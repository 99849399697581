import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

export const PageWrapper = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: ${(props) => props.margin};
  max-width: 1200px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  min-height: calc(100vh - 300px);
  scrollbar-width: none;

  ${media.tabletLandscape`
    padding: 0 16px;
  `}
  ${media.tabletLandscape`
    margin: 10px 0 0 0 ;
  `}
`;
