import React from 'react';
import Bookmaker from './Bookmaker';
import Cashier from './Cashier';
import Croupier from './Croupier';
import HistoryOfGambling from './HistoryOfGambling';
import {TestingWrapper} from './styles';

const Testing = ({direction, hideTeory, showCongratulations}) => {

  const getTitle = (dir) => {
    switch (dir) {
      case 0:
        return 'Кассир';
      case 1:
        return 'Крупье';
      case 2:
        return 'Букмекер';
      case 3:
        return 'История гемблинга';
      default:
        break;
    }
  };

  const getTest = (dir) => {
    switch (dir) {
      case 0:
        return (
          <Cashier
            direction={direction}
            hideTeory={hideTeory}
            showCongratulations={showCongratulations}
          />
        );
      case 1:
        return (
          <Croupier
            direction={direction}
            hideTeory={hideTeory}
            showCongratulations={showCongratulations}
          />
        );
      case 2:
        return (
          <Bookmaker
            direction={direction}
            hideTeory={hideTeory}
            showCongratulations={showCongratulations}
          />
        );
      case 3:
        return (
          <HistoryOfGambling
            direction={direction}
            hideTeory={hideTeory}
            showCongratulations={showCongratulations}
          />
        );
      default:
        break;
    }
  };

  return (
    <TestingWrapper>
      <h3>
        <span className="underline">Выбор направления</span> -{' '}
        <span className="underline">{getTitle(direction)}</span> -{' '}
        <b>Тестирование</b>
      </h3>
      {getTest(direction)}
    </TestingWrapper>
  );
};

export default Testing;
