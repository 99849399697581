import React from 'react';

import {StyledNavButton} from './styles';

const NavButton = ({text}) => {
  return (
    <StyledNavButton activeClassName="activeLink">
      <span>{text}</span>
    </StyledNavButton>
  );
};

export default NavButton;
