import React from 'react';

import {StyledButton} from './styles';

const Button = ({text, onClick, ...props}) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      {text}
    </StyledButton>
  );
};

export default Button;
