import React from 'react';
import { Wrapper, Header, Row, HandPayFooter, AttendantKey } from '../Menu/styles';

import {useSelector, useDispatch} from 'react-redux';
import {authSelector, getInfo, handlerCollect} from 'redux/authReducer';
import { useNavigate } from 'react-router-dom';

const RemoteIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const {
    authInfo: {info},
  } = useSelector(authSelector);

  React.useEffect(() => {
    info === null && dispatch(getInfo())
}, [info, dispatch]);

const handleKeyPress = () => {
  dispatch(handlerCollect())
  navigate('/authgames')
}

React.useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
  return () => {
    document.removeEventListener("keydown", handleKeyPress);
  };
  // eslint-disable-next-line
}, []);

  const denom = info && info.denom
  return (
      <Wrapper>
        <Header align><span>{'ATTENDANT > HANDPAY CREDIT'}</span><span>Page 1/1</span></Header>
        <div className='service'>
          <Row height={7}>
            <span></span>
            <span>VALUE[ ]</span>
            <span>CREDITS</span>
          </Row>
          <Row height={17}>
            <span>CURRENT WIN</span>
            <span>{'0.00'}</span>
            <span>{'0.00'}</span>
          </Row>
          <Row height={17}>
            <span>CASHABLE CREDIT</span>
            <span>{'0.00'}</span>
            <span>{'0.00'}</span>
          </Row>
          <hr style={{color: '#99f9ff', marginBottom: '2vh'}} />
          <Row height={17}>
            <span>HANDPAY AMOUNT</span>
            <span>{'0.00'}</span>
            <span>{'0.00'}</span>
          </Row>
        <HandPayFooter>
        <Row height={17}>
            <span>CURRENT HANDPAY</span>
            <span>{info && info.balance}</span>
            <span>{(info && info.balance)/denom+'.00'}</span>
        </Row>
          <AttendantKey>Turn attendant key to return</AttendantKey>
        </HandPayFooter>
        </div>
        </Wrapper>
  )
};

export default RemoteIn;
