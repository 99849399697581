import {css} from 'styled-components';

const sizes = {
  tabletLandscape: 1024,
  tablet: 768,
  phoneLandscape: 830,
  phone: 616,
};

const stringSizes = {
  desktop: '(min-width:992px)',
  tablet: '(max-width:1024px)',
  phone: '(max-width:616px)',
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export {media, stringSizes};
