import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const TableItemContainer = styled.div`
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.colors.mainBlue};
  padding: 11px 0;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  margin-bottom: 1px;
  box-shadow: 0px 4px 3px -3px rgba(14, 154, 252, 0.4);
  font-family: Roboto, sans-serif;

  ${media.tablet`
    padding: 5px 0;
  `}
`;

const Place = styled.span`
  font-size: 20px;
  line-height: 23px;
  color: #0e9afc;
  min-width: 23px;
  text-shadow: #0e9afc 1px 0 6px;
`;
const Name = styled.span`
  margin-left: 20px;
  margin-right: 50px;
  font-weight: 300;

  ${media.tabletLandscape`
    margin-right: 20px;
  `}

  ${media.tablet`
    margin: 0;
  `}
`;

const Points = styled.span`
  font-size: 20px;
  line-height: 23px;
`;

const NameAndPointsWrapper = styled.div`
  width: 100%;
  display: flex;

  ${media.tablet`
    margin-right: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    
  `}
`;

export {TableItemContainer, Place, Points, Name, NameAndPointsWrapper};
