import {Box, Radio, RadioGroup} from '@material-ui/core';
import React from 'react';
import {Questions} from '../styles';

const Quest = ({title, answers, setAnswers, value, correct}) => {
  return (
    <Questions>
      <Box style={{width: '100%'}} display="flex" justifyContent="flex-start">
        <h4>{title}</h4>
      </Box>
      <RadioGroup value={value}>
        {answers.map((item, id) => {
          return (
            <Box
              key={id}
              style={{width: '100%', cursor: 'pointer'}}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              onClick={() => setAnswers(id)}
            >
              <Radio value={id} color="default" size="small" />
              <span
                className={
                  correct !== null ? (correct === id ? 'green' : 'red') : ''
                }
              >
                {item}
              </span>
            </Box>
          );
        })}
      </RadioGroup>
    </Questions>
  );
};

export default Quest;
