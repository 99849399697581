import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AuthPageWrapper } from './styles';

import NewsHeaderContainer from './NewsHeader';
import MainBlock from './MainBlock';
import MainFooter from './MainBlock/MainFooter';
import { useShowBlock } from 'hooks/useShowBlock';
import {
  authSelector,
} from 'redux/authReducer';
import { getCurrentPoll } from 'redux/pollsReducer';

//provider icons
import {
  handlerFetchGamePackages,
  gamePackageSelector,
} from 'redux/gamePackageReducer';
import axios from 'axios';

const gameMenu = [];

let showOffer = null;

// eslint-disable-next-line react/display-name
const AuthPageContainer = () => {
  const dispatch = useDispatch();
  const {
    authInfo: { info },
  } = useSelector(authSelector);

  const [selectedGames, setSelectedGames] = React.useState(-4);

  const { items: packages } = useSelector(gamePackageSelector);

  React.useEffect(() => {
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${localStorage.getItem('token')}`;

    // eslint-disable-next-line
  }, [localStorage.getItem('token')]);

  React.useEffect(() => {
    dispatch(getCurrentPoll());
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    !packages && dispatch(handlerFetchGamePackages());
    // eslint-disable-next-line
  }, []);

  const {
    showBlock: showRecords,
    isBlockShown: isRecordsShown,
    hideBlock: hideRecords,
  } = useShowBlock();

  const {
    showBlock: showPoll,
    isBlockShown: isPollShown,
    hideBlock: hidePoll,
  } = useShowBlock();

  const {
    showBlock: showRules,
    isBlockShown: isRulesShown,
    hideBlock: hideRules,
  } = useShowBlock();

  const {
    showBlock: showOferta,
    isBlockShown: isOfertaShown,
    hideBlock: hideOferta,
  } = useShowBlock();
  showOffer = showOferta;

  const {
    showBlock: showCollect,
    isBlockShown: isCollectShown,
    // hideBlock: hideCollect,
  } = useShowBlock();

  const {
    showBlock: showChangeHotkey,
    isBlockShown: isChangeHotkeyShown,
    hideBlock: hideChangeHotkey,
  } = useShowBlock();

  const {
    showBlock: showAccount,
    isBlockShown: isAccountShown,
    hideBlock: hideAccount,
  } = useShowBlock();

  const {
    showBlock: showTeory,
    isBlockShown: isTeoryShown,
    hideBlock: hideTeory,
  } = useShowBlock();


  // const activeMirror = info && info.active_mirror_block;
  // const highscore = info && info.active_mirror_block;
  // React.useEffect(() => {
  //   if (info && info.is_social_poll) {
  //     if (
  //       activeMirror &&
  //       currentQuestion === -1 &&
  //       InfoBalance === 0 &&
  //       pollSum === 0 &&
  //       highscore === 0
  //     ) {
  //       hidePoll();
  //       navigate('/');
  //     }
  //   } else {
  //     if (activeMirror && InfoBalance === 0 && pollSum === 0 && highscore === 0) {
  //       navigate('/');
  //     }
  //   }
  // }, [
  //   info,
  //   InfoBalance,
  //   pollSum,
  //   currentPoll,
  //   history,
  //   hidePoll,
  //   currentQuestion,
  //   activeMirror,
  // ]);

  const isSocialPoll = info && info.is_social_poll;
  const show_logo = info && info.show_logo;

  return (
    <AuthPageWrapper>
      <NewsHeaderContainer
        showRules={showRules}
        showOferta={showOferta}
        isRulesShown={isRulesShown}
        isOfertaShown={isOfertaShown}
        isPollShown={isPollShown}
        isRecordsShown={isRecordsShown}
        isSocialPoll={isSocialPoll}
        showAccount={showAccount}
        hideAccount={hideAccount}
        showTeory={showTeory}
        hideTeory={hideTeory}
      />
      <MainBlock
        showRecords={showRecords}
        showPoll={showPoll}
        showRules={showRules}
        show_logo={show_logo}
        isRulesShown={isRulesShown}
        hideRules={hideRules}
        showOferta={showOferta}
        isOfertaShown={isOfertaShown}
        hideOferta={hideOferta}
        isSocialPoll={isSocialPoll}
        isPollShown={isPollShown}
        isRecordsShown={isRecordsShown}
        gameMenu={gameMenu}
        setSelectedGames={setSelectedGames}
        selectedGames={selectedGames}
        showChangeHotkey={showChangeHotkey}
        isChangeHotkeyShown={isChangeHotkeyShown}
        isAccountShown={isAccountShown}
        isTeoryShown={isTeoryShown}
        showAccount={showAccount}
        hideAccount={hideAccount}
        showTeory={showTeory}
        hideTeory={hideTeory}
      />
      <MainFooter
        showRecords={showRecords}
        isRecordsShown={isRecordsShown}
        hideRecords={hideRecords}
        showPoll={showPoll}
        isPollShown={isPollShown}
        hidePoll={hidePoll}
        isSocialPoll={isSocialPoll}
        isRulesShown={isRulesShown}
        isOfertaShown={isOfertaShown}
        gameMenu={gameMenu}
        setSelectedGames={setSelectedGames}
        selectedGames={selectedGames}
        showCollect={showCollect}
        isCollectShown={isCollectShown}
        isChangeHotkeyShown={isChangeHotkeyShown}
        hideChangeHotkey={hideChangeHotkey}
      />
    </AuthPageWrapper>
  );
};

export { showOffer };
export default AuthPageContainer;
