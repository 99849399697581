import {down} from 'styled-breakpoints';
import styled from 'styled-components';
import logoBg from '../../assets/images/logoBg.svg';

export const AccountWrapper = styled.div`
  position: relative;
  margin-top: 5em;
  width: 100%;
  min-height: 62h;
  max-height: 62vh;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  padding-bottom: 2em;
  border-radius: 0.5em;
  font-family: Montserrat, sans-serif;
  .card {
    cursor: pointer;
  }
  .card:hover > div {
    border: 3px solid #4a2a53;
  }

  .card:hover > button {
    border: 3px solid #c79536;
  }

  ${down('lg')} {
    padding-bottom: 1em;
  }
`;

export const Title = styled.div`
  position: relative;
  width: 100%;
  color: #000;
  background: #e8e8e8;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
`;

export const TitleTab = styled.div`
  position: relative;
  width: max-content;
  /* height: 3em; */
  background: ${(props) => (props.active ? '#fff' : '#e8e8e8')};
  padding: 1em 4em;
  min-height: 3.6em;

  display: flex;
  align-items: center;
  ${(props) =>
    props.active &&
    `box-shadow: 5px 0px 5px -5px rgba(34, 60, 80, 0.6);
     border-radius: .5em 0 0 0;
     &::after {
      z-index: 1;
     }
     `};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -3.2em;
    border: 1.65em solid transparent;
    border-bottom: 1.6em solid ${(props) => (props.active ? '#fff' : '#e8e8e8')};
    border-left: 1.6em solid ${(props) => (props.active ? '#fff' : '#e8e8e8')};
    transform: scale(1, 2);
  }

  span {
    color: #000;
    font-family: Georgia;
    font-size: 1em;
    font-weight: 800;
  }
  ${down('md')} {
    padding: 0 2em;
    clip-path: none;
    &::after {
      display: none;
    }
    span {
      font-size: .8em;
    }

  }
  ${down('sm')} {
    width: auto;
    clip-path: none;
    padding: 0 2em;
  }
`;

export const LogoBg = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  ${(props) => !!props.left && 'left: 0em;'}
  ${(props) => !!props.right && 'right: -25em;'}
  width: 25em;
  height: 25em;
  background: url(${logoBg}) no-repeat center / cover;
  filter: brightness(0.95);
  z-index: 0;
  ${down('md')} {
    display: none;
  }
`;

export const ScrollWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  max-height: 55vh;
  min-height: 55vh;
  padding-bottom: 2em;
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    outline: 1px solid transparent;
    border-radius: 0.35em;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 1em;
  right: 1em;
  color: #000;
`;
