import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const StyledYouthPoliticsContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  ${media.phone`
      flex-direction: column;
  `}
`;

const NewsAndPollBlock = styled.div`
  min-width: 285px;
  width: 285px;
  display: flex;
  flex-direction: column;

  ${media.tablet`
    min-width: 172px;
  `}

  ${media.phone`
      width:100%;
  `}
`;

const PollContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const YouthPoliticsText = styled.div`
  width: 100%;
  ${media.phone`
      width:95%;
  `}
  p {
    text-align: left;
    margin-bottom: 25px;
    font-family: Roboto;
    font-weight: 300;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;
const YouthPoliticsTextContainer = styled.div`
  width: 100%;
  margin: 0 20px;

  ${media.phone`
    margin: 20px 0 0 5px;
  `}
`;

const YouthPoliticsHeading = styled.h3`
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  margin-bottom: 15px;
`;

const SnowPolitics = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  margin-top: 15px;

  svg {
    margin-top: 5px;
  }
`;

export {
  StyledYouthPoliticsContainer,
  NewsAndPollBlock,
  PollContainer,
  YouthPoliticsHeading,
  YouthPoliticsText,
  YouthPoliticsTextContainer,
  SnowPolitics,
};
