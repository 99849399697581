import React from 'react';
import {useSelector} from 'react-redux';

import {RecordsBlockContainer} from './styles';

import ShadowText from '../ShadowText';
import ReactSelect from '../ReactSelect';
import RecordsTable from './RecordsTable';

import {gamesSelector} from 'redux/gamesReducer';
import {tranformValues} from 'utils/helpers';

const RecordsBlock = () => {
  // const dispatch = useDispatch();
  const {items} = useSelector(gamesSelector);
  const [selectedGame, setSelectedGame] = React.useState('');

  const handleChange = (game) => setSelectedGame(game);

  return (
    <RecordsBlockContainer>
      <ShadowText text="Рекорды" margin="0 0 8px 0" />
      <ReactSelect
        options={items.length > 0 ? tranformValues(items) : []}
        custom
        onChange={handleChange}
        value={selectedGame}
        placeholder="Выберите игру"
      />
      <RecordsTable selectedGame={selectedGame} />
    </RecordsBlockContainer>
  );
};

export default RecordsBlock;
