import { useState, useEffect } from 'react';

const getOrientation = () => {
    if (window.screen.orientation) {
        return window.screen.orientation.type;
    }
    return window.innerHeight > window.innerWidth ? 'portrait-primary' : 'landscape-primary';
}

let previousType = null;

const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState(getOrientation());

    const handleChangeOrientation = (event) => {
        const type = event.target.type;
        if (previousType !== type) {
            previousType = type;
            setOrientation(type);
        }
    };

    const handleResizeWindow = () => {
        const type = window.innerHeight > window.innerWidth ? 'portrait-primary' : 'landscape-primary';
        if (previousType !== type) {
            previousType = type;
            setOrientation(type);
        }
    }

    useEffect(() => {
        if (window.screen.orientation) {
            screen.orientation.addEventListener("change", handleChangeOrientation);
        } else {
            window.addEventListener('resize', handleResizeWindow)
        }

        return () => {
            if (window.screen.orientation) {
                screen.orientation.removeEventListener("change", handleChangeOrientation)
            } else {
                window.removeEventListener('resize', handleResizeWindow)
            }
        }
    }, []);

    return orientation;
}

export default useScreenOrientation;