import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  AuthGamesHeader, HeaderNews, DenomitationBlock,
  DenominationText, DenominationPoints, DenominationArrows,
  DenominationArrow, NewsWrapper, MobileText
} from './styles';

import denom from 'assets/images/denom.png';
import arrowup from 'assets/images/arrowup.png';
import arrowdown from 'assets/images/arrowdown.png';

import { authSelector } from 'redux/authReducer';
import { changeDenomination } from 'redux/denominationReducer';
import { setSelectorProvider, setSliderCount, mainContentSelector } from 'redux/mainContentReducer';
import { soundPlayer } from 'utils/soundPlayer';
import check from 'assets/sounds/Check.wav';

import bronze from 'assets/images/mobile_jackpot/bronze.png';
import silver from 'assets/images/mobile_jackpot/silver.png';
import gold from 'assets/images/mobile_jackpot/gold.png';
import diamond from 'assets/images/mobile_jackpot/diamond.png';

const jackpots = { bronze, silver, gold, diamond };

const NewsHeaderContainer = ({
  showOferta,
  showRules,
  showAccount,
  hideAccount,
  showTeory,
  hideTeory,
  isOfertaShown,
  isRulesShown,
  isPollShown,
  isRecordsShown,
  isSocialPoll
}) => {
  const {
    authInfo: { info }
  } = useSelector(authSelector);
  const { selectedProvider } = useSelector(mainContentSelector);

  const lang = info.lang.toLowerCase();
  const dispatch = useDispatch();

  const getActiveDenome = React.useCallback(() => {
    let activeDenome;
    info &&
      info.denominations.forEach((item, id) => {
        if (Number(item) === Number(info.denomination)) activeDenome = id;
      });
    return activeDenome;
  }, [info]);

  const selectDenom = React.useCallback(
    (value) => {
      const selectedDenom = getActiveDenome() + value;
      if (selectedDenom < 0) {
        dispatch(changeDenomination({ denomination: info && info.denominations[info && info.denominations.length - 1] }));
      } else if (selectedDenom > (info && info.denominations.length) - 1) {
        dispatch(changeDenomination({ denomination: info && info.denominations[0], }));
      } else {
        dispatch(changeDenomination({ denomination: info && info.denominations[selectedDenom] }));
      }
      // eslint-disable-next-line
    },
    [info, dispatch, getActiveDenome]
  );
  const [newsItems, setNewsItems] = React.useState('');
  const [tickerWidth, setTickerWidth] = React.useState(0);
  const [time, setTime] = React.useState(0);

  const string = React.createRef(); //ticker element
  const ticker = React.createRef(); //ticker element
  React.useEffect(() => {
    if (string.current) {
      string.current.onanimationend = () => {
        setNewsItems(info.text);
      };
    }
  }, [info, string, newsItems]);

  React.useEffect(() => {
    if (info) setNewsItems(info.text);
  }, [info, string]);

  React.useEffect(() => {
    if (ticker.current && string.current) {
      setTickerWidth(
        (ticker.current.clientWidth + string.current.clientWidth) / 2
      );
      setTime(
        ((ticker.current.clientWidth - string.current.clientWidth) /
          ticker.current.clientWidth) *
        100
      );
    }
  }, [ticker, string]);

  //buttons control
  const denomChange = (e) => {
    const { next_denomination = [], previous_denomination = [] } = info && info.hotkeys;
    const { code } = e;
    if (!isOfertaShown && !isRulesShown && !isPollShown && !isRecordsShown) {
      if (code === previous_denomination[0]) {
        soundPlayer(check).play()
        selectDenom(-1)
      } else if (code === next_denomination[0]) {
        soundPlayer(check).play()
        selectDenom(1)
      }
    }
  };

  React.useEffect(() => {
    if (info && info.hotkeys) {
      document.addEventListener('keydown', denomChange);
    }
    return () => {
      document.removeEventListener('keydown', denomChange);
    };
    // eslint-disable-next-line
  }, [info, isOfertaShown, isRulesShown, isPollShown, isRecordsShown]);

  const isTeoryComplete =
    !!info?.is_school &&
    info?.school !== null &&
    !!info?.school?.filter((i) => i.status === 3).length;

  const pointsText = lang === 'tgk' ? 'ХолҲо' : 'Очки';
  const denominationText = lang === 'tgk' ? 'Номинатсия' : 'Деноминация';
  const offerText = lang === 'tgk' ? 'Пешниҳод' : 'Оферта';
  const jacks = Object.keys(info.jackpots).length > 0 ? <div style={{ display: "flex", justifyContent: "flex-start", flex: "auto" }}>Jackpots: {Object.entries(jackpots).map(([name, icon]) => name in info.jackpots ? <div style={{ display: "flex", justifyContent: "flex-start", flex: "auto" }}>
    <img src={icon} style={{ marginLeft: "1vw" }} />
    <p style={{ marginLeft: "1vw" }}>{(info.jackpots[name] / 100).toFixed(2)}</p>
  </div> : null)}</div> : null;
  return (
    <>
      {info && (
        <AuthGamesHeader>
          <NewsWrapper>
            <DenomitationBlock>
              {window.innerWidth > 900 && <>
                <HeaderNews ref={ticker} width={tickerWidth} time={time}>
                  {<span ref={string}>{newsItems}</span>}
                </HeaderNews>
                <DenominationText>{denominationText}</DenominationText>
                <DenominationPoints>
                  <span>{info && info.denomination / 100}</span>
                  <img src={denom} alt="denom" />
                </DenominationPoints>
                <DenominationArrows>
                  <DenominationArrow className="up-arrow" onClick={() => { soundPlayer(check).play(); selectDenom(-1); }}>
                    <img src={arrowup} alt="down" />
                  </DenominationArrow>
                  <DenominationArrow onClick={() => { soundPlayer(check).play(); selectDenom(1); }}>
                    <img src={arrowdown} alt="down" />
                  </DenominationArrow>
                </DenominationArrows>
              </>}
              {window.innerWidth < 900 && <>
                <div className="row">
                  <HeaderNews ref={ticker} width={tickerWidth} time={time}>
                    {<span ref={string}>{newsItems}</span>}
                  </HeaderNews>
                  <MobileText>
                    {denominationText}: {info && info.denomination / 100}
                  </MobileText>
                  <DenominationArrows>
                    <DenominationArrow className="up-arrow" onClick={() => { soundPlayer(check).play(); selectDenom(1); }}>
                      <img src={arrowup} alt="down" />
                    </DenominationArrow>
                    <DenominationArrow onClick={() => { soundPlayer(check).play(); selectDenom(-1); }}>
                      <img src={arrowdown} alt="down" />
                    </DenominationArrow>
                  </DenominationArrows>
                </div>
                <div className="row">
                  {jacks}
                  <div style={{ display: "flex", justifyContent: "flex-end", flex: "auto" }}>
                    <MobileText onClick={() => { dispatch(setSelectorProvider('')); dispatch(setSliderCount(0)); }}>
                      <span style={{ opacity: selectedProvider ? '1' : '.5' }}>
                        Производители
                      </span>
                    </MobileText>
                    {!!isSocialPoll && <MobileText onClick={info?.is_school && info?.school !== null ? () => { if (!!isTeoryComplete) { showAccount(); hideTeory(); } } : showRules}>
                      {info?.is_school && info?.school !== null ? 'Aккаунт' : 'Правила'}
                    </MobileText>}
                    {(isSocialPoll || lang === 'tgk') && <MobileText onClick={info?.is_school && info?.school !== null ? () => { if (!!isTeoryComplete) { showTeory(); hideAccount(); } } : showOferta}>
                      {info?.is_school && info?.school !== null ? 'Теория' : offerText}
                    </MobileText>}
                    <MobileText>
                      {pointsText}: {info.balance / info.denomination}
                    </MobileText>
                  </div>
                </div>
              </>}
            </DenomitationBlock>
          </NewsWrapper>
        </AuthGamesHeader>
      )}
    </>
  );
};

export default NewsHeaderContainer;
