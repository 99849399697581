import {AUTH_API} from 'settings';

export const saveKey = async (key, action) => {
  const URI = `${AUTH_API}/json/saveKey?key_code=${key}&action_id=${action}`;
  const query = {
    method: 'POST',
    credentials: 'include',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

