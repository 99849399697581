import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch, useSelector} from 'react-redux';

import {MobileFiltersContainer, MobileFilterButton} from './styles';
import {Label, TextInputWrapper} from '../FiltersBlock/styles';

import {FlexBox} from 'components/Common/FlexBox';
import ReactSelect from 'components/Common/ReactSelect';
import TextInput from 'components/Common/TextInput';

import {useForm} from 'hooks/useForm';
import {theme} from 'theme/theme';
import {gamesSelector, setSelectedGames, getGames} from 'redux/gamesReducer';
import {tranformValues} from 'utils/helpers';

const MobileFiltersBlock = ({hideBlock}) => {
  const dispatch = useDispatch();
  const {items} = useSelector(gamesSelector);
  const [selected, setSelected] = React.useState('');
  const {form, handleInputChange, setForm} = useForm({
    player: '',
    points: '',
    date: '',
  });

  const handleGameChange = (game) => {
    setSelected(game);
  };

  const clearInput = (name) => {
    setForm((state) => {
      return {
        ...state,
        [name]: '',
      };
    });
  };

  React.useEffect(() => {
    dispatch(getGames());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(setSelectedGames(selected.id));
  }, [dispatch, selected.id]);

  return (
    <MobileFiltersContainer>
      <FlexBox direction="column" width="100%">
        <Label>Название игры</Label>
        <ReactSelect
          filter
          placeholder="Выберите название игры"
          options={items.length > 0 ? tranformValues(items) : []}
          custom
          onChange={handleGameChange}
          value={selected}
        />
      </FlexBox>
      <FlexBox direction="column" width="100%">
        <Label>Имя игрока</Label>
        <TextInputWrapper>
          <TextInput
            placeholder="Введите имя игрока"
            name="player"
            onChange={handleInputChange}
            value={form.player}
          />
          {form.player !== '' && (
            <CloseIcon
              className="closeIcon"
              onClick={() => clearInput('player')}
            />
          )}
        </TextInputWrapper>
      </FlexBox>
      <FlexBox direction="column" width="100%">
        <Label>Набранные очки</Label>
        <TextInputWrapper>
          <TextInput
            placeholder="Введите количество очков"
            name="points"
            onChange={handleInputChange}
            value={form.points}
          />
          {form.points !== '' && (
            <CloseIcon
              className="closeIcon"
              onClick={() => clearInput('points')}
            />
          )}
        </TextInputWrapper>
      </FlexBox>
      <FlexBox direction="column" width="100%">
        <Label>Дата</Label>
        <TextInputWrapper>
          <TextInput
            placeholder="10.02.2020"
            name="date"
            onChange={handleInputChange}
            value={form.date}
          />
          {form.date !== '' && (
            <CloseIcon
              className="closeIcon"
              onClick={() => clearInput('date')}
            />
          )}
        </TextInputWrapper>
      </FlexBox>
      <MobileFilterButton
        color={theme.colors.white}
        background="rgba(14, 154, 252, 0.5)"
        margin="30px 0 12px 0"
        onClick={hideBlock}
      >
        Применить фильтр
      </MobileFilterButton>
      <MobileFilterButton
        color={theme.colors.mainBlue}
        background="rgba(0,0,0,0)"
        onClick={hideBlock}
      >
        Сбросить фильтр
      </MobileFilterButton>
    </MobileFiltersContainer>
  );
};

export default MobileFiltersBlock;
