import {Box, Container} from '@material-ui/core';
import React from 'react';
import Lessons from './Lessons';
import {
  AccountWrapper,
  Title,
  TitleTab,
  LogoBg,
  ScrollWrap,
  CloseButton,
} from './styles';
import UserInfo from './UserInfo';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const Account = ({hideAccount, isTeoryComplete}) => {
  const [tabSelected, setTabSelected] = React.useState(0);

  const getTab = (id) => {
    switch (id) {
      case 0:
        return <UserInfo />;
      case 1:
        return <Lessons />;
        default:
          break
    }
  };

  return (
    <Container fixed>
      <AccountWrapper id="account">
        <LogoBg left />
        <LogoBg right />
        <Box
          style={{width: '100%'}}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Title>
            <TitleTab
              active={tabSelected === 0}
              onClick={() => setTabSelected(0)}
            >
              <span>Аккаунт</span>
            </TitleTab>
            <TitleTab
              active={tabSelected === 1}
              onClick={() => setTabSelected(1)}
            >
              <span>Практические уроки</span>
            </TitleTab>
          </Title>
          {isTeoryComplete && (
            <CloseButton onClick={hideAccount}>
              <HighlightOffIcon style={{color: '#777', cursor: 'pointer'}}/>
            </CloseButton>
          )}
        </Box>
        <ScrollWrap>{getTab(tabSelected)}</ScrollWrap>
      </AccountWrapper>
    </Container>
  );
};

export default Account;
