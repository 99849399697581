import React from 'react';

import {Box, Divider, LinearProgress, makeStyles} from '@material-ui/core';
import {AccountCard, UserInfoWrapper} from './styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles({
  linear: {
    width: '100%',
    height: '.6em',
    borderRadius: '.2em',
    margin: '.5em 0',
    backgroundColor: '#c8c8c8',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#fff',
    },
  },
});

const UserInfo = () => {
  const classes = useStyles();
  return (
    <UserInfoWrapper>
      <h3>Личный кабинет</h3>
      <Box display="flex" justifyContent="center" alignItems="flex-end">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <AccountCard>
            <AccountCircleIcon style={{fontSize: '6em'}} />
            <Box className="info">
              <b>
                <span>Имя:</span>
              </b>

              <span>Иван Иванов</span>
            </Box>
            <Box className="info">
              <b>
                <span>Email:</span>
              </b>
              <span>ivanov@mail.com</span>
            </Box>
            <Box className="info">
              <b>
                <span>Телефон:</span>
              </b>
              <span> +7 999 999 99 99</span>
            </Box>
          </AccountCard>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <b>
            <span>Очки</span>
          </b>
          <AccountCard>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box display="flex" alignItems="center" className="scores">
                <span>12.06.20</span>
                <b>115.350</b>
              </Box>
              <Divider
                style={{
                  width: '100%',
                  height: '.2em',
                  background: '#fff',
                  margin: '.3em',
                }}
              />
              <Box display="flex" alignItems="center" className="scores">
                <span>12.06.20</span>
                <b>115.350</b>
              </Box>
              <Divider
                style={{
                  width: '100%',
                  height: '.2em',
                  background: '#fff',
                  margin: '.3em',
                }}
              />
              <Box display="flex" alignItems="center" className="scores">
                <span>12.06.20</span>
                <b>115.350</b>
              </Box>
              <Divider
                style={{
                  width: '100%',
                  height: '.2em',
                  background: '#fff',
                  margin: '.3em',
                }}
              />
              <Box display="flex" alignItems="center" className="scores">
                <span>12.06.20</span>
                <b>115.350</b>
              </Box>
              <Divider
                style={{
                  width: '100%',
                  height: '.2em',
                  background: '#fff',
                  margin: '.3em',
                }}
              />
              <Box display="flex" alignItems="center" className="scores">
                <span>12.06.20</span>
                <b>115.350</b>
              </Box>
            </Box>
          </AccountCard>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <b>
            <span>Уровень</span>
          </b>
          <AccountCard>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <span className="level">Уровень: {5}</span>
              <LinearProgress
                className={classes.linear}
                variant="determinate"
                value={50}
              />
              <span className='progress'>17.500/35.000</span>
              <span className='next'>до следующего уровня необходимо выполнить:</span>
              <span className='lesson'>Практический урок 6</span>
              <span className='next'>-собрать 35.000 очков</span>
            </Box>
          </AccountCard>
        </Box>
      </Box>
    </UserInfoWrapper>
  );
};

export default UserInfo;
