import React from 'react';

import {
  NewsItemContainer,
  NewsItemImg,
  NewsInfo,
  NewsItemDate,
  NewsItemHeading,
  NewsItemText,
  NewsItemOverlay,
  ReadButton,
} from './styles';

const NewsItem = ({item, showModal}) => {
  return (
    <>
      {item && (
        <NewsItemContainer>
          <NewsItemOverlay className="overlay" onClick={showModal}>
            <ReadButton onClick={showModal}>Читать</ReadButton>
          </NewsItemOverlay>
          {item.img && (
            <NewsItemImg>
              <img src={item.img} alt={item.title} />
            </NewsItemImg>
          )}
          <NewsInfo>
            <NewsItemDate>{item.date ? item.date : 'No Data'}</NewsItemDate>
            <NewsItemHeading>{item.title}</NewsItemHeading>
            <NewsItemText>{item.title}</NewsItemText>
          </NewsInfo>
        </NewsItemContainer>
      )}
    </>
  );
};

export default NewsItem;
