import React from 'react';
import { useNavigate } from 'react-router-dom';

import AuthPageContainer from 'components/AuthGames/AuthPageContainer';

const AuthGames = ({ pusherClient }) => {
  const history = useNavigate();
  return <AuthPageContainer pusherClient={pusherClient} history={history} />;
};

export default AuthGames;
