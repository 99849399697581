import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const NewsItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0);
  transition: all 0.2s ease;
  margin-bottom: 30px;
  cursor: pointer;
  &:hover {
    border: 2px solid ${(props) => props.theme.colors.mainBlue};
    box-shadow: ${(props) => props.theme.blockShadow};

    .overlay {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const NewsInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  background: ${(props) => props.theme.colors.darkBlue1};
`;

const NewsItemImg = styled.div`
  width: 100%;
  height: 121px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const NewsItemOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 10, 36, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
`;

const NewsItemDate = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.lightGrey};
  font-family: Roboto;
  font-weight: 300;
`;

const NewsItemHeading = styled.h3`
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.white};
  margin: 5px 0 5px 0;

  ${media.tablet`
    font-size: 18px;
    line-height: 22px;
  `}
`;

const NewsItemText = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.lightGrey};
  font-family: Roboto;
  font-weight: 300;

  ${media.tablet`
    font-size: 14px;
    line-height: 16px;
  `}
`;

const ReadButton = styled.button`
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  position: absolute;
  width: 117px;
  height: 35px;
  background: rgba(14, 154, 252, 0.5);
  border: 1px solid ${(props) => props.theme.colors.mainBlue};
  box-shadow: none;
  transition: all 0.3s ease;
  box-shadow: ${(props) => props.theme.blockShadow};

  &:focus {
    outline: none;
  }
`;

export {
  NewsInfo,
  NewsItemContainer,
  NewsItemDate,
  NewsItemImg,
  NewsItemText,
  NewsItemHeading,
  NewsItemOverlay,
  ReadButton,
};
