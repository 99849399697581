import {Box} from '@material-ui/core';
import React from 'react';

const Bookmaker = ({setTabSelected, setDirection}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
    >
      <span className="direction-title">
        <span className="outline">Выбор направления</span> - <b>Букмекер</b>
      </span>
      <p>
        Еще в Древнем Риме народ развлекался битвами гладиаторов, петушиными
        боями, гонками на колесницах. Азарт и жажда наживы подталкивали их к
        ставкам на возможного победителя, а для этого необходим был посредник,
        который соберет деньги со спорщиков, а потом раздаст их победителям,
        оставив себе оговоренное вознаграждение за работу. Этих людей можно
        считать первыми букмекерами.
      </p>
      <p>
        Cначала были распространены тотализаторы, когда все ставки
        суммировались, отнимался процент организатора, а по окончании события
        деньги делились между победителями в зависимости от размера их взноса.
        Букмекерство пришло на смену тотализаторам в XX веке, теперь
        организаторы делали предварительные прогнозы на исход события и
        определяли коэффициенты.
      </p>
      <p>
        <b>Описание и характеристика профессии </b>
      </p>
      <p>
        Далеко не все знают, что такое букмекер. Попробуем объяснить, как это
        работает. Букмекер может быть физическим лицом или организацией.
        Поскольку времена одиночек уходят в прошлое, расскажем, как работают
        букмекерские конторы.
      </p>
      <p>
        В штат конторы входит ряд сотрудников, которые занимаются работой по
        конкретному направлению:
      </p>
      <p>
        - букмекер-операционист нужен в организации, которая существует "в
        реале": он сидит в кабинке, принимает деньги от игроков, выдает им чеки,
        а после окончания события выплачивает выигрыши;
        <br />
        - специалист по Live-ставкам (в режиме реального времени) при помощи
        программного обеспечения быстро реагирует на динамику коэффициентов,
        ограничивает сумму ставок или вовсе прекращает их прием; <br />
        - специалист по софту (системный администратор, айтишник), который
        следит за бесперебойной работой компьютерной техники и программ; <br />-
        аналитик, глубокий знаток какого-либо вида спорта, способный делать
        обоснованные прогнозы для расчета коэффициентов, учитывая мельчайшие
        детали.
      </p>
      <p>
        В мире работает множество контор, которые принимают ставки на
        всевозможнейшие события. Чаще всего речь идет о спортивных
        соревнованиях, но могут спорить, например, о том, кто станет следующим
        президентом США, какого пола родится ребенок в королевской семье, кто
        получит Нобелевскую премию по литературе и т.д.
      </p>
      <p>
        По большому счету, для букмекерской конторы не имеет большого значения,
        выиграл клиент или проиграл. В любом варианте она получит свой процент,
        который изначально заложен в выставленных коэффициентах.
      </p>
      <p>
        <b>Профессиональные обязанности </b>
      </p>
      <p>
        Для того чтобы контора работала, привлекала клиентов и приносила доход,
        она должна обеспечить для игроков комфортные условия. Букмекеру-кассиру
        (а именно с этой должности начинает большинство работников в данном
        бизнесе) необходимо выполнять целый ряд функций:
      </p>
      <p>
        - общаться с клиентами, объясняя им условия оказания услуг; <br />
        - принимать от них ставки; <br />
        - принимать оплату, выдавать квитанцию;
        <br />
        - заносить в компьютер данные о ставке;
        <br />- по исходу события выплачивать выигрыш победителю.
      </p>
      <p>
        <b>Букмекер-аналитик</b> занимается совсем другими вещами. Он, используя
        свои знания, обязан максимально точно спрогнозировать шансы той или иной
        команды на победу, определить фаворита, выставить коэффициенты и
        заложить в них маржу конторы. Возьмем, к примеру, важный футбольный
        матч. Чтобы качественно спрогнозировать его исход, аналитик может
        учитывать несколько факторов:
      </p>
      <p>
        - статистику прежних игр между соперниками; <br />
        - форму, в которой находятся команды (спад или подъем);
        <br />
        - микроклимат в коллективе; <br />
        - назначение на матч арбитра и его предпочтения; кадровые перестановки
        (смена тренера, травмы ведущих игроков); <br />
        - поставленные руководством клуба задачи и уровень мотивации игроков;
        <br />- принципиальность встречи.
      </p>
      <p>
        А еще поддержку болельщиков, погоду, время отдыха каждой из команд после
        предыдущей игры, дальность перелета и т.д. Ну, а руководитель конторы
        должен понимать все нюансы работы, уметь организовать коллектив и вести
        бизнес.
      </p>
      <p>
        <b>Кому подходит</b>
      </p>
      <p>
        Трудятся в букмекерских конторах мужчины и женщины, которые разбираются
        в спортивных событиях и умеют работать с деньгами. Работодатели обычно
        ожидают от кандидатов на должность таких качеств:
      </p>
      <p>
        - честности и порядочности,
        <br />
        - терпеливости в работе с самыми разными клиентами, <br />
        - ответственности и исполнительности, <br />
        - умения работать с компьютером на уровне опытного пользователя, <br />-
        логического мышления и интуиции.
      </p>
      <p>
        Приветствуется высшее экономическое, техническое или математическое
        образование.
      </p>
      <p>
        Многие конторы открыты круглосуточно и без выходных. В таком случае
        букмекеры-кассиры работают посменно. Это хороший вариант для людей,
        которые хотят получить дополнительный заработок помимо своего основного
        места работы.
      </p>
      <Box
        style={{width: '100%', marginTop: '2em'}}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <button
          className="test-btn"
          onClick={() => {
            setTabSelected(1);
          }}
        >
          Перейти к тестированию
        </button>
      </Box>
    </Box>
  );
};

export default Bookmaker;
