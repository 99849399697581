import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

export const NewsContainer = styled.main`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
  position: relative;

  ${media.tabletLandscape`
    grid-template-columns: repeat(auto-fit, minmax(236px, 1fr));
  `}
  ${media.tablet`
    grid-template-columns: repeat(auto-fit, minmax(172px, 1fr));
  `}

  @media(max-width: 670px) {
    display: block;
  }
`;
