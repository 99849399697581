import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const MobileMenuContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(10, 4, 16, 0.7);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 99999;
`;

const StyledMobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  background: linear-gradient(90deg, #031238 0%, #0a5aab 100%);
  height: 100%;
  width: 260px;
  padding: 16px;

  .closemobilemenu {
    ${media.phone`
     margin-right: -5px;
  `}
  }

  ${media.phone`
      width: 90%;
  `}
`;

const MobileMenuItem = styled.span`
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 25px;
  &:first-of-type {
    margin-top: 30px;
  }

  ${media.phone`
      right:0;
      left:unset;
      margin-right: 15px;
  `}
`;

export {StyledMobileMenu, MobileMenuContainer, MobileMenuItem};
