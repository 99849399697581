import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { RecordsContainer, TableContainer, TableHeader, TableHeaderCell } from './styles';
import { Heading, Button } from '../Rules/styles';

import { saveRecords, recordsSelector } from 'redux/recordsReducer';
import { authSelector } from 'redux/authReducer';
import { prettyNum } from 'utils/helpers';

const Records = ({ hideRecords, showPoll }) => {
  const dispatch = useDispatch();
  const { items, error } = useSelector(recordsSelector);
  const { authInfo: { info } } = useSelector(authSelector);

  React.useEffect(() => {
    dispatch(saveRecords());
  }, [dispatch]);

  //buttons control
  const hotkeys = info && info.hotkeys
  const handleKeyPress = e => {
    const { code } = e;
    const { pass_poll } = hotkeys;
    //pass poll
    if (code === pass_poll[0]) {
      hideRecords();
      showPoll();
    }
  }

  React.useEffect(() => {
    if (hotkeys) {
      document.addEventListener("keydown", handleKeyPress);
      return () => document.removeEventListener("keydown", handleKeyPress);
    }
    //eslint-disable-next-line
  }, [info]);

  const getRecords = (records) => {
    let recordsArr = [];
    for (let record in records) {
      recordsArr = [...recordsArr, records[record]];
    }
    return recordsArr;
  };
  console.log(error)
  return (
    <>
      {error && <span>{error}</span>}
      {items && (
        <RecordsContainer>
          <Heading>таблица рекордов игры</Heading>
          <TableContainer>
            <TableHeader>
              <TableHeaderCell>#</TableHeaderCell>
              <TableHeaderCell>ИМЯ</TableHeaderCell>
              <TableHeaderCell>РЕКОРД</TableHeaderCell>
            </TableHeader>
            {items &&
              getRecords(items)
                .slice(0, 5)
                .map((item) => (
                  <TableHeader key={uuidv4()}>
                    <TableHeaderCell>{item.id}</TableHeaderCell>
                    <TableHeaderCell>ИМЯ</TableHeaderCell>
                    <TableHeaderCell>{prettyNum(item.balance_end)}</TableHeaderCell>
                  </TableHeader>
                ))}
            {/* <TableHeader>
              <FooterCell>{items && items.length + 1}</FooterCell>
              <FooterCell>ИМЯ</FooterCell>
              <FooterCell>
                {items.length > 0 && getLastItem(data).balance_end}
              </FooterCell>
            </TableHeader> */}
          </TableContainer>
          <Button
            onClick={() => {
              hideRecords();
              showPoll();
            }}
          >
            пройти опрос
          </Button>
        </RecordsContainer>
      )}
    </>
  );
};

export default Records;
