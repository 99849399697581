import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const StyledGamesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${media.phone`
    justify-content: space-around;
  `}
`;

export {StyledGamesContainer};
