import {Box, CircularProgress, Grid} from '@material-ui/core';
import {StartBtn} from 'components/Learners/Directions/styles';
import {useForm} from 'hooks/useForm';
import React from 'react';
import {postTestingAnswers} from 'services/auth';
import Quest from '../Quest';

const Croupier = ({direction, hideTeory, showCongratulations}) => {
  const {form, setFormItem} = useForm({
    '1': null,
    '2': null,
    '3': null,
    '4': null,
    '5': null,
  });

  const [answerLoading, setAnswerLoading] = React.useState(false);
  const [isAnswer, setIsAnswer] = React.useState(false);

  const data = [form['1'], form['2'], form['3'], form['4'], form['5']];

  const handleAnswerPost = () => {
    setAnswerLoading(true);

    postTestingAnswers(direction, data).then((res) => {
      setAnswerLoading(false);
      setIsAnswer(true);
      showCongratulations()
    });
  };

  return (
    <Grid container style={{zIndex: 2}}>
      <Grid item xs={6}>
        <Quest
          title="1. Что не входит в обязанности крупье?"
          answers={[
            'принимать деньги игроков',
            'подливать напитки клиентам',
            'управлять ставками',
          ]}
          value={form['1']}
          setAnswers={(id) => setFormItem('1', id)}
          correct={isAnswer ? 2 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Quest
          title="2. Что такое Баттон?"
          answers={['кнопка', 'фишка в виде виртуального дилера', 'хлеб']}
          value={form['2']}
          setAnswers={(id) => setFormItem('2', id)}
          correct={isAnswer ? 1 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Quest
          title="3. Что требуется для работы крупье?"
          answers={[
            'знание нескольких языков и психологии',
            'высшее образование ',
            'опыт игр в турнирах',
          ]}
          value={form['3']}
          setAnswers={(id) => setFormItem('3', id)}
          correct={isAnswer ? 0 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Quest
          title="4. В какой игре не участвует крупье?"
          answers={['Блэкджэк', 'Покер', 'Слоты']}
          value={form['4']}
          setAnswers={(id) => setFormItem('4', id)}
          correct={isAnswer ? 2 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Quest
          title="5. На что не должно быть аллергии у крупье?"
          answers={['На цитрусовые', 'На пыль и табачный дым', 'На кошек']}
          value={form['5']}
          setAnswers={(id) => setFormItem('5', id)}
          correct={isAnswer ? 1 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Box
          dispay="flex"
          justifyContent="center"
          alignItems="center"
          style={{width: '100%', height: '100%'}}
        >
          <StartBtn
            disabled={!!data.filter((a) => a === null).length}
            onClick={isAnswer ? hideTeory : handleAnswerPost}
          >
            {isAnswer ? (
              'Перейти к практике'
            ) : answerLoading ? (
              <CircularProgress />
            ) : (
              'ОТПРАВИТЬ'
            )}
          </StartBtn>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Croupier;
