import styled from 'styled-components';

const MainBlockContainer = styled.main`
  height: 89%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export {MainBlockContainer};
