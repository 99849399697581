import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const StyledFooter = styled.footer`
  width: 100%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0);
  height: 136px;
  padding: 11px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.phoneLandscape`
    height: unset;
  `}
`;

const FooterLogo = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 4%;

  ${media.tablet`
    svg {
      width: 43px;
      height: 47px;
    }
  `}

  ${media.phoneLandscape`
    svg {
      width: 29px;
      height: 31px;
    }
  `}
`;

const Copyright = styled.p`
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #bfbfbf;
  margin-top: 12px;

  ${media.tablet`
    font-size: 10px;
    line-height: 12px;
  `}
  ${media.phoneLandscape`
    font-size: 8px;
    line-height: 10px;
    margin-top: 5px;
  `}
`;

const FooterContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 30px auto 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 11px 0;

  ${media.phoneLandscape`
    padding: 0 0;
  `}
`;

export {StyledFooter, FooterLogo, Copyright, FooterContainer};
