import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {StyledGamesContainer} from './styles';
import {
  SelectGenre,
  GamesWrapper,
  SelectGenreText,
} from '../MobileGenresBlock/styles';

import {ReactComponent as ArrowDown} from 'assets/icons/arrow-down-white.svg';

import GameItem from './GameItem';
import MObileGenresBlock from '../MobileGenresBlock';

import {useShowBlock} from 'hooks/useShowBlock';
import {getGames, gamesSelector} from 'redux/gamesReducer';

const GamesContainer = () => {
  const dispatch = useDispatch();
  const {items: games, loading } = useSelector(gamesSelector);
  const { selectedGames } = useSelector(gamesSelector);

  const { isBlockShown, toggleShowBlock, hideBlock } = useShowBlock();

  React.useEffect(() => {
    games.length === 0 && dispatch(getGames());
  }, [dispatch, games]);
  

  const filteredGames = React.useCallback((filter) => {
    let filteredGames = []
    games.forEach(item => {
      item.genres.forEach(genre => {
        if(genre.title === filter) filteredGames = [...filteredGames, item]
      });
    })
    return filter === '' ? games : filteredGames
    // eslint-disable-next-line
  },[games, selectedGames])
  

  return (
    <GamesWrapper>
      <SelectGenre onClick={toggleShowBlock}>
        <SelectGenreText>Жанры игр</SelectGenreText>
        <ArrowDown className="selectgenreicon" />
      </SelectGenre>
      {isBlockShown && <MObileGenresBlock hideBlock={hideBlock} />}
      <StyledGamesContainer>
        {loading && 'Loading...'}
        {!loading &&
          filteredGames(selectedGames).length > 0 &&
          filteredGames(selectedGames).map((game) => <GameItem key={game.id} game={game} />)}
      </StyledGamesContainer>
    </GamesWrapper>
  );
};

export default GamesContainer;
