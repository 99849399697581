import styled from 'styled-components';

const StyledTextInput = styled.input`
  width: 100%;
  height: 39px;
  border: 1px solid ${(props) => props.theme.colors.mainBlue};
  padding: 10px;
  color: ${(props) => props.theme.colors.white};
  background: rgba(0, 0, 0, 0);
  border-radius: 0;
  font-family: Roboto, sans-serif;
  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.mainBlue};
    box-shadow: ${(props) => props.theme.blockShadow};
  }
  font-size: ${(props) => props.theme.fontSizes.text};
  line-height: ${(props) => props.theme.lineHeights.text};
  font-family: inherit;

  &::placeholder {
    font-size: ${(props) => props.theme.fontSizes.text};
    line-height: ${(props) => props.theme.lineHeights.text};
    font-family: Roboto, sans-serif;
  }
`;

export {StyledTextInput};
