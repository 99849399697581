import {createSlice} from '@reduxjs/toolkit';
import {fetchGamePackages} from 'services/gamePackages';

export const slice = createSlice({
  name: 'gamePackage',
  initialState: {
    items: null,
    loading: false,
    error: null,
  },
  reducers: {
    gamePackagesIsLoading: (state) => {
      state.loading = true;
    },
    gamePackagesSuccess: (state, {payload}) => {
      state.items = payload;
      state.loading = false;
      state.error = null;
    },
    gamePackagesFailure: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export function handlerFetchGamePackages() {
  return async (dispatch) => {
    dispatch(gamePackagesIsLoading())
    fetchGamePackages().then((res) =>{
      if(res.data.code === 200) {
        dispatch(gamePackagesSuccess(res.data.data))
      } else {
        dispatch(gamePackagesFailure(res.data.data))
      }
    })
  };
}

export const {
  gamePackagesIsLoading,
  gamePackagesSuccess,
  gamePackagesFailure,
} = slice.actions;

export const gamePackageSelector = (state) => state.gamePackage;

export default slice.reducer;
