import {Box} from '@material-ui/core';
import React from 'react';
import {Description, DirectionsWrapper, LearningCard, StartBtn} from './styles';

import cashierIcon from '../../../assets/images/cashier.png';
import croupierIcon from '../../../assets/images/croupier.png';
import cogwheelIcon from '../../../assets/images/cogwheel.png';
import tabletIcon from '../../../assets/images/tablet.png';
import Cashier from './Cashier';
import Croupier from './Croupier';
import Bookmaker from './Bookmaker';
import HistoryOfGambling from './HistoryOfGambling';

const ChoiseDirection = ({direction, setDirection, setTabSelected}) => {

  const getDir = (id) => {
    switch (id) {
      case 0:
        return (
          <Cashier
            setTabSelected={setTabSelected}
            setDirection={setDirection}
          />
        );
      case 1:
        return (
          <Croupier
            setTabSelected={setTabSelected}
            setDirection={setDirection}
          />
        );

      case 2:
        return (
          <Bookmaker
            setTabSelected={setTabSelected}
            setDirection={setDirection}
          />
        );
      case 3:
        return (
          <HistoryOfGambling
            setTabSelected={setTabSelected}
            setDirection={setDirection}
          />
        );
      default:
        break;
    }
  };
  return (
    <DirectionsWrapper>
      {direction !== null ? (
        getDir(direction)
      ) : (
        <>
          <Description data-aos="fade-down">
            <h3>Выбор направления</h3>
          </Description>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="card"
              onClick={() => setDirection(0)}
            >
              <LearningCard>
                <img src={cashierIcon} alt="" />
                <span className="card__title">Кассир</span>
                <span className="card__description">
                  Прием и выдача средств, произведение расчета и оформление
                  ставок
                </span>
              </LearningCard>
              <StartBtn>ВЫБРАТЬ</StartBtn>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="card"
              onClick={() => setDirection(1)}
            >
              <LearningCard>
                <img src={croupierIcon} alt="" />
                <span className="card__title">Крупье</span>
                <span className="card__description">
                  Ведение игры, прием ставок, выдача выигрыша, консультация
                  игроков
                </span>
              </LearningCard>
              <StartBtn>ВЫБРАТЬ</StartBtn>
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="card"
              onClick={() => setDirection(2)}
            >
              <LearningCard>
                <img src={tabletIcon} alt="" />
                <span className="card__title">Букмекер</span>
                <span className="card__description">
                  Знание специфики ставок, видов ставок, произведение комбинаций
                </span>
              </LearningCard>
              <StartBtn>ВЫБРАТЬ</StartBtn>
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="card"
              onClick={() => setDirection(3)}
            >
              <LearningCard>
                <img src={cogwheelIcon} alt="" />
                <span className="card__title">История гемблинга</span>
                <span className="card__description">
                  Введение в историю, виды азартных игр, жанры и направления
                </span>
              </LearningCard>
              <StartBtn>ВЫБРАТЬ</StartBtn>
            </Box>
          </Box>
        </>
      )}
    </DirectionsWrapper>
  );
};

export default ChoiseDirection;
