import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const MobileFiltersContainer = styled.div`
  width: 328px;
  height: 491px;
  box-shadow: ${(props) => props.theme.blockShadow};
  background: ${(props) => props.theme.colors.darkBlue1};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 46px 0 46px;
  position: absolute;
  top: 37px;
  left: 24%;
  flex-direction: column;

  ${media.phoneLandscape`
    z-index:999;
    top: 33%;
    left: 25%;
  `}

  ${media.phone`
   	width:95%;
		left:3%;
    z-index:999;
    top:18%;
  `}

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileFilterButton = styled.button`
  border: 1px solid ${(props) => props.theme.colors.mainBlue};
  width: 100%;
  height: 39px;
  background: ${(props) => props.background};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};

  &:focus {
    outline: none;
  }
`;

export {MobileFiltersContainer, MobileFilterButton};
