import React from 'react';

import { Text, Heading, Button } from '../Rules/styles';
import { OfertaContainer } from './styles';

import { FlexBox } from 'components/Common/FlexBox';

const dafaultItems = [
  <>
    <p><strong>Cumpatator AGROVIDA IMPEX SRL</strong></p>
    <p><strong>ПРИКАЗ</strong></p>
    <p>«01» января 2023 года № 010123-1</p>
    <p><strong>Об утверждении публичной оферты</strong></p>
    <p>В соответствии со статьей 435 и частью 2 статьи 437 Гражданского кодекса Российской Федерации.</p>
    <p><span><strong>приказываю:</strong></span></p>
    <ol>
      <li>Утвердить предложение оферты в соответствии с Приложением № 1 к настоящему приказу.</li>
      <li>Определить срок действия оферты – 31.12.2023 года.</li>
      <li>Контроль за исполнением взятых обязательств в соответствии с офертой оставляю за собой.</li>
    </ol>
    <p>Neaga Nicolai</p>
  </>,
  <>
    <p><span>Приложение № 1</span></p>
    <p><span>к приказу Cumpatator AGROVIDA IMPEX SRL</span></p>
    <p><span>«01» января 2023 года № 010123-1</span></p>
    <p><span>ПУБЛИЧНАЯ ОФЕРТА</span></p>
    <li>Настоящее предложение является публичной офертой в соответствии со статьей 435 и частью 2 статьи 437 Гражданского кодекса Российской Федерации (далее – оферта).</li>
    <li>Настоящая оферта определяет взаимоотношения между Cumpatator AGROVIDA IMPEX SRL CIF/CNP/NIF: 26646022 , Numar de ordine in registrul comertului J4/234/2010 Localitate: SLANIC MOLDOVA Str.DOBRU nr.14Judetul:Bacau в лице Neaga Nicolai , именуемое в дальнейшем Организация, и физическим или юридическим лицом, принявшим оферту о заключении Договора, именуемый в дальнейшем Клиент.</li>
    <li>Организация выражает намерение заключить Договор с любым физическим или юридическим лицом и гарантирует исполнение взятых на себя в соответствии с настоящим Договором обязательств.</li>
    <li>Клиент, от своего имени принимая данный Договор, выражает свое согласие с тем, что условия настоящего Договора не ущемляют законных прав Клиента.</li>
  </>,
  <li>
    <b>Термины и определения.</b>
    <ul>
      <li>Под понятием Клиент, следует понимать лицо, выразившее согласие на заключение Договора (далее по тексту – Клиент).</li>
      <li>Инфомат – электронно-вычисленная машина, иногда оснащенная дополнительными устройствами (далее по тексту – Инфомат).</li>
      <li>Электронный сервис – электронный сервис автоматизированной информационной системы «Социнформ» (собственная разработка Cumpatator AGROVIDA IMPEX SRL) интегрируемый по технологии API в любое программное обеспечение (далее по тексту – ЭС). Автоматизированная информационная система «Социнформ» не собирает и не обрабатывает персональные данные.</li>
      <li>Программное обеспечение – среда стороннего программного обеспечения, в которую по технологии API интегрируется электронный сервис автоматизированной информационной системы «Социнформ».</li>
      <li>Границы (рамки) работы ЭС начинаются с кнопки «Пройти опрос», размещенной на главной странице Программного обеспечения, либо в ином разделе интерфейса Программного обеспечения и завершаются нажатием кнопки «Завершить опрос».</li>
    </ul>
  </li>,
  <>
    <b>Предмет Договора.</b>
    <ul>
      <li>Предметом данного Договора является предоставление Клиентом услуг Организации посредством предоставления своего мнения на интересующие Организацию вопросы в рамках проведения Организацией социологических опросов.</li>
    </ul>
    <li>
      <b>Область действия Договора.</b>
      <ul>
        <li>Действие настоящего Договора распространяется на проводимые Организацией социологические опросы посредством Инфоматов.</li>
      </ul>
    </li>
    <li>
      <b>Факт заключения Договора.</b>
      <ul>
        <li>Фактом заключения настоящего Договора со стороны Клиента, то есть полным и безоговорочным принятием Клиентом условий настоящего Договора в соответствии с п. 1 ст. 433 и п. 3 ст. 438 Гражданского кодекса Российской Федерации, является нажатие кнопки «Принять условия оферты».</li>
      </ul>
    </li>
  </>,
  <>
    <b>Права и обязанности сторон.</b>
    <li>
      Клиент имеет право:
      <ul>
        <li>Отказаться от предоставления своего мнения (ответов на вопросы). Данный отказ считается односторонним отказом от исполнения настоящего Договора.</li>
        <li>В любой момент обратиться к инструкции и ознакомиться с правилами проведения социологических опросов.</li>
        <li>В одностороннем порядке расторгнуть действие настоящего Договора без уведомления Организации.</li>
        <li>Обратиться по указанному в разделе «Инструкция» номеру телефона для связи с оператором службы технической поддержки и решением вопроса о передаче денежных средств Клиенту, в случае отсутствия в устройстве выдачи денежных средств Инфомата для оплаты услуг.</li>
        <li>Ответить не на все вопросы социологического опроса.</li>
      </ul>
    </li>
    <li>Клиент обязуется:<ul>
      <li>Соблюдать все положения настоящего Договора.</li>
      <li>Соблюдать и придерживаться положения о конфиденциальности, которое предусмотрено настоящим Договором, а также всех взятых на себя обязательств перед Организацией.</li>
      <li>Не предпринимать никаких действий, а также не использовать никакие приборы и(или) программные продукты, которые могут привести к сбою работы Программного обеспечения и(или) Инфомата и его отдельных компонентов.</li>
      <li>Не использовать в коммерческих и в иных целях, не копировать, не размножать, не распространять, не воспроизводить информацию, содержащуюся в Программном обеспечении.</li>
    </ul>
    </li>
  </>,
  <>
    <b>Организация вправе:</b>
    <ul>
      <li>На своё усмотрение демонстрировать Клиенту во время исполнения настоящего Договора рекламные сообщения, содержание которых не относится к предмету настоящего Договора.</li>
      <li>Без предупреждения Клиента вносить изменения в программное обеспечение.</li>
      <li>Производить видеозапись или сохранение снимков экрана в любом количестве во время действия настоящего Договора без уведомления Клиента.</li>
      <li>Пользоваться и распоряжаться любой информацией, полученной от Клиента во время действия настоящего Договора.</li>
      <li>Без уведомления Клиента блокировать доступ Клиента к программному обеспечению в случае, если Организации стали известны факты не соблюдения Клиентом пункта 5.2.3 настоящего Договора.</li>
      <li>Предоставлять Клиенту любые доказательства соблюдения Организацией положений настоящего Договора, включая экспортированные отчеты и записи из Информационной системы, а также видеозаписи или снимки экрана, фиксирующие процесс использования Клиентом программного обеспечения.</li>
      <li>Распространять или передавать третьим лицам любую полученную от Клиента информацию.</li>
    </ul>
  </>,
  <li>
    <b>Стоимость услуг и порядок расчетов.</b>
    <ul>
      <li>Стоимость услуг определяется в зависимости от сформированного программным обеспечением перечня вопросов.</li>
      <li>Организация с использованием информационной системы «Социнформ» составляет (формирует) список вопросов случайным образом. Стоимость предоставленного ответа на каждый вопрос определяется заведомо Организацией. Стоимость вопросов может варьироваться. Допустимы варианты, когда программное обеспечение формирует перечень, состоящий из вопросов, стоимость ответа на которые оценивается Организацией в ноль рублей.</li>
      <li>После предоставления Клиентом ответов на вопросы, сформированные Организацией, информационная система «Социнформ» суммирует номинальную стоимость за каждый ответ, предоставленный Клиентом. В случае, если суммарная стоимость предоставленных Клиентом ответов не равна нулю, то программное обеспечение после нажатия кнопки «выдать деньги» выплачивает Клиенту сумму, равную стоимости оказанных услуг.</li>
      <li>Обязательства Организации перед Клиентом считаются исполненными надлежащим образом и в полном объеме в случае выплаты Клиенту стоимости оказанных услуг либо.</li>
    </ul>
  </li>,
  <>
    <b>Ответственность сторон.</b><ul>
      <li>Клиент несет ответственность перед Организацией в случае порчи Инфомата и(или) отдельных его компонентов, а также за умышленную порчу программного обеспечения.</li>
      <li>Меры ответственности сторон, не предусмотренные настоящим Договором, применяются в соответствии с нормами действующего законодательства РФ.</li>
    </ul>
    <b>Ограничение ответственности.</b><ul>
      <li>При любых обстоятельствах Организация не несет ответственности за достоверность информации, размещенной на любой из страниц или директорий программного обеспечения.</li>
      <li>Клиент освобождает Организацию от какой-либо ответственности за возможный материальный и(или) моральный ущерб, причиненный ему в результате использования программного обеспечения.</li>
      <li>Организация не несет ответственность за перебои в работе программного обеспечения.</li>
    </ul>
  </>,
  <>
    <li>
      <b>Срок действия Договора.</b>
      <ul>
        <li>Настоящий Договор вступает в силу с момента заключения и действует до выплаты Клиенту суммы, равной стоимости оказанных услуг, либо вывода на экран благодарственного сообщения.</li>
      </ul>
    </li>
    <li>
      <b>Заключительное положение.</b>
      <ul>
        <li>Все возникающие между сторонами споры и разногласия, касающиеся настоящего Договора, стороны разрешают путем переговоров.</li>
      </ul>
    </li>
  </>
];

const tgkItems = [
  <>
    <p>Натиҷаҳои бурди чиптаҳои харидори кардашуда дар қисми болоии монитор нишон дода мешавад, инчуниг рақамҳои чиптаҳо ва маблағи бурди онҳо ҳам инъикос карда мешаванд.</p>
    <p>Бо ин усули кори таҷҳизоти лоторея пас аз қабули пардохт барои иштирок дар лоторея электронии дастрасии чиптаи электронии лоторея ( чиптаҳои электронии лоторея ) тариқи намоиши он дар равзанаи итилоотӣ "Пешниҳоди Чиптаи Лоторея" сурат мегирад ва инчунин инъикоси маълумот оиди чиптаи лоторея чиптаҳои лоторея дар ҷадвале, ки дар монитори таҷҳизоти лоторея ҷойгир аст. Пинҳон кардани маълумот оиди будан ё набудани бурд бо дастури иштирокчии лоторея аз ҷониби паҳнкунанда ё намояндаи ӯ, бо кумаки таҷҳизоти лоторея ошкор карда мешавад.</p>
    <p>Маълумоти ошкоршуда оиди бурд бурди маблағи дар ҷадвали монитори лоторея дар ру ба руи чиптаи дахлдори лоторея чиптаҳои электронии лоторея нишон дода мешавад.</p>
  </>,
  <>
    <p>Дар ҳолати мавҷуд будани шубҳа оиди эътимоднокии итилоот дпр бораи чиптаҳои лоторея харидоришуда ва рамзҳои махфии оиди бур ошкоршуда, бо дархости иштирокчии лоторея паҳнкунанда ё намоянда ки ба таҷҳизоти лотореяи мазкурро хизматрасони мекунанд, бояд фавран чиптаи лотореяра ( аз реестрӣ чоп шуда ё сервери ташкилкунанда лоторея) ба иштирокчии лоторея барои муқоиса пешниҳод намояд, ки аз ҷониби иштирокчии лоторея харидори шудаанд ва барои бурд ба у пардохт карда мешаванд.</p>
    <p>Зимни ошкор намудани итилооти махфи дар бораи мавҷуд будани бурд бо пардохт анҷом дода мешаванд, бо бартараф кардани чиптаи лотореяи автомати минбаъдаи таҷҳтзоти лоторея. Пас аз пардохти бурд чиптаи лотореяи пардохтшуда ҳисоби мешавад ва минбаъд барои пардохти бурд асос намешавад.</p>
    <p>Иштирокчии лоторея имкон дорад барои харидорӣ чипта лоторея бо маблағи бурд ё аз намояндаи паҳнкунанда, ки ба таҷҳизоти лотореяи мазкур хизматрасони мекнанд.</p>
  </>,
  <>
    <p>4. Интихоби усули кори таҷҳизоти лоторея бевосита аз ҷониби иштирокчии лоторея амалӣ шуда, ба воситаи пахшкунт тугмачаи дар назди монитор ҷойгирбуда, то пардохт барои иштирок дар лоторея.</p>
    <p>Таҷҳизоти лоторея ба шумо имкон медиҳад барои харидории якдафъа ва якчанд(максимум даҳ) чиптаҳои лоторея ба андозаи дахлдори маблағи пардохтшудаи пул, дар асоси нархи як чиптаи лоторея, дар ҳаҷми 10 дирам, 20 дирам, 50 дирам, 1(як), 2(ду), 3(се), 4(чор), 5(панҷ), 6(шаш), 7(ҳафт), 8(ҳашт), 9(нуҳ), 10(даҳ), 20(бист), 40(чил), 60(шаст), 80(ҳаштод), 100(сад) сомонӣ.Таҷҳизоти лоторея имкон намедиҳад, ки ба ғайри додани тарзи супоридани чиптаҳои пас аз супоридани(пардохт барои иштирок дар лоторея).Маблағ барои чиптаи лотореяи хкридоришуда баргардонида намешавад.</p>
    <p>Мутобиқи моддаи 467-470 Кодекси Граждани Т.Ҷ, дар сурати қабули пешнтҳоди зикршуда, қабули пешниҳоди мазкурро анҷом диҳед, бо пардохти арзиши чиптаҳои лоторея. Қабули пешниҳод дар бастани шартнома бо шартҳои дар пешн ҳод овардашуда баробарқувват дониста мешавад.</p>
  </>
];

const Oferta = ({ hideOferta, lang }) => {
  lang = lang.toLowerCase();
  const [currentContent, setCurrentContent] = React.useState(0);
  const ofertaItems = lang === 'tgk' ? tgkItems : dafaultItems;
  const nextTxt = lang === 'tgk' ? 'ба пеш' : 'вперёд';
  const acceptTxt = lang === 'tgk' ? 'шартҳоро қабул кунед' : 'принять условия';
  const publicOfferTxt = lang === 'tgk' ? 'ПЕШНИҲОДИ ОММАВӢ' : 'публичная оферта';
  return (
    <OfertaContainer>
      <Heading>{publicOfferTxt}</Heading>
      <Text align="center">
        {ofertaItems[currentContent]}
      </Text>
      <FlexBox flexWrap="wrap" alignSelf="center" justify="center">
        <Button onClick={() => currentContent < ofertaItems.length - 1 ? setCurrentContent(currentContent + 1) : setCurrentContent(0)} margin="0 40px 0 0">
          {nextTxt}
        </Button>
        <Button fontSize="18px" onClick={hideOferta}>
          {acceptTxt}
        </Button>
      </FlexBox>
    </OfertaContainer>
  );
};

export default Oferta;
