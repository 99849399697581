import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Container, AcceptConditionsText, ConditionsLink } from './styles';
import { Button } from '../Rules/styles';

import CustomCheckbox from 'components/Common/Checkbox';
import { FlexBox } from 'components/Common/FlexBox';
import { useShowBlock } from 'hooks/useShowBlock';
import Oferta from '../GameOffer';
import { soundPlayer } from 'utils/soundPlayer';
import check from '../../../../assets/sounds/Check.wav';
import { useSelector } from 'react-redux';
import { authSelector } from 'redux/authReducer';

const StartPoll = ({ startPoll, ...props }) => {
  const { isBlockShown, hideBlock, showBlock } = useShowBlock();
  const [isChecked, setIsChecked] = React.useState(false)
  const { authInfo: { info } } = useSelector(authSelector);

  const handleKeyPress = e => {
    const { agree = [], disagree = [], start_poll = [] } = info && info.hotkeys;
    const { code } = e;
    if (!!info.hotkeys) {
      if (code === agree[0]) {
        setIsChecked(!isChecked)
        soundPlayer(check).play()
      } else if (code === disagree[0]) {
        setIsChecked(!isChecked)
        soundPlayer(check).play()
      } else if (code === start_poll[0] && isChecked) {
        startPoll()
      }
    }
  }

  React.useEffect(() => {
    if (info && info.hotkeys) {
      document.addEventListener("keydown", handleKeyPress);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line
  }, [info, isChecked]);

  if (isBlockShown) {
    return <Oferta hideOferta={hideBlock} lang={info.lang} />;
  }
  return (
    <Container>
      <Button onClick={startPoll} disabled={!isChecked} margin="0 0 5% 0">
        НАЧАТЬ НОВЫЙ ОПРОС
      </Button>
      <FlexBox alignItems="center">
        <FormControlLabel
          control={
            <CustomCheckbox
              checked={isChecked}
              onChange={() => {
                soundPlayer(check).play()
                setIsChecked(!isChecked)
              }}
              name={props.name}
            />
          }
          label={
            <AcceptConditionsText>
              Я соглашаюсь с условиями{'  '}
              <ConditionsLink
                onClick={(e) => {
                  e.stopPropagation();
                  showBlock();
                }}
              >
                {' '}
                публичной оферты
              </ConditionsLink>{' '}
            </AcceptConditionsText>
          }
        />
      </FlexBox>
    </Container>
  );
};

export default StartPoll;
