import styled from 'styled-components';
import button from 'assets/images/button.png';

export const Wrapper = styled.div`
  position: relative;
  font-size: 3vh;
  color: rgb(225, 176, 88);
  line-height: 1;
  

  .buttons {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    border: 3px solid #e1b058;
    border-radius: 10px;
    padding: 5%;
    background: #25132e;
  }
  @media (max-width: 900px) {
    border: 3px solid #e1b058;
    border-radius: 10px;
    padding: 5%;
    background: #25132e;
  }
`;

export const Button = styled.button`
  width: auto;
  /* height: 50px; */
  padding: 0 20px 5px 20px;
  color: ${(props) => (props.active ? 'white' : '#5a3f1c')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-size: 32px;
  margin: 1%;
  border-image: ${`url(${button})`};
  border-image-slice: 77;
  border-image-width: 50px;
  border-image-outset: 0;
  border-image-repeat: round;
  background: red;
  border-radius: 15px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-transform: inh;
  font-weight: bold;
  /* transition: all 0.3s ease; */
  cursor: pointer;
  text-shadow: ${(props) => (props.active ? '2px 4px 6px #7b4310' : 'none')};

  background: ${(props) =>
    props.active
      ? 'linear-gradient(to right,rgba(239, 219, 116, 1) 0%,rgba(239, 219, 116, 1) 19%,rgba(223, 179, 85, 1) 63%,rgba(223, 179, 85, 1) 66%,rgba(224, 180, 86, 1) 100%)'
      : 'linear-gradient(to right, rgba(239, 219, 116, 1) 0%,rgba(239, 219, 116, 1) 8%,rgba(194, 147, 71, 1) 27%,rgba(239, 219, 116, 1) 60%,rgba(194, 147, 71, 1) 86%,rgba(239, 219, 116, 1) 100%)'};

  &:hover {
    color: white;
    text-shadow: 2px 4px 6px #7b4310;
    background: linear-gradient(
      to right,
      rgba(239, 219, 116, 1) 0%,
      rgba(239, 219, 116, 1) 19%,
      rgba(223, 179, 85, 1) 63%,
      rgba(223, 179, 85, 1) 66%,
      rgba(224, 180, 86, 1) 100%
    );
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 1024px) {
    height: 100%;
  }
`;
