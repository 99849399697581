import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  makeStyles,
  Card,
  CardMedia,
  Box,
  Button,
  Typography,
} from '@material-ui/core';

import {theme} from 'theme/theme';
import {useShowBlock} from 'hooks/useShowBlock';

import AppButton from 'components/Common/Button';
import NewsItemModal from '../NewsItemModal';
import {FlexBox} from 'components/Common/FlexBox';

dayjs.extend(relativeTime);

const useStyles = makeStyles((muTheme) => ({
  card: {
    width: 285,
    height: 240,
    borderRadius: 0,
    marginRight: 20,
    marginBottom: 20,
    cursor: 'pointer',
    background: theme.colors.darkBlue1,
    border: `2px solid rgba(0,0,0,0)`,
    boxShadow: 'none',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1024px)': {
      width: 236,
      height: 264,
    },
    '@media (max-width: 768px)': {
      width: 172,
      height: 272,
    },
    '@media (max-width: 670px)': {
      width: '100%',
      height: 'unset',
    },
    '@media (max-width: 616px)': {
      width: '100%',
      height: 'unset',
    },

    '&:hover': {
      boxShadow: theme.blockShadow,
      border: `2px solid ${theme.colors.mainBlue}`,
    },

    '&:first-of-type': {
      width: 590,
      height: 500,
      gridColumn: '1 / 3',
      gridRow: '1 / 3',

      '@media (max-width: 1024px)': {
        width: 488,
        height: 543,
      },
      '@media (max-width: 768px)': {
        width: 360,
        height: 560,
      },

      '@media (max-width: 670px)': {
        width: '100%',
        height: 'unset',
      },
      '@media (max-width: 616px)': {
        width: '100%',
        height: 'unset',
      },
    },
  },
  overlay: {
    background: 'rgba(0, 10, 36, 0.6)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99,
    opacity: 0,

    '&:hover': {
      boxShadow: theme.blockShadow,
      opacity: 1,
    },
  },
  box: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '0px',
    marginTop: '15px',
    alignItems: 'flex-start',
    '@media (max-width: 1024px)': {
      marginBottom: 5,
      marginTop: 10,
    },
    '@media (max-width: 768x)': {
      marginBottom: 10,
      marginTop: 15,
    },
  },
  date: {
    fontSize: '14px',
    lineHeight: '16px',
    fontFamily: 'Roboto',
    color: '#BFBFBF',
    '@media (max-width: 768px)': {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },

  title: {
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.colors.white,
    marginBottom: '5px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    '@media (max-width: 1030px)': {
      marginBottom: '15px',
    },
    '@media (max-width: 768px)': {
      fontSize: '18px',
      lineHeight: '22px',
      '-webkit-line-clamp': 3,
    },
    '@media (max-width: 670px)': {
      marginTop: '10px',
      display: 'block',
    },
  },
  cardInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '0px 20px',
    flex: '20%',
  },
  // img: {
  //   flex: '40%',
  // },
  text: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#BFBFBF',
    fontFamily: 'Roboto',
    marginBottom: 5,
    marginTop: 10,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 5,
    '@media (max-width: 670px)': {
      display: 'none',
    },
  },
  readButton: {
    zIndex: 100,
    width: '117px',
    height: '35px',
    background: 'rgba(14, 154, 252, 0.5)',
    border: `1px solid ${theme.colors.mainBlue}`,
    borderRadius: 0,
    fontSize: theme.fontSizes.text,
    lineHeight: theme.lineHeights.text,
    color: theme.colors.white,
    textTransform: 'inherit',
    boxShadow: '0px 0px 10px 0px rgba(14, 154, 252, 0.4)',
    '&:hover': {
      background: 'rgba(14, 154, 252, 0.5)',
    },
  },

  img: {
    height: '50%',
  },
}));

const NewsItem = ({item, currentPage, main}) => {
  const {title, id, created_at, tags, url, content} = item;
  const classes = useStyles();
  const {isBlockShown, showBlock, hideBlock} = useShowBlock();

  return (
    <>
      {isBlockShown && (
        <NewsItemModal
          itemId={id}
          hideBlock={hideBlock}
          currentPage={currentPage}
        />
      )}
      <Card className={classes.card}>
        <Box className={classes.overlay} onClick={showBlock}>
          <Button className={classes.readButton} onClick={showBlock}>
            Читать
          </Button>
        </Box>
        <CardMedia
          component="img"
          image={url}
          // height={main ? '270' : '120'}
          className={classes.img}
        />
        <Box className={classes.cardInfo}>
          <Box className={classes.box}>
            <FlexBox align="flex-start" justtify="flex-start" flexWrap="wrap">
              {tags &&
                tags
                  .slice(0, 1)
                  .map((tag) => (
                    <AppButton
                      text={tag.name}
                      key={tag.id}
                      margin="0 0 10px 0"
                    />
                  ))}
            </FlexBox>
            <span className={classes.date}>
              {dayjs(created_at).locale('ru-RU').fromNow()}
            </span>
          </Box>
          <p className={classes.title}>{title}</p>
          {main && <Typography className={classes.text}>{content}</Typography>}
        </Box>
      </Card>
    </>
  );
};

export default NewsItem;
