import React from 'react';
import {Wrapper} from './styles';
import {saveKey} from '../../../../services/hotkeys';
import {useSelector} from 'react-redux';
import {mainContentSelector} from 'redux/mainContentReducer';
import Preloader from 'components/Common/Preloader';

const ChangeHotkey = ({hideChangeHotkey}) => {
  const [selectedHotkey, setSelectedHotkey] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const {selectedActionKey} = useSelector(mainContentSelector);

  const handlerChangeHotkey = React.useCallback((key, action) => {
    setIsLoading(true);

    saveKey(key, action).then((res) => {
      if (res.status === 'ok') {
        setIsLoading(false);

        setTimeout(() => {
          setSelectedHotkey(null);
          hideChangeHotkey();
        }, 2000);
      } else if (res.status === 'error') {
        setIsLoading(false);

        setError(res.error);
        setTimeout(() => {
          setSelectedHotkey(null);
          hideChangeHotkey();
        }, 2000);
      }
    });
  }, [hideChangeHotkey])

  const handlerKey = React.useCallback(
    (e) => {
      !selectedHotkey && setSelectedHotkey(e.code);
      handlerChangeHotkey(e.code, selectedActionKey);
    },
    [selectedHotkey, selectedActionKey, handlerChangeHotkey]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handlerKey);

    return () => {
      document.removeEventListener('keydown', handlerKey);
    };
  }, [selectedHotkey, handlerKey]);
  return isLoading ? (
    <Preloader />
  ) : error ? (
    <Wrapper>
      <h3>{error}</h3>
    </Wrapper>
  ) : (
    <Wrapper>
      {selectedHotkey ? (
        <>
          <h3>Выбранная клавиша: {selectedHotkey}</h3>
        </>
      ) : (
        <h3>Нажмите любую клавишу</h3>
      )}
    </Wrapper>
  );
};

export default ChangeHotkey;
