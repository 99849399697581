import React from 'react';

import { Text, Heading, Button } from '../Rules/styles';
import { OfertaContainer } from './styles';

import { FlexBox } from 'components/Common/FlexBox';

const dafaultItems = [
  <>
    <p><strong>ООО «Альтера»</strong></p>
    <p><strong>ПРИКАЗ</strong></p>
    <p>01 января 2023г. № оф-010123-1</p>
    <p> г. Москва </p>
    <p><strong>Об утверждении публичной оферты</strong></p>
    <p>В соответствии со статьей 435 и частью 2 статьи 437 Гражданского кодекса Российской Федерации.</p>
    <p><span><strong>приказываю:</strong></span></p>
    <ol>
      <li>Утвердить предложение оферты в соответствии с Приложением № 1 к настоящему приказу.</li>
      <li>Определить срок действия оферты – 31.12.2023 года.</li>
      <li>Контроль за исполнением взятых обязательств в соответствии с офертой оставляю за собой.</li>
    </ol>
    <p>Генеральный Директор А.Ю. Цыбушкин</p>
  </>,
  <>
    <p><span>Приложение № 1</span></p>
    <p><span>к приказу Генерального Директора</span></p>
    <p><span>ООО «Альтера»</span></p>
    <p><span>От 01 января 2023г. № оф-010123-1</span></p>
    <p><span>ПУБЛИЧНАЯ ОФЕРТА</span></p>
    <li>Настоящее предложение является публичной офертой в соответствии со статьей 435 и частью 2 статьи 437 Гражданского кодекса Российской Федерации (далее – оферта).</li>
    <li>Настоящая оферта определяет взаимоотношения между ООО «Альтера» (ОГРН 1227700366365) в лице Генерального директора Цыбушкина Андрея Юрьевича, действующего на основании Устава, именуемое в дальнейшем Организация, и физическим или юридическим лицом, принявшим оферту о заключении Договора, именуемый в дальнейшем Клиент.</li>
    <li>Организация выражает намерение заключить Договор с любым физическим или юридическим лицом и гарантирует исполнение взятых на себя в соответствии с Договором обязательств.</li>
    <li>Клиент, от своего имени и от имени любых иных лиц, заинтересованных в определяемых данным Договором услугах, принимая данный оферту, выражает свое согласие с тем, что условия настоящего Договора не ущемляют законных прав Клиента и любых иных лиц, от имени которых действует Клиент.</li>
  </>,
  <li>
    <b>Термины и определения.</b>
    <ul>
      <li>Под понятием Клиент следует понимать лицо, выразившее согласие на заключение Договора (далее по тексту – Клиент).</li>
      <li>Инфомат – электронно-вычислительная машина, иногда оснащенная дополнительными устройствами (далее по тексту – Инфомат).</li>
      <li>Информационная система – автоматизированный комплекс программных средств, управляющий функциями Инфомата (далее по тексту – ИС). ИС включает в себя общесистемное программное обеспечение, драйвера и службы, обеспечивающие работоспособность Инфомата и отдельных элементов (устройств), включенных в состав общего оборудования Инфомата, а также графический интерфейс главной страницы ИС. ИС обеспечивает функционирование всех технических средств и устройств, включенных в состав Инфомата, а также автоматическое восстановление штатной работы всех технических средств и устройств Инфомата в случае сбоев в работе Инфомата. Информационная система не собирает и не обрабатывает персональные данные.</li>
      <li>Программное обеспечение – программа, позволяющая Клиенту управлять информационным и развлекательным контентом в целях получения удовольствия. Программное обеспечение не собирает и не обрабатывает персональные данные.</li>
      <li>Границы (рамки) Программного обеспечения начинаются с главной страницы интерфейса инфомата и включают в себя поддиректории раздела с каталогами, страницами и ссылками выраженными как каталоги, страницы и ссылки Программного обеспечения, так и сторонних (интегрированных) программ.</li>
    </ul>
  </li>,
  <>
    <li>Электронные очки – это игровая валюта, приобретаемая Клиентом в рамках заключаемого Договора. Используется для активации игр и фиксирования достигнутого результата.</li>
    <li>Таблица рекордов – раздел программного обеспечения, в котором Клиенты могут зафиксировать количество набранных электронных очков.</li>
    <b>Предмет Договора.</b>
    <ul>
      <li>Предметом Договора является предоставление Организацией посредством Инфомата информационных и развлекательных услуг с использованием Программного обеспечения.</li>
    </ul>
    <li>
      <b>Область действия Договора.</b>
      <ul>
        <li>Действие настоящего Договора распространяется на использование программного обеспечения, установленного на Инфоматах Организации.</li>
      </ul>
    </li>
    <li>
      <b>Факт заключения Договора.</b>
      <ul>
        <li>Фактом заключения настоящего Договора со стороны Клиента, то есть полным и безоговорочным принятием Клиентом условий настоящего Договора и всех Приложений настоящему Договору, являющихся неотъемлемой частью настоящего Договора, в соответствии с п. 1 ст. 433 и п. 3 ст. 438 Гражданского кодекса Российской Федерации, является внесения наличных денежных средств в принимающее купюры (монеты) устройство Инфомата.</li>
      </ul>
    </li>
  </>,
  <>
    <b>Права и обязанности сторон.</b>
    <li>
      Клиент имеет право:
      <ul>
        <li>В полной мере получать услуги в рамках использования Программного обеспечения.</li>
        <li>В любой момент обратиться к инструкции и ознакомиться с правилами просмотра развлекательного контента и правилами использования развлекательного контента.</li>
        <li>В одностороннем порядке расторгнуть действие настоящего Договора без уведомления Организации.</li>
        <li>Переуступить право расходования электронных очков, приобретенных Клиентом, третьему лицу без уведомления Организации.</li>
        <li>В случае, если количество очков не равно нулю, зафиксировать в таблице рекордов количество электронных очков в соответствии с правилами использования программного обеспечения. При фиксации текущего количества электронных очков в таблице рекордов, текущее количество электронных очков обнуляется до значения нуля.</li>
      </ul>
    </li>
    <li>Клиент обязуется:<ul>
      <li>Соблюдать все положения настоящего Договора.</li>
      <li>Соблюдать и придерживаться положения о конфиденциальности, которое предусмотрено настоящим Договором, а также всех взятых на себя обязательств перед Организацией.</li>
      <li>Не предпринимать никаких действий, а также не использовать никакие приборы и(или) программные продукты, которые могут привести к сбою работы Программного обеспечения и(или) Инфомата и его отдельных компонентов.</li>
      <li>Не использовать в коммерческих и в иных целях, не копировать, не размножать, не распространять, не воспроизводить информацию, содержащуюся в Программном обеспечении.</li>
    </ul>
    </li>
  </>,
  <>
    <b>Организация вправе:</b>
    <ul>
      <li>На своё усмотрение демонстрировать Клиенту во время просмотра информационного контента или процесса использования Клиентом развлекательного контента рекламные сообщения, содержание которых не относится к предмету настоящего Договора.</li>
      <li>Без предупреждения Клиента редактировать информационный или развлекательный контент.</li>
      <li>Производить видеозапись или сохранение снимков с экрана в любом количестве во время просмотра Клиентом информационного контента или использования Клиентом развлекательного контента без уведомления Клиента.</li>
      <li>Пользоваться и распоряжаться любой информацией, полученной от Клиента во время действия настоящего Договора.</li>
      <li>Без уведомления Клиента блокировать доступ Клиента к программному обеспечению в случае, если Организации стали известны факты несоблюдения Клиентом пункта 5.2.3 настоящего Договора.</li>
      <li>Предоставлять Клиенту любые доказательства соблюдения Организацией положений настоящего Договора, включая экспортированные отчеты и записи из Информационной системы, а также воспроизводить видеозаписи или демонстрировать снимки экрана, фиксирующие процесс использования Клиентом программного обеспечения.</li>
      <li>Распространять или передавать третьим лицам любую полученную от Клиента информацию.</li>
    </ul>
    <li>
      <b>Организация обязуется:</b>
      <ul>
        <li>Выполнить все обязательства, взятые на себя путем заключения настоящего Договора.</li>
      </ul>
    </li>
  </>,
  <li>
    <b>Стоимость услуг и порядок расчетов.</b>
    <ul>
      <li>С Клиента не взимается плата за просмотр бесплатно предоставляемого Организацией посредством программного обеспечения контента (правил, страниц и директорий программного обеспечения, доступных для просмотра, как до заключения настоящего Договора, так и в момент действия настоящего Договора).</li>
      <li>С Клиента взимается плата путем авансового платежа за просмотр информационного контента и использование развлекательного контента.</li>
      <li>Осуществляя платёж, Клиент получает электронные очки в количестве, равном количеству внесенных авансовым платежом денежных средств, количество которых рассчитывается по формуле: один Российский рубль равен одному электронному очку. Количество электронных очков отображается в окошке «очки» на всех страницах и директориях программного обеспечения.</li>
      <li>Количество электронных очков может уменьшаться или увеличиваться в зависимости от действий Клиента при использовании программного обеспечения.</li>
      <li>Организация не осуществляет обмен электронных очков на денежные средства либо иные материальные ценности.</li>
      <li>Организация не возмещает Клиенту денежными средствами либо иными материальными ценностями неизрасходованные Клиентом электронные очки.</li>
      <li>Неизрасходованные Клиентом электронные очки обнуляются до значения нуля в случае бездействия программного обеспечения в течение 30 минут.</li>
    </ul>
  </li>,
  <>
    <li>При завершении действия настоящего Договора или расторжении настоящего Договора Клиентом в одностороннем порядке Организация не возмещает Клиенту денежными средствами либо иными материальными ценностями неизрасходованные Клиентом электронные очки.<br />Обязательства Организации перед Клиентом считаются исполненными надлежащим образом в случаях обнуления количества электронных очков, предусмотренных настоящим Договором.</li>
    <b>Ответственность сторон.</b><ul>
      <li>Клиент несет ответственность перед Организацией в случае порчи Инфомата и(или) отдельных его компонентов, а также за умышленную порчу программного обеспечения.</li>
      <li>Меры ответственности сторон, не предусмотренные настоящим Договором, применяются в соответствии с нормами действующего законодательства РФ.</li>
    </ul>
    <b>Ограничение ответственности.</b><ul>
      <li>Организация не несет ответственности за достоверность описания, качество и совокупные потребительские свойства услуг.</li>
      <li>При любых обстоятельствах Организация не несет ответственности за достоверность информации, размещенной на любой из страниц или директорий программного обеспечения.</li>
      <li>Клиент освобождает Организацию от какой-либо ответственности за возможный материальный и(или) моральный ущерб, причиненный ему в результате использования программного обеспечения.</li>
      <li>Организация не несет ответственность за перебои в работе программного обеспечения.</li>
      <li>Организация не несет ответственность за внешний вид Инфомата и информацию, размещенную на наружных стенках и элементах Инфомата.</li>
    </ul>
  </>,
  <>
    <li><b>Срок действия Договора.</b><ul>
      <li>Настоящий Договор вступает в силу с момента заключения и действует до момента обнуления количества электронных очков до значения нуля в случаях предусмотренных настоящим Договором, либо до расторжения Клиентом настоящего Договора в одностороннем порядке.</li>
    </ul></li>
    <li><b>Заключительное положение.</b><ul>
      <li>Все возникающие между сторонами споры и разногласия, касающиеся настоящего Договора, стороны разрешают путем переговоров.</li>
    </ul></li>
    <p>Генеральный Директор А.Ю. Цыбушкин</p>
  </>
];

const tgkItems = [
  <>
    <p>Натиҷаҳои бурди чиптаҳои харидори кардашуда дар қисми болоии монитор нишон дода мешавад, инчуниг рақамҳои чиптаҳо ва маблағи бурди онҳо ҳам инъикос карда мешаванд.</p>
    <p>Бо ин усули кори таҷҳизоти лоторея пас аз қабули пардохт барои иштирок дар лоторея электронии дастрасии чиптаи электронии лоторея ( чиптаҳои электронии лоторея ) тариқи намоиши он дар равзанаи итилоотӣ "Пешниҳоди Чиптаи Лоторея" сурат мегирад ва инчунин инъикоси маълумот оиди чиптаи лоторея чиптаҳои лоторея дар ҷадвале, ки дар монитори таҷҳизоти лоторея ҷойгир аст. Пинҳон кардани маълумот оиди будан ё набудани бурд бо дастури иштирокчии лоторея аз ҷониби паҳнкунанда ё намояндаи ӯ, бо кумаки таҷҳизоти лоторея ошкор карда мешавад.</p>
    <p>Маълумоти ошкоршуда оиди бурд бурди маблағи дар ҷадвали монитори лоторея дар ру ба руи чиптаи дахлдори лоторея чиптаҳои электронии лоторея нишон дода мешавад.</p>
  </>,
  <>
    <p>Дар ҳолати мавҷуд будани шубҳа оиди эътимоднокии итилоот дпр бораи чиптаҳои лоторея харидоришуда ва рамзҳои махфии оиди бур ошкоршуда, бо дархости иштирокчии лоторея паҳнкунанда ё намоянда ки ба таҷҳизоти лотореяи мазкурро хизматрасони мекунанд, бояд фавран чиптаи лотореяра ( аз реестрӣ чоп шуда ё сервери ташкилкунанда лоторея) ба иштирокчии лоторея барои муқоиса пешниҳод намояд, ки аз ҷониби иштирокчии лоторея харидори шудаанд ва барои бурд ба у пардохт карда мешаванд.</p>
    <p>Зимни ошкор намудани итилооти махфи дар бораи мавҷуд будани бурд бо пардохт анҷом дода мешаванд, бо бартараф кардани чиптаи лотореяи автомати минбаъдаи таҷҳтзоти лоторея. Пас аз пардохти бурд чиптаи лотореяи пардохтшуда ҳисоби мешавад ва минбаъд барои пардохти бурд асос намешавад.</p>
    <p>Иштирокчии лоторея имкон дорад барои харидорӣ чипта лоторея бо маблағи бурд ё аз намояндаи паҳнкунанда, ки ба таҷҳизоти лотореяи мазкур хизматрасони мекнанд.</p>
  </>,
  <>
    <p>4. Интихоби усули кори таҷҳизоти лоторея бевосита аз ҷониби иштирокчии лоторея амалӣ шуда, ба воситаи пахшкунт тугмачаи дар назди монитор ҷойгирбуда, то пардохт барои иштирок дар лоторея.</p>
    <p>Таҷҳизоти лоторея ба шумо имкон медиҳад барои харидории якдафъа ва якчанд(максимум даҳ) чиптаҳои лоторея ба андозаи дахлдори маблағи пардохтшудаи пул, дар асоси нархи як чиптаи лоторея, дар ҳаҷми 10 дирам, 20 дирам, 50 дирам, 1(як), 2(ду), 3(се), 4(чор), 5(панҷ), 6(шаш), 7(ҳафт), 8(ҳашт), 9(нуҳ), 10(даҳ), 20(бист), 40(чил), 60(шаст), 80(ҳаштод), 100(сад) сомонӣ.Таҷҳизоти лоторея имкон намедиҳад, ки ба ғайри додани тарзи супоридани чиптаҳои пас аз супоридани(пардохт барои иштирок дар лоторея).Маблағ барои чиптаи лотореяи хкридоришуда баргардонида намешавад.</p>
    <p>Мутобиқи моддаи 467-470 Кодекси Граждани Т.Ҷ, дар сурати қабули пешнтҳоди зикршуда, қабули пешниҳоди мазкурро анҷом диҳед, бо пардохти арзиши чиптаҳои лоторея. Қабули пешниҳод дар бастани шартнома бо шартҳои дар пешн ҳод овардашуда баробарқувват дониста мешавад.</p>
  </>
];

const Oferta = ({ hideOferta, lang }) => {
  lang = lang.toLowerCase();
  const [currentContent, setCurrentContent] = React.useState(0);
  const ofertaItems = lang === 'tgk' ? tgkItems : dafaultItems;
  const nextTxt = lang === 'tgk' ? 'ба пеш' : 'вперёд';
  const acceptTxt = lang === 'tgk' ? 'шартҳоро қабул кунед' : 'принять условия';
  const publicOfferTxt = lang === 'tgk' ? 'ПЕШНИҲОДИ ОММАВӢ' : 'публичная оферта';
  return (
    <OfertaContainer>
      <Heading>{publicOfferTxt}</Heading>
      <Text align="center">
        {ofertaItems[currentContent]}
      </Text>
      <FlexBox flexWrap="wrap" alignSelf="center" justify="center">
        <Button onClick={() => currentContent < ofertaItems.length - 1 ? setCurrentContent(currentContent + 1) : setCurrentContent(0)} margin="0 40px 0 0">
          {nextTxt}
        </Button>
        <Button fontSize="18px" onClick={hideOferta}>
          {acceptTxt}
        </Button>
      </FlexBox>
    </OfertaContainer>
  );
};

export default Oferta;
