import styled from 'styled-components';
import bgk from 'assets/images/16_10/bg16_10.jpg';
import bgk4_3 from 'assets/images/4_3/bg_4_3.jpg';
import bgk16_10 from 'assets/images/16_10/bg16_10.jpg';
import {resolutions} from '../../../theme/utils';

const AuthPageWrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  background: ${`url(${bgk})`} no-repeat center / cover;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Georgia, sans-serif;
  scrollbar-width: none;
  /* ${resolutions.resolution_4_3} {
    background: ${`url(${bgk4_3})`};
  }
  ${resolutions.resolution_16_10} {
    background: ${`url(${bgk16_10})`};
  } */
  

  
`;

export {AuthPageWrapper};
