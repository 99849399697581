import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch, useSelector} from 'react-redux';

import {
  FiltersBlockContainer,
  Label,
  TextInputWrapper,
  ResetFilterButton,
} from './styles';

import ShadowText from 'components/Common/ShadowText';
import {FlexBox} from 'components/Common/FlexBox';
import ReactSelect from 'components/Common/ReactSelect';
import TextInput from 'components/Common/TextInput';
import MobileFiltersBlock from '../MobileFIltersBlock';
import TableBlock from '../TableBlock';

import {useForm} from 'hooks/useForm';
import { getGames, gamesSelector } from 'redux/gamesReducer';
import {tranformValues} from 'utils/helpers';
import {useShowBlock} from 'hooks/useShowBlock';

const FiltersBlock = () => {
  const dispatch = useDispatch();
  const {items} = useSelector(gamesSelector);
  const [selected, setSelected] = React.useState('');
  const {isBlockShown, toggleShowBlock, hideBlock} = useShowBlock();

  const {form, handleInputChange, setForm, clearForm} = useForm({
    player: '',
    points: '',
    date: '',
  });

  const handleGameChange = (game) => {
    setSelected(game);
  };

  const checkIfNotEmpty = () =>
    form.player.length !== 0 ||
    form.points.length !== 0 ||
    form.date.length !== 0;

  React.useEffect(() => {
    items.length === 0 && dispatch(getGames());
  }, [dispatch, items]);

  // React.useEffect(() => {
  //   dispatch(setSelectedGames(selected.id));
  // }, [dispatch, selected.id]);

  const clearInput = (name) => {
    setForm((state) => {
      return {
        ...state,
        [name]: '',
      };
    });
  };
  return (
    <>
      {isBlockShown && <MobileFiltersBlock hideBlock={hideBlock} />}
      <FiltersBlockContainer>
        <ShadowText text="Фильтровать по:" margin="0 0 7px 0" />
        <FlexBox direction="column" width="100%">
          <Label>Название игры</Label>
          <ReactSelect
            filter
            placeholder="Выберите название игры"
            options={items.length > 0 ? tranformValues(items) : []}
            custom
            onChange={handleGameChange}
            value={selected}
          />
        </FlexBox>
        <FlexBox direction="column" width="100%">
          <Label>Имя игрока</Label>
          <TextInputWrapper>
            <TextInput
              placeholder="Введите имя игрока"
              name="player"
              onChange={handleInputChange}
              value={form.player}
            />
            {form.player !== '' && (
              <CloseIcon
                className="closeIcon"
                onClick={() => clearInput('player')}
              />
            )}
          </TextInputWrapper>
        </FlexBox>
        <FlexBox direction="column" width="100%">
          <Label>Набранные очки</Label>
          <TextInputWrapper>
            <TextInput
              placeholder="Введите количество очков"
              name="points"
              onChange={handleInputChange}
              value={form.points}
            />
            {form.points !== '' && (
              <CloseIcon
                className="closeIcon"
                onClick={() => clearInput('points')}
              />
            )}
          </TextInputWrapper>
        </FlexBox>
        <FlexBox direction="column" width="100%">
          <Label>Дата</Label>
          <TextInputWrapper>
            <TextInput
              placeholder="10.02.2020"
              name="date"
              onChange={handleInputChange}
              value={form.date}
            />
            {form.date !== '' && (
              <CloseIcon
                className="closeIcon"
                onClick={() => clearInput('date')}
              />
            )}
          </TextInputWrapper>
        </FlexBox>
        {checkIfNotEmpty() && (
          <ResetFilterButton onClick={clearForm}>
            Сбросить фильтр
          </ResetFilterButton>
        )}
      </FiltersBlockContainer>
      <TableBlock
        points={form.points}
        date={form.date}
        toggleShowBlock={toggleShowBlock}
        selectedGame={selected}
      />
    </>
  );
};

export default FiltersBlock;
