import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Heading } from '../Rules/styles';
import { PollContainer, Sum, AnswerButton, Subheading } from './styles';
import { pollsSelector, answerQuestion, finishPoll, setCurrentQuestion } from 'redux/pollsReducer';
import { authSelector, setPollSum, updateInfo } from 'redux/authReducer';
import Preloader from 'components/Common/Preloader';
import { prettyNum } from 'utils/helpers';

const capitalizeFirstWord = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const Poll = ({ hidePoll }) => {
  const dispatch = useDispatch();
  const {
    currentPoll: { data, error, loading },
    currentQuestion,
    questionAnsweredLoading,
  } = useSelector(pollsSelector);
  const {
    authInfo: { info },
  } = useSelector(authSelector);
  const [isPollFinished, setIsPollFinished] = React.useState(false);

  const [endPollSum, setEndPollSum] = React.useState(0);

  const answerTheQuestion = (id) => {
    const questionId = data.questions[currentQuestion].id;
    const answerId = id;
    dispatch(answerQuestion({ questionId, answerId }));
  };

  const finishThePoll = React.useCallback(async () => {
    await dispatch(finishPoll());
    setIsPollFinished(true);
  }, [dispatch]);

  const postAnswer = (questId) => {
    if (!questionAnsweredLoading) {
      if (currentQuestion === 9) {
        setEndPollSum(pollSum());
        answerTheQuestion(questId);
      } else {
        answerTheQuestion(questId);
      }
    }
  };

  React.useEffect(() => {
    if (info.poll_sum > 0) setIsPollFinished(true);
  }, [info, info.poll_sum]);

  const InfoBalance = info && info.balance;
  const PollSum = info && info.poll_sum;
  React.useEffect(() => {
    if (currentQuestion === -1 && isPollFinished && PollSum === 0) {
      setIsPollFinished(false);
      hidePoll();
    }
    // eslint-disable-next-line
  }, [InfoBalance, PollSum, hidePoll, isPollFinished, error]);

  const pollSum = React.useCallback(() => {
    let sum = 0;
    data &&
      data.questions.forEach((item, id) => {
        if (id <= currentQuestion) {
          sum += item.sum;
        }
      });

    return prettyNum(sum);
  }, [data, currentQuestion]);

  React.useEffect(() => {
    if (currentQuestion === 10) {
      dispatch(setPollSum(pollSum()));
      dispatch(setCurrentQuestion(-1));
      finishThePoll();
    }
  }, [dispatch, currentQuestion, finishThePoll, pollSum]);

  //buttons control
  const handleKeyPress = (e) => {
    const { first_answer = [], second_answer = [], third_answer = [] } = info && info.hotkeys;
    const { code } = e;
    const getQuestionId = (btnId) => {
      let answerId;
      data &&
        data.questions[currentQuestion].answers.forEach((item, id) => {
          if (id === btnId) {
            answerId = item.id;
          }
        });
      return answerId;
    };

    if (
      !questionAnsweredLoading &&
      data &&
      data.questions &&
      currentQuestion < 10 &&
      currentQuestion !== -1
    ) {
      if (code === first_answer[0]) {
        postAnswer(getQuestionId(0));
      } else if (code === second_answer[0]) {
        postAnswer(getQuestionId(1));
      } else if (code === third_answer[0]) {
        postAnswer(getQuestionId(2));
      }
    }
  };

  React.useEffect(() => {
    if (info && info.hotkeys) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, [data, info, currentQuestion, questionAnsweredLoading]);

  React.useEffect(() => {
    const interval = setInterval(() => isPollFinished && dispatch(updateInfo()), 40000);
    return () => clearInterval(interval);
  }, [isPollFinished, dispatch]);

  if (isPollFinished) {
    return (
      <>
        {isPollFinished && (
          <PollContainer>
            <div className="question-wrapper fadeIn">
              <p style={{ textAlign: 'center' }}>
                Благодарим Вас за участие в социальном исследовании! Теперь Вы
                можете забрать заработанные деньги.
              </p>
              <Sum>
                <span>Сумма: </span>
                {endPollSum ? endPollSum : info && prettyNum(info.poll_sum)}
              </Sum>
            </div>
          </PollContainer>
        )}
      </>
    );
  }

  return (
    <>
      {!loading ? (
        <PollContainer>
          {data &&
            !loading &&
            currentQuestion > -1 &&
            currentQuestion !== 10 ? (
            <>
              {data &&
                data.questions?.map((question, index) => (
                  <React.Fragment key={question.id}>
                    {index === currentQuestion && (
                      <div className="question-wrapper fadeIn">
                        <Heading>социальные опросы</Heading>
                        <Subheading>{question.question.text}</Subheading>
                        {question.answers.map((quest) => (
                          <AnswerButton
                            key={quest.id}
                            onClick={() => {
                              postAnswer(quest.id);
                            }}
                          >
                            {capitalizeFirstWord(quest.text)}
                          </AnswerButton>
                        ))}
                        <Sum>
                          <span>Сумма: </span>
                          {pollSum()}
                        </Sum>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </>
          ) : (
            <Preloader />
          )}
        </PollContainer>
      ) : (
        ''
      )}
    </>
  );
};

export default Poll;
