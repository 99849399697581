import {Box} from '@material-ui/core';
import React from 'react';

const HistoryOfGambling = ({setTabSelected, setDirection}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
    >
      <span className="direction-title">
        <span className="outline">Выбор направления</span> -
        <b>История гемблинга</b>
      </span>
      <p>
        Для начала стоит определить сам термин "гемблинг". В общем понимании -
        это рискованные действия для приумножения ресурсов, результат зависит не
        от знаний или расчетов, а от везения.
      </p>
      <p>
        Азартные игры были неотъемлемой частью персидских городов, то есть на
        территории современного Ирана. Об этом в своих трудах неоднократно
        упоминает ученый и врачеватель, праотец медицины Авиценна, живший на
        стыке первого и второго тысячелетий нашей эры, тысячу лет назад.
      </p>
      <p>
        Азартные развлечения упоминаются и в Су́нне - священном для мусульман
        труде, содержащем описание жизни пророка Мухаммада. Согласно официальным
        сведениям, исламский пророк жил в средине первого тысячелетия новой эры,
        то есть полторы тысячи лет назад.
      </p>
      <p>
        Но оказывается, это не самый давний исторический след. В ведических
        писаниях, которые датируются 1700-1100 гг. до н.э. и считаются одними из
        самых древних из всех известных письменных источников, играм на интерес
        уделяется достаточно много внимания. В частности, в "Ригведе", одном из
        четырех ведических текстов, можно найти поэму "Плач игрока". Она
        изначально, как считают историки, была просьбой к высшим силам о победе
        в кости. Описывается что akșa, игровые кости, были сделаны из ореха и
        имели четыре стороны. Для выигрыша достаточно было получить в результате
        число, которое без остатка можно было поделить на четыре. Ведические
        гуру, кстати, не видят в игре греха, если это способ сбросить стресс,
        развлечься, но без зависимости. Даже бесполезная трата денег на игру
        рассматривается не большим злом, чем трата на еду или любое другое
        развлечение. Вопрос только в том, что игрок должен рисковать той суммой,
        которую готов потерять без ущерба для себя и своей семьи.
      </p>
      <p>
        О разрушении и возрождении мира с помощью азартных игр судили и в
        Древнем Египте еще пять с половиной тысяч лет назад. В Древней Греции же
        игры считались способом контакта с богами.
      </p>
      <p>
        Таким образом, обойдя всю планету в любом направлении, в каждом очаге
        цивилизации можно встретить нечто очень схожее и по принципу, и по форме
        на нынешние популярные виды гемблинга. Наверное, исследователи еще долго
        будут спорить о том, где же на планете Земля действительно то место,
        которое стало колыбелью игры на интерес.
      </p>
      <p>
        <b>
          Список игр в казино формировался постепенно и стал стандартным для
          всех заведений.
        </b>
      </p>
      <p>
        Первоначально слово casino переводилось с итальянского как "домик".
        Первый игорный дом открылся именно в Италии, а точнее в Венеции, в
        первой половине 17-го века: пристанище игроки нашли в церкви Св. Моисея.
        Именно таким образом правительство пыталось направить в какое-то русло
        неуемный азарт игроков в периоды карнавалов. До наших дней дошло
        большинство игр, которые отличаются жанром и спецификой процесса игры.
      </p>
      <p>
        <b>1. Лотерея </b>
      </p>
      <p>
        Лотерейный билет правильно считать одним из видов ценных бумаг. Есть
        спорное мнение касательно места возникновения лотерей: или это Древний
        Рим, или Древний Китай. Также существует предположение, что Великая
        Китайская стена построена за деньги, вырученные от лотереи типа
        современного кено, ориентировочно в 100 году до н.э. во времена
        правления императорской династии Хань.
      </p>
      <p>
        Первая американская лотерея прошла во втором десятилетии 17-го века. А
        вот числовая лотерея состоялась уже в 1726 году в Нидерландах. Что
        касается России, то первые розыгрыши датируются концом 17-го - началом
        18-го века. А вот в 1782 году они получили статус государственной и
        проводились до начала 20-го века часто в помощь пострадавшим от неурожая
        и от войны. Это были денежно-вещевые лотереи, нужно было просто купить
        билет и ждать выигрыша. А вот лотерея спортлото подразумевала уже
        активное участие игроков: нужно было выбрать комбинацию чисел на свое
        усмотрение, а с начала 1974 года появилась возможность еще и наблюдать
        за вращающимся барабаном по телевидению. Таким образом появлялся эффект
        того, что от игрока что-то зависит. В 80-х годах розыгрыши проводились
        уже на двух лототронах.
      </p>
      <p>
        В 90-х годах прошлого века технические возможности организаторов лотерей
        существенно выросли благодаря развитию электронных технологий во всем
        мире, в том числе и в России: данные передавались в точку сбора прямо из
        киосков. Кстати сказать, у частных лотерейных операторов возможностей
        было намного больше, поэтому государственное спортлото до конца столетия
        не просуществовало - не выдержало конкуренцию. Современная модификация
        традиционного спортлото - лотерея бинго.
      </p>
      <p>
        Сегодня во многих странах мира лотерейные билеты можно покупать в
        лотерейных терминалах и автоматах, которые часто совмещены с платежными.
        Это значит, что нет необходимости содержать киоски с персоналом,
        обрабатывать данные вручную: терминалы серьезно снижают себестоимость
        проекта и операционные расходы. Кроме того, они не занимают много места
        и могут быть установлены практически везде.
      </p>
      <p>
        <b>2. Слоты </b>
      </p>
      <p>
        Основной принцип слотов остался неизменным: нужно собрать из случайных
        цифр, знаков или символов правильную комбинацию. А вот способы
        реализации такой игры существенно изменились.
      </p>
      <p>
        В 1891 году в Нью-Йорке была презентована пятибарабанная машина от
        Sittman & Pitt. Именно с легкой руки первых игроков, скорее всего, и
        появилось название этого вида игры. На английском slot значит щель,
        прорезь: нужно было опустить монетку в монетоприемник и рычагом
        запустить вращение барабанов. Через четыре года, опять же в Соединенных
        Штатах, правда, уже в Сан-Франциско, Liberty Bell презентовал
        трехбарабанную машину с автоматической выдачей выигрышей. Именно ее
        начали называть одноруким бандитом. Они были весьма популярны, поэтому
        создатели начали работать над усовершенствованием, но это касалось
        больше дизайна, чем технологии.
      </p>
      <p>
        В начале 20-го века Новый Свет продолжил двигаться в направлении
        совершенствования игровых машин. Как ни странно, этому способствовали
        ограничивающие законы: появились автоматы "фрукты". В качестве выигрыша
        можно было получить сладости, жевательную резинку, напитки. Технология
        использовалась позже для торговых автоматов, а принцип фруктовых слотов
        дожил до сегодняшнего дня.
      </p>
      <p>
        Начало великой американской депрессии стало периодом взрыва в индустрии
        развлечений: большое количество привычных символов США появились именно
        в этот период - Микки Маус, кукла Ширли Темпл, игра "Мафия", комиксы и
        танцы спасали тысячи людей от морального упадка. Индустрия процветала.
        Именно тогда в Чикаго и появился электроприводной пятикарточный покер.
        Только после окончания кризиса правительство начало принимать меры по
        борьбе с азартными развлечениями. Почти 40 лет в слотах не было новинок,
        вплоть до 1976 года, когда появился автомат с логическим платами для
        казино. Установлен он был в Лас-Вегасе.
      </p>
      <p>
        Новую же эру этого вида автоматов - видеослотов - начало оборудование
        Three Bag Full в Австралии. Годом позже появился первый онлайн-слот и
        виртуальное казино Microgaming. Следующим этапом стали мобильные слоты,
        а с 2010 года начали появляться 3D-слоты.
      </p>

      <p>
        В целом же слоты можно разделить по: <br />
        - конструкции
        <br />
        - связи с другими автоматами <br />
        - количеству барабанов <br />
        - числу линий <br />- системам выигрышей{' '}
      </p>

      <p>
        <b>3. Блэкджек</b>
      </p>
      <p>
        Термин означает "черный валет", то есть в любом случае речь идет о
        карточной игре. Хотя такие игры насчитывают уже тысячу лет истории,
        блекджек достаточно молодой. Первая его версия появилась около трех
        сотен лет назад во Франции под названием Vingt et Un, то есть 21. С этим
        названием игра переехала за океан, где с несколько измененными правилами
        получила популярность как блекджек. До 90-х годов 20-го века это была
        карточная игра в игорных домах, однако при первой же возможности стала
        неотъемлемой и недорогой частью онлайн-казино.
      </p>
      <p>
        <b> 4. Покер </b>
      </p>
      <p>
        Родиной покера считают Европу, хотя ни одна страна не может претендовать
        на первенство однозначно. Исторические сведения описывают игру как
        популярное развлечение 16-го века во Франции, Германии и Англии. Нет
        однозначного мнения и касательно происхождения названия. Как и многие
        другие развлечения, в своем первом виде покер попал на просторы Северной
        Америки, стал национальной американской игрой и уже в модифицированном
        виде вернулся в Европу. В самих же Соединенных Штатах соревнования 1968
        года вошли в историю как первые, а уже через 2 года состоялся мировой
        чемпионат. Научно-технический прогресс не прошел мимо и этого
        популярного развлечения: покер в Интернете привлекает игроков тем, что
        можно соревноваться с любым противником из любой точки мира.
      </p>
      <p>
        <b>5. Рулетка </b>
      </p>
      <p>
        Слово настолько плотно вошло в русский язык, что уже даже почти забыто
        его происхождение, а оно однозначно французское: roulette переводится на
        русский как "колесико". У возникновения игры есть несколько версий, в
        том числе и как альтернативы карточным играм. Нечто похожее было у
        многих народов практически всех частей света. В конце 19-го века вместе
        с французскими эмигрантами рулетка попала в США и быстро стала
        популярной. Хотя изначально нечто подобное позаимствовал викинг Эриксон
        тысячу лет назад во время своей экспедиции к берегам Лабрадора и
        Гренландии: в путевых заметках описывалась игра, которая заключалась в
        том, что выигравший забирает деньги, если в его направлении
        останавливается раскрученная на оси палочка.
      </p>
      <p>
        С течением времени правила менялись, но в конце концов сформировалось
        три вида игры: американская, европейская, французская. Классическая
        европейская рулетка - наиболее популярный вариант в игорных зонах
        России. То, что называется русской рулеткой, относится не к правилам
        игры, а к экстремальной составляющей.
      </p>
      <p>
        Вместе с другими азартными играми рулетка стала частью меню современных
        онлайн-казино. Для опытных игроков в последние годы стало интересно
        играть в рулетку live, то есть иметь дело не с генератором случайных
        чисел, а с реальным онлайн-крупье, доверие к которому традиционно выше.
      </p>
      <p>
        <b>6. Колесо Фортуны </b>
      </p>
      <p>
        Одна из наименее исследованных игр. Есть версия, что с помощью
        перевернутого колеса телеги распределяли добычу после набегов некоторые
        племена. Такой способ распределения трофеев считался очень справедливым
        и даже веселым. Вариантов игры по всему миру бесчисленное множество. С
        развитием телекоммуникационных технологий колесо фортуны вошло в дома
        жителей разных стран. К примеру, одноименное американское телешоу Мерва
        Гриффина транслируется начиная с середины 70-х годов прошлого века. А
        вот в 1990 году начался показ телевизионного капитал-шоу "Поле Чудес",
        которое популярно в России до сих пор. В онлайн-казино "Колесо
        Фортуны"часто рассматривают как разновидность рулетки. Вращать колесо
        может как дилер, так и автомат.
      </p>
      <p>
        Если резюмировать всю многотысячелетнюю историю развития отдельных игр и
        индустрии гемблинга в целом, то можно выделить несколько периродов:
      </p>
      <p>
        - зарождение
        <br /> - создание первых казино и букмекерских контор
        <br /> - внедрение электромеханических инженерных изобретений
        <br /> - взаимодействие с телекоммуникациями
        <br /> - появление онлайн-платформ
        <br /> - развитие мобильного гемблинга
      </p>
      <p>
        Каким будет следующий этап развития индустрии? Это то, за чем можно
        будет наблюдать игорной сферы уже сегодня.
      </p>

      <Box
        style={{width: '100%', marginTop: '2em'}}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <button
          className="test-btn"
          onClick={() => {
            setTabSelected(1);
          }}
        >
          Перейти к тестированию
        </button>
      </Box>
    </Box>
  );
};

export default HistoryOfGambling;
