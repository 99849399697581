import {createSlice} from '@reduxjs/toolkit';
import {games} from 'services';

export const slice = createSlice({
  name: 'games',
  initialState: {
    items: [],
    loading: false,
    error: null,
    selectedGames: '',
  },
  reducers: {
    getGamesIsLoading: (state) => {
      state.loading = true;
    },
    getGamesSuccess: (state, {payload}) => {
      state.items = payload;
      state.loading = false;
      state.error = null;
    },
    getGamesFailure: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
    setSelectedGames: (state, {payload}) => {
      state.selectedGames = payload;
    },
  },
});


export function getGames() {
  return async (dispatch) => {
    dispatch(getGamesIsLoading());
    try {
      const response = await games.fetchGames();
      dispatch(getGamesSuccess(response.data));
    } catch (error) {
      dispatch(getGamesFailure(error.message));
    }
  };
}

export const {
  getGamesIsLoading,
  getGamesSuccess,
  getGamesFailure,
  filter,
  setSelectedGames,
} = slice.actions;

export const gamesSelector = (state) => state.games;

export default slice.reducer;
