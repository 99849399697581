import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import { theme } from 'theme/theme';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
    height: 45,
    border: `1px solid ${theme.colors.mainBlue}`,
    color: theme.colors.white,
    fontSize: theme.fontSizes.text,
    lineHeight: theme.lineHeights.text,
    fontFamily: 'Roboto',
    textTransform: 'inherit',
    borderRadius: 0,
    margin: '0 auto',
    background: 'rgba(14, 154, 252, 0.5)',
    '&.MuiButton-contained.Mui-disabled': {
      color: 'rgba(255,255,255, 0.7)',
    },
    '&:hover': {
      boxShadow: '0px 0px 10px 0px rgba(14, 154, 252, 0.4)',
      background: 'rgba(14, 154, 252, 0.5)',
    },
    ':disabled': {
      color: 'white',
    },
  },
}));

const LoginButton = ({ text, type, disabled, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.button}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default LoginButton;
