import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const MobileGenresBlockContainer = styled.div`
  width: 140px;
  /* height: 369px; */
  background: #031238;
  box-shadow: ${(props) => props.theme.blockShadow};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  flex-direction: column;
  padding: 12px 0;
  z-index: 999;
  top: 31px;
  left: 39%;

  ${media.phone`
    left:unset;
  `}

  @media (min-width: 768px) {
    display: none;
  }
`;

const MObileGenre = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.white};
`;

const SelectGenre = styled.div`
  margin: 0 auto;
  display: none;
  align-items: center;
  position: relative;

  .selectgenreicon {
    margin-top: 6px;
  }

  ${media.phoneLandscape`
      display: flex;
  		margin-bottom: 10px;
  `}
`;

const Genre = styled.span`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  color: ${(props) =>
    props.active ? props.theme.colors.main : props.theme.colors.white};
`;

const SelectGenreText = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.white};
  margin-right: 9px;
`;

const GamesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 20px;

  ${media.phone`
    margin: 0;
  `}
`;

export {
  MObileGenre,
  MobileGenresBlockContainer,
  SelectGenre,
  Genre,
  GamesWrapper,
  SelectGenreText,
};
