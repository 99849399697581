import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const RecordsBlockContainer = styled.aside`
  min-width: 284px;
  display: flex;
  box-shadow: ${(props) => props.theme.blockShadow};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 0;
  background: #000a24;

  ${media.tabletLandscape`
    min-width: 237px;
  `}
  ${media.tablet`
    min-width: 173px;
  `}
  ${media.phoneLandscape`
    display: none;
  `}
`;

export {RecordsBlockContainer};
