import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const GameGenresBlockContainer = styled.div`
  padding: 20px 30px 25px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.colors.darkBlue};
  box-shadow: 0px 0px 13px 2px rgba(14, 154, 252, 0.4);
  min-width: 284px;

  ${media.tabletLandscape`
    min-width: 237px;
  `}
  ${media.tablet`
    min-width: 169px;
    padding: 10px 5px 15px 5px;
  `}

  ${media.phoneLandscape`
    display: none;
  `}
`;

export {GameGenresBlockContainer};
