import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {
  PollContainer,
  PollText,
  PollQuestionsContainer,
  PollQuestion,
  PollQuestionButton,
  PollQuestionText,
  PollQuestionInner,
} from './styles';

import ShadowText from 'components/Common/ShadowText';
import Button from 'components/Common/Button';
import {pollsSelector, getPollDone, getPolls} from 'redux/pollsReducer';

const Poll = () => {
  const dispatch = useDispatch();
  const {
    items: polls,
    isPollDone: {done},
  } = useSelector(pollsSelector);

  React.useEffect(() => {
    dispatch(getPolls());
  }, [dispatch]);

  const [selectedOption, setSelectedOption] = React.useState(0);

  const selectOption = (option) => setSelectedOption(option);

  const answerPollQuestion = (answerId) =>
    dispatch(getPollDone(polls[0].pollOptions[selectedOption].id));

  return (
    <>
      {polls && (
        <PollContainer>
          <ShadowText text={polls[0].title} alignSelf="center" />
          <PollText>{done ? 'Спасибо' : polls[0].content}</PollText>
          {!done && (
            <PollQuestionsContainer>
              {polls[0].pollOptions.map((question, index) => (
                <PollQuestion
                  key={question.id}
                  onClick={() => selectOption(index)}
                >
                  <PollQuestionButton active={index === selectedOption}>
                    {index === selectedOption && <PollQuestionInner />}
                  </PollQuestionButton>
                  <PollQuestionText active={index === selectedOption}>
                    {question.content}
                  </PollQuestionText>
                </PollQuestion>
              ))}
            </PollQuestionsContainer>
          )}
          <Button
            text="Ответить"
            width="134px"
            height="35px"
            onClick={answerPollQuestion}
            className="answerbutton"
            disabled={done}
          />
        </PollContainer>
      )}
    </>
  );
};

export default Poll;
