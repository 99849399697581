import styled from 'styled-components';

export const MainContentWrapper = styled.main`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  margin: ${(props) => props.margin};
  height: 100%;
`;
