import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {getGenres, genresSelector} from 'redux/genresReducer';

import {MobileGenresBlockContainer, Genre} from './styles';
import {getGames, setSelectedGames} from 'redux/gamesReducer';

const ALL_GENRES_INDEX = -1;

const MObileGenresBlock = ({hideBlock}) => {
  const dispatch = useDispatch();
  const {items: genres, loading} = useSelector(genresSelector);

  const [selectedGenre, setSelectedGenre] = React.useState(ALL_GENRES_INDEX);

  React.useEffect(() => {
    dispatch(getGenres());
  }, [dispatch]);

  const setToAllGenres = () => {
    setSelectedGenre(ALL_GENRES_INDEX);
    dispatch(getGames());
    hideBlock();
  };

  const selectGenre = (index, genre) => {
    setSelectedGenre(index);
    dispatch(setSelectedGames(genre));
    hideBlock();
  };

  return (
    <MobileGenresBlockContainer>
      {!loading &&
        genres.length > 0 &&
        genres.map((genre, index) => (
          <Genre
            key={genre.id}
            onClick={() => selectGenre(index, genre.title)}
            active={index === selectedGenre}
          >
            {genre.title}
          </Genre>
        ))}
      {!loading && (
        <Genre
          onClick={() => setToAllGenres()}
          active={ALL_GENRES_INDEX === selectedGenre}
        >
          Все
        </Genre>
      )}
    </MobileGenresBlockContainer>
  );
};

export default MObileGenresBlock;
