import React from 'react'
import logo from '../../../assets/images/LOGO1.svg'
import { Wrapper, Logo } from './styled'

const MainPreloader = () => {
    return (
        <Wrapper>
            <Logo src={logo} alt='' />
        </Wrapper>
    )
}

export default MainPreloader