import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { splitSlidesData } from 'utils/helpers';

import { MainContentContainer, GameContainer, GameImg } from './styles';

import { authSelector } from 'redux/authReducer';
import {
  mainContentSelector,
  onChangeSlide,
  setSelectorProvider,
  setSliderCount,
} from 'redux/mainContentReducer';
import arrowLeft from 'assets/images/arrowup.png';
import arrowRight from 'assets/images/arrowdown.png';

import select from 'assets/sounds/Select.wav';
import enter from 'assets/sounds/Enter.wav';
import { soundPlayer } from 'utils/soundPlayer';
import { gamePackageSelector } from 'redux/gamePackageReducer';
import Learners from 'components/Learners';
import Account from 'components/Account';

const providers = [1, 2, 3, 4, 5, 6, 1, 8, 8, 10, 8, 8, 8, 8, 14, 16, 8, 21, 22, 8, 1];
providers[30] = 1;
providers[31] = 4;

const MainContent = ({
  isRulesShown,
  isOfertaShown,
  isPollShown,
  setSelectedGames,
  selectedGames,
  isChangeHotkeyShown,
  isAccountShown,
  isTeoryShown,
  hideAccount,
  showTeory,
  hideTeory,
  isRecordsShown
}) => {
  const dispatch = useDispatch();

  const {
    authInfo: { info },
  } = useSelector(authSelector);
  const { items: packages } = useSelector(gamePackageSelector);

  const isSocialPoll = info && info.is_social_poll;

  const { sliderCount, selectedProvider } = useSelector(mainContentSelector);

  const sliderElementsSum = 8;
  const games =
    packages &&
    selectedProvider &&
    packages.find((item) => item.package.name === selectedProvider).games;
  const allGames = packages ? (selectedProvider ? games : packages) : [];
  const gamesList = allGames
    ? splitSlidesData(allGames, sliderElementsSum)
    : [];

  const [sliderPosition, setSliderPosotion] = useState({});

  const selectedLine =
    (sliderCount * -1 * sliderElementsSum) / 4 + (selectedGames === 4 ? 1 : 0);
  const selectedLinksCount = 4;
  const gamesLineSumRemains = allGames.length % 4 ? 1 : 0;
  const gamesLineSum = Math.floor(allGames.length / 4) + gamesLineSumRemains;
  // eslint-disable-next-line
  const gamesLinks = (array) => {
    const startArray = selectedLine * selectedLinksCount;
    const endArray = selectedLine * selectedLinksCount + selectedLinksCount;

    return array.slice(startArray, endArray);
  };

  useEffect(() => {
    setSliderPosotion({
      transform: `translateX(${100 * -sliderCount}%)`,
    });
    // eslint-disable-next-line
  }, [sliderCount]);

  const isTeoryComplete =
    !!info?.is_school &&
    info?.school !== null &&
    !!info?.school?.filter((i) => i.status === 3).length;

  useEffect(() => {
    if (info?.is_school && info?.school !== null) {
      !isTeoryComplete && showTeory();
    }
    // eslint-disable-next-line
  }, [isTeoryComplete]);

  const hotkeys = info && info.hotkeys;
  const handleKeyPress = (e) => {
    if (
      !isRulesShown &&
      !isOfertaShown &&
      !isPollShown &&
      !isRecordsShown &&
      !isChangeHotkeyShown
    ) {
      const { code } = e;
      const {
        previous_game = [],
        next_game = [],
        select_first_game = [],
        select_second_game = [],
        select_third_game = [],
        select_fourth_game = [],
        previous_provider = [],
      } = hotkeys;
      //select provider
      previous_provider.forEach((item) => {
        if (code === item) {
          if (selectedProvider) {
            dispatch(setSelectorProvider(''));
            dispatch(setSliderCount(0));
          }
        }
      });
      //arrow UP
      previous_game.forEach((item) => {
        if (code === item) {
          soundPlayer(select).play();
          console.log(selectedGames);
          if (selectedGames >= 4) {
            setSelectedGames(selectedGames - 4);
          } else {
            selectedProvider && dispatch(onChangeSlide(-1, gamesList, sliderCount));
            setSelectedGames(selectedGames + 4);
          }
        }
      });
      //arrow DOWN
      // next_game.forEach((item) => {
      if (code === next_game[0]) {
        soundPlayer(select).play();
        console.log(selectedGames, gamesList[sliderCount].length);
        if (selectedGames <= 0) {
          selectedLine <= gamesLineSum - 1 && setSelectedGames(selectedGames + 4);
        } else {
          selectedProvider && dispatch(onChangeSlide(1, gamesList, sliderCount));
          setSelectedGames(selectedGames - 4);
        }
      }
      // });
      //document.location.href = gamesList[sliderCount][num].link + `?token=${localStorage.getItem('token')}`;
      const redirectOnGame = num => document.location.href = gamesList[sliderCount][num].link + `?token=${localStorage.getItem('token')}`;
      const disabledKey = num => gamesList[sliderCount].slice(selectedGames, selectedGames + selectedLinksCount).length >= num;
      [select_first_game, select_second_game, select_third_game, select_fourth_game].forEach((hotkeys, idx) => {
        hotkeys.forEach(hotkey => {
          if (code === hotkey && disabledKey(idx + 1)) {
            soundPlayer(enter).play();
            selectedProvider ? redirectOnGame(selectedGames + idx) : dispatch(setSelectorProvider(gamesList[sliderCount][selectedGames + idx].package.name));
          }
        });
      });
      /*//btn 1
      select_first_game.forEach((item) => {
        if (code === item && disabledKey(1)) {
          soundPlayer(enter).play();
          selectedProvider ? redirectOnGame(0 + selectedGames) : dispatch(setSelectorProvider(gamesList[sliderCount][selectedGames].package.name));
        }
      });
      //btn 2
      select_second_game.forEach((item) => {
        if (code === item && disabledKey(2)) {
          soundPlayer(enter).play();
          selectedProvider ? redirectOnGame(1 + selectedGames) : dispatch(setSelectorProvider(gamesList[sliderCount][selectedGames + 1].package.name));
        }
      });
      //btn 3
      select_third_game.forEach((item) => {
        if (code === item && disabledKey(3)) {
          soundPlayer(enter).play();
          selectedProvider ? redirectOnGame(2 + selectedGames) : dispatch(setSelectorProvider(gamesList[sliderCount][selectedGames + 2].package.name));
        }
      });
      //btn 4
      select_fourth_game.forEach((item) => {
        if (code === item && disabledKey(4)) {
          soundPlayer(enter).play();
          selectedProvider ? redirectOnGame(3 + selectedGames) : dispatch(setSelectorProvider(gamesList[sliderCount][selectedGames + 3].package.name));
        }
      });*/
    }
  };

  useEffect(() => {
    if (hotkeys) {
      document.addEventListener('keydown', handleKeyPress);

      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
    // eslint-disable-next-line
  }, [
    selectedGames,
    gamesLinks,
    sliderCount,
    selectedLine,
    hotkeys,
    isRulesShown,
    isOfertaShown,
    isPollShown,
    isChangeHotkeyShown,
  ]);

  useEffect(() => {
    const localPackage = packages?.find(
      (item) => item.package.name === localStorage.getItem('selected_provider')
    );
    !!localPackage && dispatch(setSelectorProvider(localPackage?.package.name));
    // eslint-disable-next-line
  }, [dispatch, packages]);

  useEffect(() => {
    const localPackage = packages?.find(
      (item) => item.package.name === localStorage.getItem('selected_provider')
    );
    const remainder =
      localPackage && !!(localPackage.games.length % sliderElementsSum) ? 1 : 0;
    const sliderPagesCount =
      localPackage &&
      Math.floor(localPackage.games.length / sliderElementsSum) + remainder;
    if (sliderPagesCount >= Number(localStorage.getItem('slider_page'))) {
      dispatch(setSliderCount(Number(localStorage.getItem('slider_page'))));
    }
    // eslint-disable-next-line
  }, [packages]);

  const renderSlide = (slideData) => {
    return slideData.map((game, id) => {
      let selectedLine = id >= selectedGames && id <= selectedGames + 3;
      return <div key={uuidv4()} className={`game ${selectedLine ? 'selected-game' : ''}`}
          onClick={() => {
            if(game.status) {
              soundPlayer(enter).play();
              document.location.href = `${game.link}?token=${localStorage.getItem('token')}`;
            }
          }}>
          <GameContainer>
            <GameImg bg={game?.image}>
              {selectedLine ? <div className={'selected-label'}><span>Играть</span></div>: ''}
            </GameImg>
          </GameContainer>
        </div>
    });
  };
  const renderMenu = (data) => {
    return data.map((provider, id) => {
      let selectedLine = id >= selectedGames && id <= selectedGames + 3;
      return (
        <div
          key={uuidv4()}
          className={`game ${selectedLine ? 'selected-provider' : ''}`}
          onClick={() => {
            soundPlayer(enter).play();
            localStorage.setItem('selected_provider', provider.package.name);
            dispatch(setSelectorProvider(provider.package.name));
          }}
        >
          <div className="game-provider">
            {selectedLine ? (
              <div className={'selected-label'}>
                <span>Выбор</span>
              </div>
            ) : (
              ''
            )}
            <img src={require(`../../../../../assets/images/providers/${providers[provider.package.id - 1] || 0}.png`)} alt={provider.package.name} />
          </div>
        </div>
      );
    });
  };

  const prevSlide = !!isSocialPoll && (
    <div
      className="game-slider-button-left"
      onClick={() => dispatch(onChangeSlide(-1, gamesList, sliderCount))}
    >
      <img className="game-slider-button-l" src={arrowLeft} alt="arrowLeft" />
    </div>
  );

  const nextSlide = !!isSocialPoll && (
    <div
      className="game-slider-button-right"
      onClick={() => {
        selectedGames === 4 && setSelectedGames(selectedGames - 4);
        dispatch(onChangeSlide(1, gamesList, sliderCount));
      }}
    >
      <img className="game-slider-button-r" src={arrowRight} alt="arrowRight" />
    </div>
  );

  const rootRender = () => {
    return selectedProvider
      ? gamesList.map((games) => (
        <div className="slide" key={uuidv4()}>
          <div className="slide-wrapper">{renderSlide(games)}</div>
        </div>
      ))
      : gamesList.map((games) => (
        <div className="slide" key={uuidv4()}>
          <div className="slide-wrapper">{renderMenu(games)}</div>
        </div>
      ));
  };

  return (
    <MainContentContainer>
      {!isAccountShown && !isTeoryShown && (
        <div className="games-list">
          {prevSlide}
          <div className="game-slides-container" style={sliderPosition}>
            {packages && rootRender()}
          </div>
          {nextSlide}
        </div>
      )}
      {isTeoryShown && (
        <Learners hideTeory={hideTeory} isTeoryComplete={isTeoryComplete} />
      )}
      {isAccountShown && (
        <Account hideAccount={hideAccount} isTeoryComplete={isTeoryComplete} />
      )}
    </MainContentContainer>
  );
};

export default MainContent;
