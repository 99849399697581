import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FormHelperText, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import LoginButton from '../LoginButton';
import { theme } from 'theme/theme';
import { useForm } from 'hooks/useForm';
import { signUp, authSelector } from 'redux/authReducer';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 77,
    width: '285px',
    marginLeft: 'auto',
    marginRight: 'auto',

    '@media (max-width: 670px)': {
      marginTop: 10,
      marginBottom: 20,
    },
    '@media (max-width: 616px)': {
      marginTop: 77,
      marginBottom: 20,
    },
  },
  input: {
    marginBottom: '30px',
    width: '100%',
    height: '45px',
    zIndex: 99,

    '& input': {
      color: 'white',
      caretColor: theme.colors.mainBlue,
    },
    '& legend': {
      width: 0,
    },

    '& p': {
      position: 'absolute',
      top: '-23px',
    },

    '& label': {
      color: '#81889B',
      fontSize: '16px',
      lineHeight: '19px',
    },
    '& label.Mui-focused': {
      fontSize: '10px',
      lineHeight: '12px',
      color: '#81889B',
    },

    '& .MuiFormLabel-root': {
      transform: 'translate(14px,13px)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(12px,5px)',
      background: 'transparent',
      fontSize: '10px',
      lineHeight: '12px',
      color: '#81889B',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: `1px solid ${theme.colors.mainBlue}`,
        height: '100%',
        borderRadius: 0,
        color: theme.colors.white,
      },
      '&:hover fieldset': {
        border: `2px solid ${theme.colors.mainBlue}`,
      },
      '&.Mui-focused fieldset': {
        border: `2px solid ${theme.colors.mainBlue}`,
        zIndex: 2,
      },
    },
  },
}));

const LoginForm = ({ handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, error, status } = useSelector(authSelector);
  const login = React.createRef();

  const { form, handleInputChange } = useForm({
    login: '',
    password: '',
  });

  React.useEffect(() => {
    status === 'ok' && handleClose()
  }, [status, handleClose])

  return (
    <form className={classes.form}>
      <TextField
        id="outlined-basic"
        className={`${classes.input} safariinput`}
        variant="outlined"
        label="Логин"
        value={form.login}
        onChange={handleInputChange}
        name="login"
        ref={login}
        autoFocus
      />
      <TextField
        id="outlined-basic"
        className={classes.input}
        variant="outlined"
        label="Пароль"
        type="password"
        value={form.password}
        onChange={handleInputChange}
        name="password"
      />
      <FormHelperText style={{ color: '#f44336' }}>{error && 'Неверныe данные для ввода, попробуйте снова'}</FormHelperText>
      <LoginButton
        onClick={() => dispatch(signUp({ login: form.login, password: form.password }))}
        text={!loading ? "Войти" : <CircularProgress color="primary" />}
        type="button"
        disabled={form.password.length === 0 || form.login.length === 0}
      />
    </form>
  );
};

export default LoginForm;
