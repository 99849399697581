import {API} from 'settings';

const fetchNews = async () => {
  const URI = `${API}/v1/news?expand=created_by,content,tags,created_at`;
  const query = {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const fetchPoliticalNews = async () => {
  const URI = `${API}/v1/political-news?sort=-id&per-page=1&page=1`;
  const query = {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const fetchSingleNewsBlock = async (newsId) => {
  const URI = `${API}/v1/news/${newsId}?expand=created_by,created_at,updated_at,content,tags`;
  const query = {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const fetchNextOrPrev = async (pageNumber) => {
  const URI = `${API}/v1/news?expand=created_by,created_at,updated_at,content,tags&per-page=1&page=${pageNumber}`;
  const query = {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

export default {
  fetchNews,
  fetchPoliticalNews,
  fetchSingleNewsBlock,
  fetchNextOrPrev,
};
