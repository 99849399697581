import styled from 'styled-components';

export const LessonsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51vh;
  .carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LessonPreview = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  color: #4a2952;
  height: 19em;
  width: 14em;
  margin: 1em;

  &:hover {
    box-shadow: 0px 0px 9px 2px rgb(192 193 43 / 47%);
  }

  .number {
    position: absolute;
    top: 4.3em;
    right: 0;
    background: #303030;
    width: 7em;
    height: 4em;
    clip-path: polygon(100% 0%, 100% 100%, 0% 50%, 100% 0%);
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: #fff;
      margin-left: 2em;
      font-size: 1em;
      font-weight: 700;
    }
  }

  .title {
    position: relative;
    height: 8em;
    width: 100%;
    /* background: black; */
    clip-path: polygon(100% 0%, 100% 51%, 0% 100%, 0% 0%);
    margin-bottom: -0.5em;
    object-fit: cover;
    ${(props) =>
      !!props.bg && `background: url(${props.bg}) no-repeat center / cover`}
  }

  .description {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    span {
      font-size: 0.85em;
      margin-top: 0.5em;
    }
    b {
      font-size: 1em;
      font-weight: 500;
      font-style: italic;
    }
  }
`;

export const ArrowLeft = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0;
  color: #000;
`;

export const ArrowRight = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  color: #000;
`;
