import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  color: #e1b058;
  margin-top: 2px;

  @media (max-width: 1024px) {
    border: 3px solid #e1b058;
    border-radius: 10px;
    padding: 5%;
    background: #25132e;
  }
`;

const AcceptConditionsText = styled.p`
  font-family: 'Candara', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  margin-top: 5px;
  @media (max-width: 900px) {
    font-size: 14px;
    flex-wrap: wrap;
  }
`;

const ConditionsLink = styled.a`
  color: white;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
`;

export {Container, AcceptConditionsText, ConditionsLink};
