import {createSlice} from '@reduxjs/toolkit';
import {setDenomination} from 'services/denomination';
import {setInfoDenom} from '../redux/authReducer';

export const slice = createSlice({
  name: 'denomination',
  initialState: {
    isChanged: false,
    loading: false,
    error: null,
  },
  reducers: {
    changeDenomIsLoading: (state) => {
      state.loading = true;
    },
    changeDenomSuccess: (state) => {
      state.isChanged = true;
      state.loading = false;
      state.error = null;
    },
    changeDenomFailure: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export function changeDenomination(data) {
  return async (dispatch) => {
    dispatch(changeDenomIsLoading());

    setDenomination(data).then((res) => {
      if (res.data.code === 200) {
        dispatch(changeDenomSuccess());
        dispatch(setInfoDenom(res.data.data));
      } else if (res.data.code !== 200) {
        dispatch(changeDenomFailure(res.data.data));
      }
    });
  };
}

export const {
  changeDenomIsLoading,
  changeDenomSuccess,
  changeDenomFailure,
  filterByPoints,
} = slice.actions;

export const recordsSelector = (state) => state.records;

export default slice.reducer;
