import React from 'react';

import {StyledGenreButton} from './styles';

const GenreButton = ({genre: {title}, active, ...props}) => {
  return (
    <StyledGenreButton active={active} {...props}>
      <span>{title}</span>
    </StyledGenreButton>
  );
};

export default GenreButton;
