import React from 'react';
import { useSelector } from 'react-redux';

import silver from 'assets/images/2.png';
import bronze from 'assets/images/1.png';
import gold from 'assets/images/3.png';
import logo from 'assets/images/logo.png';
import logo2 from 'assets/images/logo2.png';
import jackpot from 'assets/images/jackpot.png';
import points from 'assets/images/points.png';
import frame from 'assets/images/modal.png';

import {
  MainHeader,
  Img,
  Jackpot,
  JackpotText,
  RulesText,
  OfertaText,
  Logo,
  DiamondJackpot,
  DiamondText,
  PointsText,
  Points,
  PointsDigits,
} from './styles';

import { authSelector } from 'redux/authReducer';

import Modal from 'components/AuthGames/Modal';
import Rules from '../../Rules';
import Oferta from '../../Oferta';

const JACKPOTS = [
  { id: 1, name: 'bronze', img: bronze, color: '#cd7f32' },
  { id: 2, name: 'silver', img: silver, color: '#c0c0c0' },
  { id: 3, name: 'gold', img: gold, color: '#ffc200' },
  { id: 4, name: 'diamond', img: jackpot, color: '#00c2ff' },
];

const tranformJackpots = (jackpots) => {
  let jackpotsArr = [];
  for (let jackpot in jackpots) {
    jackpotsArr = [...jackpotsArr, { name: jackpot, bank: jackpots[jackpot] }];
  }
  return jackpotsArr;
};

// const diamondJackpot = (arr) => {
//   if (arr) {
//     const diamond = arr.find((item) => item.name === 'diamond');
//     return diamond;
//   }
// };

const filterOutDiamond = (arr) => {
  if (arr) {
    return arr.filter((item) => item.name !== 'diamond');
  }
};

const MainHeaderContainer = ({
  isRulesShown,
  hideRules,
  show_logo,
  showOferta,
  isOfertaShown,
  hideOferta,
  showRules,
  isSocialPoll,
  showAccount,
  hideAccount,
  showTeory,
  hideTeory,
}) => {
  const {
    authInfo: { info }
  } = useSelector(authSelector);
  const lang = info.lang.toLowerCase();
  const balance = info && info.balance;
  const denom = info && info.denomination;
  const isTeoryComplete = !!info?.is_school && info?.school !== null && !!info?.school?.filter((i) => i.status === 3).length;
  const pointsText = lang === 'tgk' ? 'ХолҲо' : 'Очки';
  const offerText = lang === 'tgk' ? 'Пешниҳод' : 'Оферта';
  const jackDenom = lang === 'tgk' ? info.denomination : 100;
  return (
    <>
      {!!isRulesShown && <Modal bg={frame}>
        <Rules hideRules={hideRules} />
      </Modal>}
      {!!isOfertaShown && <Modal bg={frame}>
        <Oferta hideOferta={hideOferta} lang={info.lang} />
      </Modal>}
      {!!info && (
        <MainHeader isSocialPoll={isSocialPoll}>
          <div className="main-header__part left-part">
            {filterOutDiamond(JACKPOTS).map((item) => {
              const bank = info && tranformJackpots(info && info.jackpots).find((j) => j.name === item.name) ? Math.floor(tranformJackpots(info && info.jackpots).find((j) => j.name === item.name).bank / jackDenom) : '-';
              return item.name !== 'diamond' && <Jackpot key={item.id} background={item.color}>
                <Img src={item.img} alt="jackpot" status />
                <JackpotText>
                  Jackpot {item.name}
                  {''}
                  <div className="jackpot-bank">{bank}</div>
                </JackpotText>
              </Jackpot>;
            })}
          </div>
          <div className="main-header__center-part">
            {!!isSocialPoll && <RulesText onClick={info?.is_school && info?.school !== null ? () => { if (!!isTeoryComplete) { showAccount(); hideTeory(); } } : showRules}>
              {info?.is_school && info?.school !== null ? 'Aккаунт' : 'Правила'}
            </RulesText>}
            <Logo isSocialPoll={isSocialPoll}>
              {!!show_logo ? <img src={logo} alt="logo" /> : <img src={logo2} alt="logo" style={{ width: '85%', marginLeft: '5%' }} />}
            </Logo>
            {(!!isSocialPoll || lang === 'tgk') && <OfertaText onClick={info?.is_school && info?.school !== null ? () => { if (!!isTeoryComplete) { showTeory(); hideAccount(); } } : showOferta} >
              {info?.is_school && info?.school !== null ? 'Теория' : offerText}
            </OfertaText>}
          </div>
          <div className="main-header__part right-part">
            <DiamondJackpot>
              <img src={jackpot} alt="jackpot" />
              <DiamondText>
                Jackpot
                <span className="block">{'Diamond'}</span>
                <div className="jackpot-bank">
                  {info && info.jackpots['diamond'] ? Math.floor(info && info.jackpots['diamond'] / jackDenom) : '-'}
                </div>
              </DiamondText>
            </DiamondJackpot>
            <PointsText>{pointsText}:</PointsText>

            <Points>
              <img src={points} alt="points" />
              <PointsDigits>
                {Math.floor(balance / 100 / (denom / 100))}{' '}
              </PointsDigits>
            </Points>
            {window.innerWidth > 1280 && <h3 style={{ color: '#5a3f1c', margin: '1.5vh', fontSize: '100%', fontStyle: 'italic' }} >
              {info.id}
            </h3>}
          </div>
        </MainHeader>
      )}
    </>
  );
};

export default MainHeaderContainer;
