import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(10, 4, 16, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999;
`;

const ModalFrame = styled.div`
  z-index: 999999999;
  width: 60%;
  height: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props=>props.bg ? `url(${props.bg}) no-repeat center / contain` : '#25132e'};


  @media (max-width: 1770px) {
    width: 80%;
  }
  @media (max-width: 1550px) {
    width: 70%;
  }
  /* @media (max-width: 1350px) {
    width: 85%;
  }
  @media (max-width: 1260px) {
    width: 85%;
  }
  @media (max-width: 1200px) {
    width: 85%;
  } */
  @media (max-width: 1024px) {
    background: none;
    height: 60%;
  }
`;

export {Overlay, ModalFrame};
