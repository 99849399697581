import React from 'react';
import {ArrowLeft, ArrowRight, LessonPreview, LessonsWrapper} from './styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {Box} from '@material-ui/core';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 2000},
    items: 3,
  },
  desktop: {
    breakpoint: {max: 1600, min: 1024},
    items: 3,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 1,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};

const Lessons = () => {
  return (
    <LessonsWrapper>
      <Box style={{width: '80%'}}>
        <Carousel
          draggable
          infinite
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={5000}
          customLeftArrow={
            <ArrowLeft>
              <ArrowBackIosIcon style={{fontSize: '3.3em'}} />
            </ArrowLeft>
          }
          customRightArrow={
            <ArrowRight>
              <ArrowForwardIosIcon style={{fontSize: '3em'}} />
            </ArrowRight>
          }
        >
          <div className="carousel-item">
            <LessonPreview
              bg={
                'http://s5.hostingkartinok.com/uploads/images/2013/03/eb1715793ddb5a37e70aa68ef0a46776.jpg'
              }
            >
              <div className="title"></div>
              <div className="number">
                <span>Урок 1</span>
              </div>
              <div className="description">
                <span>Обучение процесса слот игр</span>
                <span>Ознакомление с играми</span>
                <span>Цель достижения до урок 2:</span>
                <b>30.000 очков</b>
              </div>
            </LessonPreview>
          </div>
          <div className="carousel-item">
            <LessonPreview
              bg={
                'http://s5.hostingkartinok.com/uploads/images/2013/03/eb1715793ddb5a37e70aa68ef0a46776.jpg'
              }
            >
              <div className="title"></div>
              <div className="number">
                <span>Урок 2</span>
              </div>
              <div className="description">
                <span>Обучение индивидуальным Джекпотам</span>
                <span>Обучение 10% сashback системы</span>
                <span>Цель достижения до урок 3:</span>
                <b>60.000 очков</b>
              </div>
            </LessonPreview>
          </div>
          <div className="carousel-item">
            <LessonPreview
              bg={
                'http://s5.hostingkartinok.com/uploads/images/2013/03/eb1715793ddb5a37e70aa68ef0a46776.jpg'
              }
            >
              <div className="title"></div>
              <div className="number">
                <span>Урок 3</span>
              </div>
              <div className="description">
                <span>Free spin 20 в игре Book of Strike</span>
                <span>Зальные джекпоты</span>
                <span>Цель достижения до урок 4:</span>
                <b>100.000 очков</b>
              </div>
            </LessonPreview>
          </div>
        </Carousel>
      </Box>
    </LessonsWrapper>
  );
};

export default Lessons;
