import {API} from 'settings';

const fetchGenres = async () => {
  const URI = `${API}/v1/genres`;
  const query = {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

export default {
  fetchGenres,
};
