import React from 'react';

import {StyledFooter, FooterLogo, Copyright, FooterContainer} from './styles';

import {ReactComponent as Logo} from 'assets/images/footerlogo.svg';

const Footer = () => {
  return (
    <StyledFooter>
      <FooterContainer>
        <FooterLogo>
          <Logo />
          <Copyright>© 2019-2020 ООО «Название конторы»</Copyright>
        </FooterLogo>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
