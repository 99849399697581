import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const StyledButton = styled.button`
  width: ${(props) => (props.width ? props.width : '100px')};
  height: ${(props) => (props.height ? props.height : '35px')};
  border: 1px solid
    ${(props) =>
      props.disabled ? 'rgba(14, 154, 252, 0.4)' : props.theme.colors.mainBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
  color: ${(props) =>
    props.disabled ? 'rgba(14, 154, 252, 0.4)' : props.theme.colors.mainBlue};
  align-self: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  transition: all 0.3s ease;
  font-family: Roboto, sans-serif;
  margin: ${(props) => props.margin};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  &:hover {
    border-color: ${(props) => props.theme.colors.mainBlue};
    border-width: 2px;
    box-shadow: 0px 0px 10px 0px rgba(14, 154, 252, 0.4);
  }
  &:focus {
    outline: none;
  }

  ${media.tablet`
    width: ${(props) => (props.width ? props.width : '87px')};
    height: ${(props) => (props.height ? props.height : '24px')};
    font-size: 12px;
    line-height: 14px;
  `}
`;

export {StyledButton};
