import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const NewsItemModalWrapper = styled.div`
  width: 97.6%;
  position: absolute;
  height: 610px;
  background: ${(props) => props.theme.colors.darkBlue1};
  z-index: 9999;
  box-shadow: 0px 13px 25px #000a24;
  padding: 50px 6px 20px 0;

  ${media.phoneLandscape`
      padding:0;
      height: unset;
  `}
`;

const NewsItemModalContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 40px;
  padding-left: 50px;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.darkBlue1};
  }
  ::-webkit-scrollbar-thumb {
    background: #0e9afc;
    border-radius: 50px;
  }

  ${media.tabletLandscape`
      padding-left: 27px;
    .tag{
      margin-top: 20px;
    }
  `}

  ${media.phoneLandscape`
      padding-right:0;
      padding-left: 0;
      .tag{
        margin-top: 10px;
        margin-left:16px;
    }
  `}

  .close-icon {
    cursor: pointer;
    margin-right: 6px;
    position: absolute;
    top: 20px;
    right: 13px;

    ${media.phoneLandscape`
      width:25px;
      height:25px;     
      top: 6px;
      right: 1px;
 
  `}
  }
`;

const PaginationText = styled.span`
  color: ${(props) =>
    props.disabled ? 'rgba(14, 154, 252, 0.4)' : props.theme.colors.mainBlue};
  margin: ${(props) => props.margin};

  ${media.phone`
      display: none;
  `}
`;

const NewsHeader = styled.h3`
  font-style: normal;
  font-weight: normal;
  color: ${(props) => props.theme.colors.white};
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0 15px 0;

  ${media.phoneLandscape`
      margin: 20px 16px 15px 16px;
  `}
`;

const NewsItemDate = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.lightGrey};
  margin-bottom: 15px;

  ${media.phoneLandscape`
      margin: 0px 16px 15px 16px;
  `}
`;
const NewsItemImage = styled.div`
  width: 425px;
  min-height: 241px;
  margin-bottom: 15px;

  ${media.tabletLandscape`
    width: 350px;
    min-height: 198px;
  `}

  ${media.phoneLandscape`
      width: 295px;
      height:166px;
      margin: 0px 16px 15px 16px;
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const NewsItemMainText = styled.p`
  color: ${(props) => props.theme.colors.lightGrey};
  width: 600px;
  ${media.tablet`
    width: unset;
  `}

  ${media.phoneLandscape`
      margin: 0px 16px 15px 16px;
  `}
`;

const PaginationBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  border-top: 1px solid ${(props) => props.theme.colors.mainBlue};
  margin-right: 40px;
  margin-top: 6px;

  ${media.phoneLandscape`
    padding: 10px 40px;
    margin: 6px 0 0 0;
  `}
`;

export {
  NewsItemModalContainer,
  NewsItemModalWrapper,
  PaginationText,
  NewsHeader,
  NewsItemDate,
  NewsItemMainText,
  NewsItemImage,
  PaginationBlock,
};
