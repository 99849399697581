import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import {
  NewsModalContainer,
  NewsModalHeading,
  NewsModalDate,
  NewsModalText,
} from './styles';

const NewsModal = ({item: {title, date}, closeModal}) => {
  return (
    <NewsModalContainer>
      <CloseIcon className="closemodalicon" onClick={closeModal} />
      <NewsModalHeading>{title}</NewsModalHeading>
      <NewsModalDate>{date ? date : 'No data'}</NewsModalDate>
      <NewsModalText>{title}</NewsModalText>
    </NewsModalContainer>
  );
};

export default NewsModal;
