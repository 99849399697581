import {Box, CircularProgress, Grid} from '@material-ui/core';
import {StartBtn} from 'components/Learners/Directions/styles';
import {useForm} from 'hooks/useForm';
import React from 'react';
import {postTestingAnswers} from 'services/auth';
import Quest from '../Quest';

const Bookmaker = ({direction, hideTeory, showCongratulations}) => {
  const {form, setFormItem} = useForm({
    '1': null,
    '2': null,
    '3': null,
    '4': null,
    '5': null,
  });

  const [answerLoading, setAnswerLoading] = React.useState(false);
  const [isAnswer, setIsAnswer] = React.useState(false);

  const data = [form['1'], form['2'], form['3'], form['4'], form['5']];

  const handleAnswerPost = () => {
    setAnswerLoading(true);

    postTestingAnswers(direction, data).then((res) => {
      setAnswerLoading(false);
      setIsAnswer(true);
      showCongratulations()
    });
  };
  return (
    <Grid container style={{zIndex: 2}}>
      <Grid item xs={6}>
        <Quest
          title="1. На что не собирали ставки ранние букмекеры Древнего Рима?"
          answers={['битва гладиаторов', 'конка на колесницах', 'кошачьи бои']}
          value={form['1']}
          setAnswers={(id) => setFormItem('1', id)}
          correct={isAnswer ? 2 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Quest
          title="2. Какие бывают букмекерские направления?"
          answers={[
            'специалист по live-ставкам',
            'aналитик посещаемости букмекерских контор',
            'обменщик валют в игровые токены',
          ]}
          value={form['2']}
          setAnswers={(id) => setFormItem('2', id)}
          correct={isAnswer ? 0 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Quest
          title="3. Обязанности букмекера-кассира"
          answers={[
            'технический осмотр и ремонт кассового аппарата',
            'приём оплаты, выдача квитанций и выигрыша',
            'аналитика определенной команды участника ставок',
          ]}
          value={form['3']}
          setAnswers={(id) => setFormItem('3', id)}
          correct={isAnswer ? 1 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Quest
          title="4. Обязанности букмекера-аналитика"
          answers={[
            'статистика прежних игр между соперниками',
            'прогноз шансов повышения выручки конторы за квартал',
            'сопровождение командигроков и встреча в аэропорте',
          ]}
          value={form['4']}
          setAnswers={(id) => setFormItem('4', id)}
          correct={isAnswer ? 0 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Quest
          title="5. Какое образование приветствуется в букмекерской специальности?"
          answers={[
            'Высшее психологическое',
            'Высшее педагогическое',
            'Высшее математическое',
          ]}
          value={form['5']}
          setAnswers={(id) => setFormItem('5', id)}
          correct={isAnswer ? 2 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{width: '100%', height: '100%'}}
        >
          <StartBtn
            disabled={!!data.filter((a) => a === null).length}
            onClick={isAnswer ? hideTeory : handleAnswerPost}
          >
            {isAnswer ? (
              'Перейти к практике'
            ) : answerLoading ? (
              <CircularProgress />
            ) : (
              'ОТПРАВИТЬ'
            )}
          </StartBtn>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Bookmaker;
