import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const StyledGenreButton = styled.button`
  width: 226px;
  height: 48px;
  background: ${(props) => props.theme.colors.darkBlue};
  box-shadow: ${(props) => (props.active ? 'none' : props.theme.tabBoxShadow)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.mainBlue};
  transform: skewX(-10deg);
  margin-top: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: inherit;

  ${media.tabletLandscape`
    width: 197px;
    height: 38px;
  `}

  ${media.tablet`
    width: 132px;
    height: 38px;
  `}
  
  &:hover {
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }

  span {
    font-size: 20px;
    line-height: 24px;
    display: block;
    transform: skewX(10deg);

    ${media.tablet`
      width: 102px;
      font-size: ${(props) => props.theme.fontSizes.text};
      line-height: ${(props) => props.theme.lineHeights.text};
    `}
  }
`;

export {StyledGenreButton};
