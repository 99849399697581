import styled from 'styled-components';
import {down} from 'styled-breakpoints';

export const DirectionsWrapper = styled.div`
  height: 100%;
  .direction-title {
    margin: 2em;
    color: #4a2952;
  }

  p {
    color: #4a2952;
    margin: .5em 2em;
  }

  .outline {
    text-decoration: underline;
  }

  .test-btn {
    cursor: pointer;
    position: relative;
    padding: 1em;
    margin: 2em;
    border-radius: 0.5em;
    border: none;
    background: #4a2a53;
    font-size: 1em;
    font-weight: 700;
    margin-top: 1vh;
    color: #fff;
    z-index: 2;
  }
`;

export const LearningCard = styled.div`
  width: 12em;
  height: 13em;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 15px;
  padding: 10px;
  z-index: 2;

  img {
    width: 40px;
    height: 40px;
  }

  .card__title {
    color: #000000;
    margin: 10px 0;
    font-weight: 500;
    font-size: 0.8em;
  }
  .card__description {
    color: #000000;
    font-size: 0.7em;
    text-align: center;
    line-height: 1.5em;
    max-width: 90%;
  }
`;

export const StartBtn = styled.button`
  cursor: pointer;
  position: relative;
  width: max-content;
  padding: 0 1em;
  height: 2.3em;
  border-radius: 0.5em;
  border: none;
  background: #4a2a53;
  font-size: 1em;
  font-weight: 700;
  margin-top: 1vh;
  color: #fff;
  z-index: 2;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  transition: filter 1s;
  ${props => props.disabled && 'filter: opacity(0.6);'}
`;

export const Description = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3em 0;

  span {
    font-size: 3vh;
    font-weight: 500;
    text-align: center;
    color: #9699a8;
    margin-bottom: 2vh;
  }

  h3 {
    color: #492952;
    font-size: 2em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
  }

  ${down('lg')} {
    margin: 1em 0;

    h3 {
      font-size: 1.5em;
    }
  }

  @media (max-width: 1280px) and (max-height: 1024px) {
    h3 {
      font-size: 1.5em;
    }
    margin: 0.5em 0;
  }
`;
