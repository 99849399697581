import styled from 'styled-components';
import button from 'assets/images/button.png';

const RulesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  color: #e1b058;
  margin-top: 1%;
  max-height: 50vh;
  width: auto;
  overflow: auto;
  h3, h4 {
      font-size: 20px;
    }
  p, li {
      font-size: 12px;
    }

  @media (max-width: 1024px) {
    border: 3px solid #e1b058;
    border-radius: 10px;
    padding: 5% 0;
    background: #25132e;
  }
  @media (max-width: 900px) {
    border: 3px solid #e1b058;
    border-radius: 10px;
    padding: 5% 0;
    background: #25132e;
    max-height: 95vh;
    overflow: auto;
    p, li {
      font-size: 12px;
    }
    h3, h4 {
      font-size: 20px;
      margin: 3px 0;
    }
    button {
      font-size: 20px;
    }
  }
`;

const Heading = styled.h3`
  font-size: 130%;
  line-height: 51px;
  font-family: 'Colus';
  text-align: center;
  filter: drop-shadow(7.891px 16.178px 10.5px rgba(0, 0, 0, 0.2));
  font-weight: normal;

  @media (max-width: 1280px) {
    font-size: 30px;
    line-height: 30px;
  }

  @media (max-width: 730px) {
    font-size: 32px;
  }
`;

const Subheading = styled.h4`
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 23px;
  }
`;

const Text = styled.div`
  margin: 15px 0 20px 0;
  width: 60%;
  text-align: ${(props) => (props.align ? props.align : 'center')};

  @media (max-width: 1280px) {
    margin: 5px 0;
  }
  @media (max-width: 1024px) {
    width: 95%;
  }

  p {
    margin-bottom: 5px;
  }

  li {
    font-size: 14px;
  }
`;

const Button = styled.button`
  border-image: ${`url(${button})`};
  border-image-slice: 77;
  border-image-width: 50px;
  border-image-outset: 0;
  border-image-repeat: round;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  width: auto;
  padding: 13px 20px;
  height: 60px;
  background: transparent;
  &:focus {
    outline: none;
  }
  font-size: ${(props) => (props.fontSize ? props.fontSize : '36px')};
  font-family: 'Colus';
  text-align: center;
  color: ${(props) =>
    props.disabled ? 'rgba(255,255,255,0.6)' : 'rgba(255,255,255,1)'};
  text-transform: uppercase;
  line-height: 1.018;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: ${(props) => props.margin};
  @media (min-width: 1280px) {
    &:hover {
      text-shadow: 0 0 15px #be00c5, 0 0 10px #be00c5;
      color: white;
    }
  }
  @media (max-width: 560px) {
    height: 100%;
  }
  @media (max-width: 470px) {
    margin: 3% auto;
  }
  @media (max-width: 900px) {
    font-size: 20px;
  }
`;

export {RulesContainer, Heading, Subheading, Text, Button};
