import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const PollContainer = styled.div`
  width: 99%;
  display: flex;
  padding: 28px 20px 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: ${(props) => props.theme.blockShadow};
  background: #031238;

  ${media.tabletLandscape`
    .answerbutton{
      background: rgba(14, 154, 252, 0.5);
      color: ${(props) => props.theme.colors.white};
    }
  `}
`;

const PollText = styled.p`
  margin: 15px 0;
  font-family: Roboto;
  font-weight: 300;
  ${media.tablet`
    font-size: 14px;
    line-height: 16px;
  `}
`;

const PollQuestionsContainer = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const PollQuestion = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);

  &:last-of-type {
    margin-bottom: 20px;
  }
`;

const PollQuestionText = styled.span`
  font-size: 14px;
  font-family: Roboto;
  font-weight: 300;
  line-height: 16px;
  color: ${(props) =>
    props.active ? props.theme.colors.white : props.theme.colors.lightGrey};
  margin-left: 15px;

  ${media.tablet`
    margin-left: 7px;
  `}
`;

const PollQuestionButton = styled.div`
  border: 1px solid ${(props) => props.theme.colors.mainBlue};
  border-radius: 50px;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: ${(props) => (props.active ? props.theme.blockShadow : 'none')};
  background: rgba(0, 0, 0, 0);
`;

const PollQuestionInner = styled.div`
  min-width: 9px;
  min-height: 9px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.mainBlue};
`;

export {
  PollContainer,
  PollText,
  PollQuestionsContainer,
  PollQuestionButton,
  PollQuestionText,
  PollQuestion,
  PollQuestionInner,
};
