import styled from 'styled-components';



export const Wrapper = styled.span`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Logo = styled.img`
    height: auto;
    width: 10%;
    animation: zoomOut 2s ease-in;

    @keyframes zoomOut {
        from {
        opacity: 0;
        transform: scale3d(3, 3, 3);
        }
    
        50% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
        
        }
    
        to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
        }
    }
`
