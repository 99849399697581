import React from 'react';
import {Box, CircularProgress, Grid} from '@material-ui/core';
import {StartBtn} from 'components/Learners/Directions/styles';
import Quest from '../Quest';
import {useForm} from 'hooks/useForm';
import {postTestingAnswers} from 'services/auth';

const HistoryOfGambling = ({direction, hideTeory, showCongratulations}) => {
  const {form, setFormItem} = useForm({
    '1': null,
    '2': null,
    '3': null,
    '4': null,
    '5': null,
  });

  const [answerLoading, setAnswerLoading] = React.useState(false);
  const [isAnswer, setIsAnswer] = React.useState(false);

  const data = [form['1'], form['2'], form['3'], form['4'], form['5']];

  const handleAnswerPost = () => {
    setAnswerLoading(true);
    postTestingAnswers(direction, data).then((res) => {
      setAnswerLoading(false);
      setIsAnswer(true);
      showCongratulations()
    });
  };
  return (
    <Grid container style={{zIndex: 2}}>
      <Grid item xs={6}>
        <Quest
          title='1. Что означает термин "гемблинг"?'
          answers={[
            'Рисковые действия для приумножение ресурсов',
            'Звук игровых автоматов в момент выигрыша',
            '"Сияние драгоценностей"',
          ]}
          value={form['1']}
          setAnswers={(id) => setFormItem('1', id)}
          correct={isAnswer ? 0 : null}
        />
      </Grid>

      <Grid item xs={6}>
        <Quest
          title="2. Как давно зародился гемблинг?"
          answers={['В наше время', 'В XVII веке', 'До нашей эры']}
          value={form['2']}
          setAnswers={(id) => setFormItem('2', id)}
          correct={isAnswer ? 2 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Quest
          title="3. Что в лотерее является основным инструментом?"
          answers={['Фишка', 'Билет', 'Токен']}
          value={form['3']}
          setAnswers={(id) => setFormItem('3', id)}
          correct={isAnswer ? 1 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Quest
          title="4. Как изначально назывались слот автоматы?"
          answers={['Слепой преступник', 'Безногий вор', 'Однорукий бандит ']}
          value={form['4']}
          setAnswers={(id) => setFormItem('4', id)}
          correct={isAnswer ? 2 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Quest
          title='5. Какой шоу в России основано на концепции "Колесо Фортуны"?'
          answers={['Дом2', 'Поле чудес', 'Кто хочет стать миллионером']}
          value={form['5']}
          setAnswers={(id) => setFormItem('5', id)}
          correct={isAnswer ? 1 : null}
        />
      </Grid>
      <Grid item xs={6}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{width: '100%', height: '100%'}}
        >
          <StartBtn
            disabled={!!data.filter((a) => a === null).length}
            onClick={isAnswer ? hideTeory : handleAnswerPost}
          >
            {isAnswer ? (
              'Перейти к практике'
            ) : answerLoading ? (
              <CircularProgress />
            ) : (
              'ОТПРАВИТЬ'
            )}
          </StartBtn>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HistoryOfGambling;
