import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';

import { HeaderWrapper, HeaderContainer } from './styles';

import NavBar from './NavBar';
import LoginModal from 'components/LoginModal';
import MobileMenu from 'components/MobileMenu';

import { useSelector } from 'react-redux';
import { authSelector } from 'redux/authReducer';

const Header = () => {

  const [isLoginFormShown, setIsLoginFormShown] = React.useState(false);
  const [isMobileMenuShown, setIsMobileMenuShown] = React.useState(false);
  // eslint-disable-next-line
  const showLoginForm = () => setIsLoginFormShown(true);

  const closeLoginForm = () => setIsLoginFormShown(false);

  const showMobileMenu = () => setIsMobileMenuShown(true);

  const closeMobileMenu = () => setIsMobileMenuShown(false);

  const {
    status,
  } = useSelector(authSelector);

  const handlerKeyControl = React.useCallback(
    (e) => {
      if (!isLoginFormShown && e.code === 'Enter') {
        e.preventDefault();
        showLoginForm();
      }
    },
    [isLoginFormShown, showLoginForm]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handlerKeyControl);
    return () => {
      window.removeEventListener('keydown', handlerKeyControl);
    };
  }, [handlerKeyControl]);

  return (
    <HeaderWrapper>
      <LoginModal open={isLoginFormShown} handleClose={closeLoginForm} />
      <HeaderContainer>
        <NavBar status={status} handleOpen={showLoginForm} />
        <MenuIcon className="mobilemenu" onClick={showMobileMenu} />
        {isMobileMenuShown && <MobileMenu closeMobileMenu={closeMobileMenu} />}
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
