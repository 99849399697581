import React from 'react';

import { GameItemContainer, HoverItemButton } from './styles';

const GameItem = ({ game: { icon, title, url } }) => {
  const onClick = () => {
    window.disablePusher = true;
    location.href = url;
  };
  return <GameItemContainer>
    <img src={icon} alt={title} />
    <HoverItemButton>
      <a onClick={onClick} href='#' target="_blank" rel="noopener noreferrer">
        Играть
      </a>
    </HoverItemButton>
  </GameItemContainer>;
};

export default GameItem;
