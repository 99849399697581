import React from 'react';

import Menu from 'components/AuditMenu/Menu';



const Audit = () => {
  return <Menu/>;
};

export default Audit;
