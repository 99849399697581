import {Box} from '@material-ui/core';
import React from 'react';

const Cashier = ({setTabSelected, setDirection}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
    >
      <span className="direction-title">
        <span className="outline">Выбор направления</span> - <b>Кассир</b>
      </span>

      <p>
        <b>Обязанности:</b> Поддержание баланса инвентаря кассы; покупка и
        продажа фишек казино у клиентов; согласование работы кассы.
      </p>
      <p>
        Несмотря на то, что работа кассира одна из самых малооплачиваемых в
        казино, заработок может быть значительно пополнен за счет чаевых.
        Выигравшие гости склонны оставлять чаевые именно кассирам, так как
        психологически они видят в кассире человека, дающего им самим деньги.{' '}
      </p>
      <p>
        <b>Опыт:</b>
        <br /> Минимальное требование - среднее образование. Желателен опыт
        работы в банковской сфере или другой опыт работы с наличностью. Навыки:
        Способность быстро и точно считать деньги и фишки; навыки ввода данных;
        бухгалтерские навыки.
      </p>
      <p>
        Касса казино подобна банку, а работа кассира в казино соответственно
        подобна работе кассира в банке. Касса несет ответственность за наличные
        деньги, фишки (далее чипы) и символы, которые используются, чтобы
        консолидировать действия казино.
      </p>
      <p>
        Когда дополнительные чипы необходимы на настольных играх, касса -
        источник этих чипов. Касса также обеспечивает фонды, для проведения
        сделок с клиентами казино. В кассе находится "главный банк", который
        поддерживает все финансовые операции в казино. В кассе находятся и
        клиентские окна с предопределенным количеством денег, которое остается
        постоянным, например 1.000.000 рублей. Число занятых кассиров зависит от
        относительного размера кассы и числа потенциальных клиентов в казино в
        течение времени пик.
      </p>
      <p>
        Касса также содержит "заполняющийся банк" (Fill), который может работать
        на основании аванса и поддерживает значительный инвентарь чипов и
        маркеров, используемых исключительно для проведения сделок со служащими
        казино. Сделки состоят, прежде всего, из обменов чипов с настольными
        играми, которые действуют в виде заполнения и кредитования.
        Заполняющийся банк также перемещает чипы к окнам кассиров, чтобы
        пополнить исчерпанные материальные запасы и принимает у кассиров избыток
        чипов, получаемый через сделки клиентов, такие как выплаты по ним. Все
        обмены чипами регистрируются заполнением документации кассиром банка.
        Заполняющийся банк пополняется через обмены с главным банком. Инвентарь
        кассы определен для каждой смены. Заполняющийся банк и главный банк
        подсчитываются и проверяются уходящей сменой и приступившей к работе
        сменой. Балансы тщательно проверяются, так как любые различия
        (недостаток или наоборот) могут кончаться дисциплинарным взысканием,
        принимаемым против служащего, ответственного за различие. Результаты
        этих процедур регистрируются на листе ответственности, который
        показывает все балансы, за которые ответственна касса.
      </p>
      <p>
        Большинство казино сегодня использует компьютерные системы,
        предназначенные для сделок кассы, что облегчает согласование балансов в
        конце смены.
      </p>
      <Box
        style={{width: '100%', marginTop: '2em'}}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <button
          className="test-btn"
          onClick={() => {
            setTabSelected(1);
          }}
        >
          Перейти к тестированию
        </button>
      </Box>
    </Box>
  );
};

export default Cashier;
