import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const NavBarContainer = styled.nav`
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;

  ${media.phoneLandscape`
    align-items: center;
    justify-content: center;
    position:relative;

    .logbutton {
      position: absolute;
      top:10px;
      left:0;
      width: 64px;
      height: 32px;
    }
  `}

  .logo {
    ${media.tabletLandscape`
    width: 101px;
    height: 110px;
  `}
    ${media.phoneLandscape`
      width: 40px;
      height: 43px;
  `}
  }
`;

export {NavBarContainer};
