import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {useSelector, useDispatch} from 'react-redux';
import dayjs from 'dayjs';

import {
  NewsItemModalContainer,
  NewsItemModalWrapper,
  NewsHeader,
  NewsItemDate,
  NewsItemImage,
  NewsItemMainText,
  PaginationBlock,
  PaginationText,
} from './styles';

import {theme} from 'theme/theme';

import {FlexBox} from 'components/Common/FlexBox';
import Button from 'components/Common/Button';
import {newsSelector, getNextOrPrevNews} from 'redux/newsReducer';

const FIRST_PAGE = 1;

const NewsItemModal = ({hideBlock, currentPage}) => {
  const [pageNumber, setPageNumber] = React.useState(currentPage);
  const dispatch = useDispatch();
  const {
    items: news,
    nextOrPrev: {item: page},
  } = useSelector(newsSelector);

  const nextIsDisabled = pageNumber === news.length;
  const prevIsDisabled = pageNumber === FIRST_PAGE;

  React.useEffect(() => {
    dispatch(getNextOrPrevNews(pageNumber));
  }, [dispatch, pageNumber]);

  const toNextNews = (pageNum) => {
    if (pageNum === news.length) {
      return;
    }
    setPageNumber((prev) => prev + 1);
    dispatch(getNextOrPrevNews(pageNum));
  };

  const toPrevNews = (pageNumber) => {
    if (prevIsDisabled) {
      return;
    }
    setPageNumber((prev) => prev - 1);
    dispatch(getNextOrPrevNews(pageNumber));
  };

  return (
    <>
      {page && (
        <NewsItemModalWrapper>
          <NewsItemModalContainer>
            <FlexBox
              width="100%"
              align="flex-start"
              justify="space-between"
              height="100%"
              className="tag"
              margin=""
            >
              <FlexBox align="flex-start" justtify="flex-start" flexWrap="wrap">
                {page[0].tags &&
                  page[0].tags.map((tag) => (
                    <Button key={tag.id} text={tag.name} margin="0 10px 0 0 " />
                  ))}
              </FlexBox>
              <CloseIcon
                style={{color: theme.colors.mainBlue, fontSize: 40}}
                className="close-icon"
                onClick={hideBlock}
              />
            </FlexBox>
            <NewsHeader>{page[0].title}</NewsHeader>
            <NewsItemDate>
              {dayjs(page[0].created_at).format('DD.MM.YYYY, HH:mm')}
            </NewsItemDate>
            <NewsItemImage>
              <img src={page[0].url} alt={page[0].url} />
            </NewsItemImage>
            <NewsItemMainText>
              {page[0].content === '' ? 'Empty content' : page[0].content}
            </NewsItemMainText>
            <PaginationBlock>
              <FlexBox
                display="flex"
                aligh="center"
                cursor="pointer"
                onClick={() => toPrevNews(pageNumber)}
                disabled={prevIsDisabled}
              >
                <ArrowBackIcon
                  style={{
                    color: prevIsDisabled
                      ? 'rgba(14, 154, 252, 0.4)'
                      : theme.colors.mainBlue,
                  }}
                />
                <PaginationText margin="0 0 0 16px" disabled={prevIsDisabled}>
                  Предыдущая новость
                </PaginationText>
              </FlexBox>
              <FlexBox
                display="flex"
                aligh="center"
                cursor="pointer"
                onClick={() => toNextNews(pageNumber)}
                disabled={nextIsDisabled}
              >
                <PaginationText margin="0 16px 0 0" disabled={nextIsDisabled}>
                  Следующая новость
                </PaginationText>
                <ArrowForwardIcon
                  style={{
                    color: nextIsDisabled
                      ? 'rgba(14, 154, 252, 0.4)'
                      : theme.colors.mainBlue,
                  }}
                  cursor="pointer"
                />
              </FlexBox>
            </PaginationBlock>
          </NewsItemModalContainer>
        </NewsItemModalWrapper>
      )}
    </>
  );
};

export default NewsItemModal;
