import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {PageWrapper} from 'components/Common/PageWrapper/index';
import RecordsBlock from 'components/Common/RecordsBlock';
import NewsItem from 'components/NewsPage/NewsItem';
import {NewsContainer} from 'components/NewsPage/NewsContainer';

import {getNews, newsSelector} from 'redux/newsReducer';
import Header from 'components/Header';
import Footer from 'components/Footer';
import MainPreloader from 'components/Common/MainPreloader';
import { authSelector } from 'redux/authReducer';

const Home = () => {
  const dispatch = useDispatch();
  const {items: news, loading} = useSelector(newsSelector);
  const {
    status,
  } = useSelector(authSelector);

  // React.useEffect(() => {
  //   status === '' && dispatch(getInfo());
  // }, [dispatch, status]);


  React.useEffect(() => {
    dispatch(getNews());
  }, [dispatch]);

  return status ? (
    <>
      <Header />
      <PageWrapper margin="30px 0 0 0">
        {loading && 'Loading...'}
        <NewsContainer>
          {!loading &&
            news.length > 0 &&
            news.map((item, index) => (
              <NewsItem
                item={item}
                currentPage={index + 1}
                key={item.id}
                main={index === 0}
              />
            ))}
        </NewsContainer>
        <RecordsBlock />
      </PageWrapper>
      <Footer />
    </>
  ) : <MainPreloader/>
};

export default Home;
