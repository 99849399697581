import React from 'react';
import {StyledShadowText} from './styles';

const ShadowText = ({text, margin, ...props}) => {
  return (
    <StyledShadowText margin={margin} {...props}>
      {text}
    </StyledShadowText>
  );
};

export default ShadowText;
