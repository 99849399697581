import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: #000094;
    padding: 25px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: #99f9ff;

    .menu-wrapper {
        width: 100%;
        margin-top: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .menu {
        margin: 0 50px;
    }
    .menu > li {
        font-size: 20px;
        position: relative;
        list-style-type: none;
        margin: 25px 0;
    }

    .selected:before {
        content: ">";
        font-size: 20px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: -10px;
    }

    .disabled {
        opacity: .4;
    }

    .service {
        position: relative;
        margin-top: 130px;
        width: 100%;
        height: 100%;
        font-size: 24px;
    }
`;


const Header = styled.div`
    width: 90vw;
    min-height: 30px;
    background-color: #020440;
    display: flex;
    justify-content: ${props => props.align ? 'space-between' : 'center'};
    align-items: center;
    font-size: 20px;
    span {
        margin: 0 20px;
    }
`

const Row = styled.div`
    width: 90%;
    height: ${props => props.height}%;
    display: flex;
    span:nth-child(1) {
        width: 60%;
        padding-left: 20vw;
    }

    span:nth-child(2) {
        width: 20%;
        text-align: right;
    }

    span:nth-child(3) {
        width: 20%;
        text-align: right;
    }

`

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 90%;
    margin: 20px;

    div {
        display: flex;
    }
`
const Button= styled.div`
        margin: 10px;
        background: #99f9ff;
        color: #000094;
        border-radius: 5px;
        width: 130px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;

        span {
            text-align: center;
            position: relative;
            max-width: 100px;
        }

        ${props => props.popup ? 
        `span:after {
            content: '${props.popup}';
            position: absolute;
            transform: translate(-50%, -50%);
            top: -60%;
            left: 50%;
            color: #99f9ff;
            font-size: 15px;
        }` : ``}
`

const HandPayFooter = styled.div`
    display: flex;
    justify-content: space-between;
    position: fixed;
    flex-direction: column;
    bottom: 0;
    width: 90%;
    margin: 20px;
`
const AttendantKey = styled.span`
    color: red;
    background: #fff;
    height: 6vh;
    margin: 4vh 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export {Wrapper, Header, Row, ButtonsWrapper, Button, HandPayFooter, AttendantKey};
