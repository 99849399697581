import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {RecordTableContainer} from './styles';

import TableItem from '../TableItem';
import {getRecords, recordsSelector} from 'redux/recordsReducer';

const RecordsTable = ({selectedGame}) => {
  const dispatch = useDispatch();
  const {items: records, status} = useSelector(recordsSelector);

  React.useEffect(() => {
    status === null && dispatch(getRecords(selectedGame.id));
  }, [dispatch, selectedGame.id, status]);

  return (
    <RecordTableContainer>
      {selectedGame.id &&
        records.length > 0 &&
        records.map((item, index) => (
          <TableItem key={item.id} item={item} place={index + 1} />
        ))}
    </RecordTableContainer>
  );
};

export default RecordsTable;
