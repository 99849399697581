import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const StyledShadowText = styled.span`
  font-size: ${(props) => props.theme.fontSizes.blockTitle};
  line-height: ${(props) => props.theme.lineHeights.mainBlue};
  text-shadow: -1px -1px 0px ${(props) => props.theme.colors.mainBlue};
  text-align: center;
  margin: ${(props) => props.margin};
  display: block;
  align-self: ${(props) => props.alignSelf};

  ${media.tablet`
    font-size: 25px;
    line-height: 30px;
  `}
`;

export {StyledShadowText};
