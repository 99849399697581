import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {
  StyledYouthPoliticsContainer,
  NewsAndPollBlock,
  YouthPoliticsTextContainer,
  YouthPoliticsText,
  YouthPoliticsHeading,
} from './styles';

import NewsItem from '../NewsItem';
import NewsModal from '../NewsModal';
import Poll from '../Poll';

import {useShowBlock} from 'hooks/useShowBlock';
import {newsSelector, getPoliticalNews} from 'redux/newsReducer';

const YouthPoliticsContainer = () => {
  const {isBlockShown, showBlock, hideBlock} = useShowBlock();
  const dispatch = useDispatch();
  const {
    politicalNews: {item},
  } = useSelector(newsSelector);

  React.useEffect(() => {
    dispatch(getPoliticalNews());
  }, [dispatch]);

  return (
    <StyledYouthPoliticsContainer>
      {isBlockShown && (
        <NewsModal item={item && item[0]} closeModal={hideBlock} />
      )}
      <NewsAndPollBlock>
        <NewsItem item={item && item[0]} showModal={showBlock} />
        <Poll />
      </NewsAndPollBlock>
      <YouthPoliticsTextContainer>
        <YouthPoliticsHeading>Молодёжная политика</YouthPoliticsHeading>
        <YouthPoliticsText>
          <p>
            Несмотря на то, что в рядах членов всех парламентских партий
            присутствует заметное количество молодых людей, их участие в
            непосредственном принятии решений в городском парламенте ограничено.
          </p>
            Причин этому несколько, но основных две:
            <p>
              во-первых, партийные дисциплина и иерархия сами по себе "заточены"
              на решающую роль среднего и старшего поколений,
            </p>
            <p>
              во-вторых, сама структура парламента не обеспечивает должный учёт
              интересов молодёжи - в городском правительстве есть Комитет по
              делам молодёжи, а в ЗакСе соответствующей профильной комиссии нет.
            </p>
            Поэтому мы считаем целесообразным создание такой структуры, как
            Молодёжный электронный парламент (МЭП), в задачи которого будут
            входить:
            <p>
              - представительство интересов именно молодёжи в законодательном
              процессе,
            </p>
            <p>
              - повышение эффективности взаимодействия городского ЗакСа и
              Комитета по молодёжной политике городского правительства,
            </p>
            <p>
              - организация эффективной обратной связи между городской молодёжью
              и ветвями городской власти.
            </p>
            <p>
              Для обеспечения последнего (в том числе) на нашем сайте будут
              регулярно проводиться опросы на актуальные темы, пример которого
              вы сейчас видите.
            </p>
        </YouthPoliticsText>
      </YouthPoliticsTextContainer>
    </StyledYouthPoliticsContainer>
  );
};

export default YouthPoliticsContainer;
