import styled from 'styled-components';

const OfertaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  color: #e1b058;
  margin-top: 1%;
  max-height: 50vh;
  width: auto;
  overflow: auto;
  h3, h4 {
      font-size: 20px;
    }
  p, li {
      font-size: 12px;
    }

  @media (max-width: 1024px) {
    border: 3px solid #e1b058;
    border-radius: 10px;
    padding: 5% 0;
    background: #25132e;
  }
  @media (max-width: 900px) {
    border: 3px solid #e1b058;
    border-radius: 10px;
    padding: 5% 0;
    background: #25132e;
    max-height: 95vh;
    overflow: auto;
    p, li {
      font-size: 12px;
    }
    h3, h4 {
      font-size: 20px;
      margin: 3px 0;
    }

    button {
      font-size: 20px;
    }
  }
`;

export {OfertaContainer};
