import styled from 'styled-components';

const NewsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 50px 0 50px 50px;
  position: absolute;
  width: 97.6%;
  z-index: 99;
  background: #03224d;
  box-shadow: 0px 13px 25px #000a24;

  .closemodalicon {
    cursor: pointer;
    margin-right: 6px;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    fill: ${(props) => props.theme.colors.mainBlue};
  }
`;

const NewsModalHeading = styled.h3`
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.white};
`;

const NewsModalDate = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.lightGrey};
  margin: 15px 0;
`;

const NewsModalText = styled.p`
  width: 80%;
  color: ${(props) => props.theme.colors.lightGrey};
  font-size: 16px;
  line-height: 19px;
`;

export {NewsModalContainer, NewsModalHeading, NewsModalDate, NewsModalText};
