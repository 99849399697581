import { createSlice } from '@reduxjs/toolkit';
import { auth } from 'services';

export const slice = createSlice({
  name: 'auth',
  initialState: {
    status: '',
    token: null || localStorage.getItem('token'),
    templateId: null,
    loading: false,
    error: null,
    data: null,
    records: {
      items: [],
      loading: false,
      error: null,
    },
    authInfo: {
      info: null,
      loading: false,
      error: null,
    },
  },
  reducers: {
    loginIsLoading: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.token = payload;
    },
    loginFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    loginStatus: (state, { payload }) => {
      state.status = payload;
    },
    getInfoIsLoading: (state) => {
      state.authInfo.loading = true;
    },
    getInfoSuccess: (state, { payload }) => {
      state.authInfo.info = payload;
      state.authInfo.loading = false;
      state.authInfo.error = null;
    },
    getInfoFailure: (state, { payload }) => {
      state.authInfo.loading = false;
      state.authInfo.error = payload;
    },
    getRecordsIsLoading: (state) => {
      state.records.loading = true;
    },
    getRecordsSuccess: (state, { payload }) => {
      state.records.items = payload;
      state.records.loading = false;
      state.records.error = null;
    },
    getRecordsFailure: (state, { payload }) => {
      state.records.loading = false;
      state.records.error = payload;
    },
    setInfoBalance: (state, { payload }) => {
      state.authInfo.info.balance = payload;
    },
    setPollSum: (state, { payload }) => {
      state.authInfo.info.poll_sum = payload;
    },
    setInfoDenom: (state, { payload }) => {
      state.authInfo.info.denomination = payload;
    },
    setHighscore: (state, { payload }) => {
      state.authInfo.info.highscore = payload;
    },
    сhangeAuthInfoJackpots: (state, { payload }) => {
      state.authInfo.info.jackpots[payload.type] = payload.jackpot;
    },
    setTemplateId: (state, { payload }) => {
      state.templateId = payload;
    },
  },
});

export function getRecords() {
  return async (dispatch) => {
    dispatch(getRecordsIsLoading());

    try {
      const response = await auth.fetchRecords();
      dispatch(getRecordsSuccess(response.data));
    } catch (error) {
      dispatch(getRecordsFailure(error.message));
    }
  };
}
export function getInfo() {
  return async (dispatch) => {
    dispatch(getInfoIsLoading());
    auth
      .fetchInfo()
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(getInfoSuccess(res.data.data));
          dispatch(loginStatus(res.data.code));
          dispatch(setTemplateId(res.data.data.hall_template));
        } else {
          dispatch(getInfoFailure(res.data.data));
          dispatch(loginStatus(res.data.code));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getInfoFailure(err));
        dispatch(loginStatus(400));
      });
  };
}
export function updateInfo() {
  return async (dispatch) => {
    auth.fetchInfo().then((res) => {
      if (res.data.code === 200) {
        if (!res.data.data.poll_sum) {
          dispatch(setPollSum(0));
        }
      }
    });
  };
}
export function signUp(data) {
  return async (dispatch) => {
    dispatch(loginIsLoading());

    auth.login(data).then((res) => {
      if (res.data.code === 200) {
        localStorage.setItem('token', res.data.data.token);
        localStorage.setItem('is_new_token', "1");
        dispatch(loginStatus(res.data.code));
        dispatch(loginSuccess(res.data.data));
      } else {
        dispatch(loginFailure(res.data.data));
      }
    });
  };
}

export function handlerCollect() {
  return async (dispatch) => {
    auth.collect().then((res) => {
      if (res.status === 'ok') {
        dispatch(setInfoBalance('0.00'));
      }
    });
  };
}

export function handlerRemoteIn(credits) {
  return async (dispatch) => {
    auth.remoteIn(credits).then((res) => {
      if (res.status === 'ok') {
        dispatch(setInfoBalance(res.balance));
        dispatch(setPollSum('0.00'));
      }
    });
  };
}

export const {
  login,
  getInfoFailure,
  getInfoIsLoading,
  getInfoSuccess,
  loginFailure,
  loginSuccess,
  loginIsLoading,
  loginStatus,
  getRecordsFailure,
  getRecordsIsLoading,
  getRecordsSuccess,
  setInfoBalance,
  setPollSum,
  setInfoDenom,
  setHighscore,
  сhangeAuthInfoJackpots,
  setTemplateId,
} = slice.actions;

export const authSelector = (state) => state.auth;

export default slice.reducer;
