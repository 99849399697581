import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import dayjs from 'dayjs';

import {ReactComponent as ArrowDown} from 'assets/icons/arrow-down-white.svg';

import {
  TableContainer,
  TableHeading,
  TableWrapper,
  TableHeader,
  TableHeaderNumber,
  TableHeaderPlayer,
  TableHeaderPoints,
  TableHeaderDate,
  TableItem,
  TableItemNumber,
  TableItemPlayer,
  TableItemDate,
  TableItemPoints,
  UserIcon,
  MobileFilterAction,
  MoblieFilterText,
} from './styles';

import {FlexBox} from 'components/Common/FlexBox';
import {getRecords, recordsSelector} from 'redux/recordsReducer';

const TableBlock = ({points, date, toggleShowBlock, selectedGame}) => {
  const dispatch = useDispatch();
  const {items: records} = useSelector(recordsSelector);

  React.useEffect(() => {
    dispatch(getRecords(selectedGame));
  }, [dispatch, selectedGame]);

  const filteredByPoints = () =>
    records.filter((record) => points <= Number(record.points));

  return (
    <TableWrapper>
      <MobileFilterAction onClick={toggleShowBlock}>
        <MoblieFilterText>Фильтровать по</MoblieFilterText>
        <ArrowDown className="arrowdownfilter" />
      </MobileFilterAction>
      <TableHeading>{selectedGame.title || 'Game Name'}</TableHeading>
      <TableContainer>
        <TableHeader>
          <TableHeaderNumber>#</TableHeaderNumber>
          <TableHeaderPlayer>Имя игрока</TableHeaderPlayer>
          <TableHeaderPoints>Очки</TableHeaderPoints>
          <TableHeaderDate>Дата</TableHeaderDate>
        </TableHeader>
        {selectedGame &&
          records.length > 0 &&
          filteredByPoints().map((item, index) => (
            <TableItem key={item.id}>
              <TableItemNumber>{index + 1}</TableItemNumber>
              <FlexBox align="center" flex="50%">
                <UserIcon>
                  <img src={item.icon} alt={item.name} />
                </UserIcon>
                <TableItemPlayer>{item.name}</TableItemPlayer>
              </FlexBox>
              <TableItemPoints>{item.points}</TableItemPoints>
              <TableItemDate>
                {item.updated_at && dayjs(item.updated_at).format('DD.MM.YYYY')}
              </TableItemDate>
            </TableItem>
          ))}
      </TableContainer>
    </TableWrapper>
  );
};

export default TableBlock;
