import React from 'react';
import {PageWrapper} from 'components/Common/PageWrapper/index';
import GameGenresBlock from 'components/HomePage/GameGenresBlock';
import RecordsBlock from 'components/Common/RecordsBlock';
import {MainContentWrapper} from 'components/Common/MainContentWrapper/index';
import GamesContainer from 'components/HomePage/GamesContainer';
import Header from 'components/Header';
import Footer from 'components/Footer';

const Home = ({pusherClient}) => {
  return (
    <>
      <Header pusherClient={pusherClient} />
      <PageWrapper margin="30px 0 0 0">
        <MainContentWrapper>
          <GameGenresBlock />
          <GamesContainer />
        </MainContentWrapper>
        <RecordsBlock />
      </PageWrapper>
      <Footer />
    </>
  );
};

export default Home;
