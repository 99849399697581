import React from 'react';

import HandPay from 'components/AuditMenu/HandPay';



const Collect = () => {
  return <HandPay/>;
};

export default Collect;
