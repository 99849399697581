import React from 'react';
import {RulesContainer, Heading, Subheading, Text, Button} from './styles';

const Rules = ({hideRules}) => {
  return (
    <RulesContainer>
      <Heading>Правила</Heading>
      <Subheading>Правила использования развлекательного терминала</Subheading>
      <Text>
        <p>Развлекательный терминал - аппаратно-программный комплекс, обеспечивающий приём платежей за оказание услуг развлекаткльного характера от пользователя в режиме самообслуживания.</p>
        <p>Развлекательный терминал является автономным средством по оказанию услуг и приёму платежей в автоматическом режиме.</p>
        <p>Развлекательный терминал предназначен для:</p>
        <ol>
          <li>приёма платежей за услуги развлекательного характера</li>
          <li>пополнения очков развлекательной системы</li>
        </ol>
        <p>С помощью экранного меню развлекаткльного терминала пользователь пополняет быланс очков путём принятия публичного договора оферты и внесения денежных средств и выбирает услугу, которую он хотел бы получитью</p>
        <p>Следуя инструкциям, выведенным на экране, осуществляется процесс получения развлекательной услуги.</p>
        <p>Развлекательные услуги не содержат в себе компонента выигрыша или получения каких-либо материальных выгод взамен использования услуг, предоставляемых терминалом.</p>
        <p>В качестве мотивирующего результата оказанной услуги каждому пользователю системы присваивается результат, отоюражающий уровень его мастерства и сноровки при достижении целей развлекательного процесса, предоставляемого терминалом</p>
      </Text>
      <Button onClick={hideRules}>Закрыть</Button>
    </RulesContainer>
  );
};

export default Rules;
