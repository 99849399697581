import styled from 'styled-components';
import mainHeader from 'assets/images/mainheader.png';
import mainHeader16_10 from 'assets/images/16_10/header_bg_16_10.jpg';
import {resolutions} from '../../../../../theme/utils';

const MainHeader = styled.header`
  width: 100%;
  height: 6.47vh;
  display: flex;
  /* margin-bottom: 13vh; */
  background: ${`url(${mainHeader})`};
  background-size: cover;
  background-position: center center;
  justify-content: center;
  margin-bottom: 15vh;
  .left-part {
    margin-right: ${(props) => (props.isSocialPoll ? '1.5vh' : '9.5vh')};
    margin-left: 0;
  }
  .right-part {
    margin-right: 2vh;
  }

  .main-header__center-part {
    display: flex;
    justify-content: center;
  }
  .main-header__part {
    display: flex;
    margin-left: ${(props) => (props.isSocialPoll ? '-3vh' : '4.5vh')};
  }

  @media (max-width: 900px) {
    display: none;
  }

  .main-header__content-wrapper {
    display: flex;
  }
  .block {
    display: block;
  }

  .message {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .mobilewrapper {
    display: none;
  }
  ${resolutions.resolution_16_10} {
    background: ${`url(${mainHeader16_10})`};
    height: 6vh;
    background-repeat: no-repeat;
  }
  ${resolutions.resolution_4_3} {
    height: 5.2vh;
    .left-part {
      margin-right: 0;
    }
    .right-part {
      margin-right: 0;
    }
  }
  @media (max-width: 1280px) {
    height: 7.47vh;
    margin-bottom: 8vh;
    .left-part {
      margin-right: ${(props) => (!props.isSocialPoll ? '5vh' : 0)};
    }
  }
`;

const Jackpot = styled.div`
  width: 17.6vh;
  position: relative;
  .jackpot-bank {
    margin-top: 2vh;
  }
  ${resolutions.resolution_4_3} {
    width: 15vh;
  }

  @media (max-width: 1280px) {
    width: 14vh;
  }
`;

const Img = styled.img`
  width: 100%;
  margin-top: -15%;

  @media (max-width: 725px) {
    display: none;
  }
`;

const JackpotText = styled.div`
  position: absolute;
  font-size: 2.03vh;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  text-align: center;
  max-width: 8.54vh;
  left: 25%;
  top: 0;
  ${resolutions.resolution_4_3} {
    font-size: 1.6vh;
    left: 22%;
  }
  .space {
    color: rgba(0, 0, 0, 0);
  }
  @media (max-width: 1280px) {
    font-size: 1.6vh;
    left: 19.5%;
  }
`;

const RulesText = styled.span`
  font-size: 2vh;
  color: #5a3f1c;
  font-weight: bold;
  font-style: italic;
  margin-top: 1.8vh;
  cursor: pointer;
  margin-right: 1vh;
  transition: all 0.3s ease;
  ${resolutions.resolution_16_10} {
    display: none;
  }
  ${resolutions.resolution_4_3} {
    display: none;
  }

  @media (min-width: 1280px) {
    &:hover {
      color: #fff;
    }
  }
`;
const OfertaText = styled.span`
  font-size: 2vh;
  color: #5a3f1c;
  font-weight: bold;
  font-style: italic;
  margin-top: 1.8vh;
  cursor: pointer;
  transition: all 0.3s ease;
  ${resolutions.resolution_4_3} {
    display: none;
  }
  ${resolutions.resolution_16_10} {
    display: none;
  }
  @media (min-width: 1280px) {
    &:hover {
      color: #fff;
    }
  }
`;
const PointsText = styled.span`
  color: #5a3f1c;
  margin-right: 2vh;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
  font-size: 3vh;
  margin-top: 1.5vh;
  ${resolutions.resolution_4_3} {
    font-size: 1.3em;
    margin-top: 1.5vh;
    margin-left: 2vh;
  }
  ${resolutions.resolution_800_1024} {
    margin-top: 1vh;
  }
  ${resolutions.resolution_16_10} {
    margin-top: 2.1vh;
    margin-left: 1vh;
  }
  @media (max-width: 1280px) {
    font-size: 2.5vh;
  }
`;

const Logo = styled.div`
  width: 22.5vh;
  /* cursor: pointer; */
  /*align-self: end;*/
  ${resolutions.resolution_4_3} {
    width: 19.5vh;
    margin-left: 4vh;
  }
  img {
    width: 100%;
    margin-top: -10%;
  }

  @media (max-width: 1280px) and (max-height: 1024px) {
    width: 17vh;
  }
`;

const DiamondJackpot = styled.div`
  width: 30vh;
  position: relative;
  margin-right: -4vh;
  ${resolutions.resolution_4_3} {
    width: 23vh;
  }

  img {
    width: 100%;
    margin-top: -13%;
  }
  @media (max-width: 1280px) {
    width: 23vh;
  }
`;

const DiamondText = styled.div`
  position: absolute;
  font-size: 2.1vh;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  text-align: center;
  left: 32.8%;
  top: 0;
  .jackpot-bank {
    margin-top: 2vh;
  }
  ${resolutions.resolution_4_3} {
    font-size: 1.8vh;
  }

  .space {
    color: rgba(0, 0, 0, 0);
  }
  @media (max-width: 1280px) {
    font-size: 1.8vh;
  }
  @media (max-width: 1980px) and (max-height: 1024px) {
    left: 33%;
  }
`;

const Points = styled.div`
  width: 22.6vh;
  height: 4vh;
  margin-top: 0.9vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  img {
    width: 120%;
    height: 105%;
  }
  ${resolutions.resolution_4_3} {
    width: 10vw;
    margin-top: 0.7vh;
  }
  @media (max-width: 1280px) {
    width: 9vw;
    align-items: center;
  }
`;

const PointsDigits = styled.span`
  position: absolute;
  margin-top: 0.8vh;
  font-size: 3.2vh;
  color: #5a3f1c;
  font-style: italic;
  font-weight: bold;
  ${resolutions.resolution_4_3} {
    font-size: 2.47vh;
    margin-top: 0.9vh;
  }
  ${resolutions.resolution_800_1024} {
    margin-top: 0.2vh;
  }
  ${resolutions.resolution_16_10} {
    font-size: 2.6875vh;
    margin-top: 1vh;
  }
  @media (max-width: 1280px) and (max-height: 1024px) {
    font-size: 2.5vh;
    margin-top: 0;
  }
`;

export {
  MainHeader,
  Jackpot,
  Img,
  JackpotText,
  RulesText,
  OfertaText,
  Logo,
  DiamondJackpot,
  DiamondText,
  PointsText,
  Points,
  PointsDigits,
};
