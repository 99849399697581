import {API} from 'settings';
import axios from 'axios';

const fetchPolls = async () => {
  const URI = `${API}/v1/political-polls?expand=content,pollOptions&sort=-id&per-page=1&page=1`;
  const query = {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};
const fetchPollQuestion = async (answerId) => {
  const URI = `${API}/v1/political-polls/vote/${answerId}`;
  const query = {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const fetchCurrentPoll = async () => {
  const URI = `v1/view-poll`;
  return await axios.get(URI);
};

// /v1/poll-create +
// /v1/poll-view +
// /v1/select-answer
// /v1/finish +

const fetchNewPoll = async () => {
  const URI = `v1/create-poll`;
  return await axios.get(URI);
};

const fetchFinishPoll = async () => {
  const URI = `v1/finish-poll`;
  return await axios.get(URI);
};

const fetchAnsweredQuestion = async ({questionId, answerId}) => {
  const URI = `v1/select-answer?question_id=${questionId}&answer_id=${answerId} `;
  return await axios.get(URI);
};

export default {
  fetchPolls,
  fetchPollQuestion,
  fetchCurrentPoll,
  fetchAnsweredQuestion,
  fetchNewPoll,
  fetchFinishPoll,
};
