import React from 'react';
import Select, {components} from 'react-select';
import styled from 'styled-components';

import arrowDownIcon from 'assets/icons/shevron-down.svg';
import arrowUpIcon from 'assets/icons/shevron-up.svg';
import {theme} from 'theme/theme';

const defaultList = [];

const SelectIcon = styled.div`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 6px;
  }
  img {
    width: 100%;
  }
`;

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SelectIcon>
          <img
            src={props.selectProps.menuIsOpen ? arrowUpIcon : arrowDownIcon}
            alt="select option"
          />
        </SelectIcon>
      </components.DropdownIndicator>
    )
  );
};

const ReactSelect = (props) => {
  const addDefaultValue = () => {
    if (props.defaultValue) {
      if (props.custom) {
        return {label: props.options[0].label, value: props.options[0].value};
      }
      return {label: defaultList[0].label, value: defaultList[0].value};
    } else {
      return null;
    }
  };
  const customStyles = {
    container: (base, state) => ({
      ...base,
      // height: '100%',
      width: '100%',
      zIndex: 2,
      color: '#0E9AFC',
      fontSize: '18px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: props.filter ? 'Roboto' : 'inherit',
    }),
    placeholder: (base, state) => ({
      ...base,
      fontWeight: 'normal',
      top: '56%',
      color: props.filter ? 'rgba(255,255,255,0.5)' : '#0E9AFC',
      fontSize: props.filter ? '16px' : '18px',
      lineHeight: props.filter ? '19px' : '22px',
      fontFamily: 'Roboto, sans-serif',
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      display: 'none',
    }),

    singleValue: (base, state) => ({
      ...base,
      top: '56%',
      color: props.filter ? 'white' : '#0E9AFC',
    }),
    input: (base, state) => ({
      ...base,
      color: '#0E9AFC',
      width: '100%',
    }),
    noOptionsMessage: (base, state) => ({
      ...base,
      color: '#0E9AFC',
    }),

    control: (base, state) => ({
      ...base,
      background: props.filter ? 'rgba(0,0,0,0)' : '#000a24',
      height: '100%',
      cursor: 'pointer',
      width: '100%',
      borderRadius: 0,
      boxShadow: props.filter && state.isFocused && theme.blockShadow,
      border: props.filter ? '1px solid #0E9AFC' : 'none',

      '&:hover': {
        border: props.filter ? '1px solid #0E9AFC' : 'none',
      },
    }),
    option: (base, state) => ({
      ...base,
      background: state.isFocused || state.isSelected ? '#000a24' : '#000a24',
      color: '#0E9AFC',
      border: 'none',
      height: '100%',
      padding: '10px 18px',
      ':active': {
        backgroundColor: '#000a24',
      },
      textShadow:
        state.isFocused || state.isSelected
          ? 'rgba(14, 154, 252, 0.8) 1px 0 6px'
          : 'none',
      '&:last-of-type': {
        borderBottom: 'none',
      },
      '&:hover': {
        textShadow:
          state.isFocused || state.isSelected
            ? 'rgba(14, 154, 252, 0.8) 1px 0 6px'
            : 'none',
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      background: props.filter ? 'rgba(0,0,0,0)' : '#000a24',
      alignItems: 'center',
    }),
    menuList: (base, state) => ({
      ...base,
      background: '#000a24',
      paddingTop: '0px',
      paddingBottom: '0px',
      '::-webkit-scrollbar': {
        width: '3px',
      },
      '::-webkit-scrollbar-track': {
        background: '#000a24',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#0E9AFC',
        borderRadius: '50px',
      },
      overflowY: 'scroll',
      height: 'auto',
      minHeight: '635px',
      '@media(max-width:670px)': {
        minHeight: '100px',
      },
    }),
    menu: (base, state) => ({
      ...base,
      overflowX: 'hidden',
      overflowY: 'scroll',
      boxShadow: 'none',
      height: 'auto',
      width: '100%',
      borderRadius: 'none',
      marginTop: 0,
      paddingRight: '4px',
      paddingTop: '4px',
      paddingBottom: '4px',
      background: '#000a24',
      scrollbarWidth: 'none',
      border: '2px solid #0E9AFC',
      '::-webkit-scrollbar': {
        width: '0px',
      },
    }),
  };
  return (
    <Select
      {...props}
      value={props.value}
      onChange={props.onChange}
      options={props.custom ? props.options : defaultList}
      placeholder={props.placeholder}
      styles={customStyles}
      defaultValue={addDefaultValue()}
      isMulti={false}
      components={{DropdownIndicator}}
    />
  );
};

export default ReactSelect;
