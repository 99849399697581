import React from 'react';

import {Overlay, ModalFrame} from './styles';

import {useLockBodyScroll} from 'hooks/useLockBodyScroll';

const Modal = ({children, bg}) => {
  useLockBodyScroll();
  return (
    <Overlay>
      <ModalFrame bg={bg}>
        {children}
      </ModalFrame>
    </Overlay>
  );
};

export default Modal;
