import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const HoverItemButton = styled.button`
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  position: absolute;
  width: 117px;
  height: 35px;
  background: rgba(14, 154, 252, 0.5);
  border: 1px solid ${(props) => props.theme.colors.mainBlue};
  box-shadow: none;
  transition: all 0.3s ease;
  font-family: Roboto, sans-serif;

  ${media.tabletLandscape`
    width: 90%;
  `}

  &:focus {
    outline: none;
  }
`;

const GameItemContainer = styled.div`
  width: 183px;
  height: 120px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  margin-bottom: 18px;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0);

  ${media.tabletLandscape`
    width: 152px;
    height: 99px;
  `}
  ${media.tabletLandscape`
    width: 109px;
    height: 71px;
  `}

  ${media.phoneLandscape`
    width: 141px;
    height: 100px;
  `}

  ${media.phone`
    width: 156px;
    height: 102px;
  `}

  &:hover {
    box-shadow: ${(props) => props.theme.blockShadow};
    border: 2px solid ${(props) => props.theme.colors.mainBlue};

    ${HoverItemButton} {
      opacity: 1;
      visibility: visible;
      box-shadow: ${(props) => props.theme.blockShadow};
    }
  }
`;

export {GameItemContainer, HoverItemButton};
