import React from 'react';
import {
  TableItemContainer,
  Place,
  Points,
  Name,
  NameAndPointsWrapper,
} from './styles';

const TableItem = ({item: {id, name, points}, place}) => {
  return (
    <TableItemContainer>
      <Place>{place}</Place>
      <NameAndPointsWrapper>
        <Name>{name}</Name>
        <Points>{points}</Points>
      </NameAndPointsWrapper>
    </TableItemContainer>
  );
};

export default TableItem;
