import styled from 'styled-components';

export const TestingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 1em;

  .underline {
    text-decoration: underline;
  }

  h3 {
      color: #492952;
      margin: 1em 1em;
      font-weight: 500;
  }

  .green {
    color: #1bde7a;
  }

  .red {
    color: #db0000;
  }
`;

export const Questions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  h4 {
      color: #000;
      margin: 1em 0;
      font-weight: 500;
  }

  span {
      color: #000;
      font-weight: 300;
      font-size: .8em;
  }
`;