import styled from 'styled-components';

export const UserInfoWrapper = styled.div`
  color: #4a2952;
  h3 {
    color: #4a2952;
    margin: 1em 2em;
  }
`;

export const AccountCard = styled.div`
  width: 12em;
  height: 13em;
  border-radius: 10px;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1em;
  padding: 10px;
  z-index: 2;

  .info {
    display: flex;
    font-size: 0.7em;
  }

  .info > b {
    margin: 0 0.5em;
  }

  .scores > span {
    font-size: 0.8em;
  }

  .scores > b {
    margin: 0 1em;
  }

  .level {
    font-weight: 500;
    font-size: 0.9em;
  }

  .progress {
    font-size: 0.8em;
  }

  .next {
    font-size: 0.8em;
  }

  .lesson {
    cursor: pointer;
    font-size: .9em;
    font-weight: 600;
    text-decoration: underline;
    margin: .7em 0;

  }
`;
