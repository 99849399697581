import {createSlice} from '@reduxjs/toolkit';
import {news} from 'services';

export const slice = createSlice({
  name: 'news',
  initialState: {
    items: [],
    loading: false,
    error: null,
    politicalNews: {
      item: null,
      error: null,
      loading: false,
    },
    singleNewsBlock: {
      item: null,
      error: null,
      loading: false,
    },
    nextOrPrev: {
      item: null,
      error: null,
      loading: false,
    },
  },
  reducers: {
    getNewsIsLoading: (state) => {
      state.loading = true;
    },
    getNewsSuccess: (state, {payload}) => {
      state.items = payload;
      state.loading = false;
      state.error = null;
    },
    getNewsFailure: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
    getPoliticalNewsIsLoading: (state) => {
      state.politicalNews.loading = true;
    },
    getPoliticalNewsSuccess: (state, {payload}) => {
      state.politicalNews.item = payload;
      state.politicalNews.loading = false;
      state.politicalNews.error = null;
    },
    getPoliticalNewsFailure: (state, {payload}) => {
      state.politicalNews.loading = false;
      state.politicalNews.error = payload;
    },
    getSingleNewsBlockIsLoading: (state) => {
      state.singleNewsBlock.loading = true;
    },
    getSingleNewsBlockSuccess: (state, {payload}) => {
      state.singleNewsBlock.item = payload;
      state.singleNewsBlock.loading = false;
      state.singleNewsBlock.error = null;
    },
    getSingleNewsBlockFailure: (state, {payload}) => {
      state.singleNewsBlock.loading = false;
      state.singleNewsBlock.error = payload;
    },
    getNextOrPrevIsLoading: (state) => {
      state.nextOrPrev.loading = true;
    },
    getNextOrPrevSuccess: (state, {payload}) => {
      state.nextOrPrev.item = payload;
      state.nextOrPrev.loading = false;
      state.nextOrPrev.error = null;
    },
    getNextOrPrevFailure: (state, {payload}) => {
      state.nextOrPrev.loading = false;
      state.nextOrPrev.error = payload;
    },
  },
});

export function getNews() {
  return async (dispatch) => {
    dispatch(getNewsIsLoading());

    try {
      const response = await news.fetchNews();
      dispatch(getNewsSuccess(response.data));
    } catch (error) {
      dispatch(getNewsFailure(error.message));
    }
  };
}
export function getPoliticalNews() {
  return async (dispatch) => {
    dispatch(getPoliticalNewsIsLoading());

    try {
      const response = await news.fetchPoliticalNews();
      dispatch(getPoliticalNewsSuccess(response.data));
    } catch (error) {
      dispatch(getPoliticalNewsFailure(error.message));
    }
  };
}
export function getNextOrPrevNews(pageNumber) {
  return async (dispatch) => {
    dispatch(getNextOrPrevIsLoading());

    try {
      const response = await news.fetchNextOrPrev(pageNumber);
      dispatch(getNextOrPrevSuccess(response.data));
    } catch (error) {
      dispatch(getNextOrPrevFailure(error.message));
    }
  };
}

export function getSingleNewsBlock(id) {
  return async (dispatch) => {
    dispatch(getSingleNewsBlockIsLoading());

    try {
      const response = await news.fetchSingleNewsBlock(id);
      dispatch(getSingleNewsBlockSuccess(response.data));
    } catch (error) {
      dispatch(getSingleNewsBlockFailure(error.message));
    }
  };
}

export const {
  getNewsIsLoading,
  getNewsSuccess,
  getNewsFailure,
  getPoliticalNewsFailure,
  getPoliticalNewsSuccess,
  getPoliticalNewsIsLoading,
  getSingleNewsBlockFailure,
  getSingleNewsBlockSuccess,
  getSingleNewsBlockIsLoading,
  getNextOrPrevFailure,
  getNextOrPrevIsLoading,
  getNextOrPrevSuccess,
} = slice.actions;

export const newsSelector = (state) => state.news;

export default slice.reducer;
