import {createSlice} from '@reduxjs/toolkit';
import {genres} from 'services';

export const slice = createSlice({
  name: 'genres',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {
    getGenresIsLoading: (state) => {
      state.loading = true;
    },
    getGenresSuccess: (state, {payload}) => {
      const sortedAlphabetically = payload.sort((a, b) =>
        a.title === b.title ? 0 : a.title < b.title ? -1 : 1
      );
      state.items = sortedAlphabetically;
      state.loading = false;
      state.error = null;
    },
    getGenresFailure: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export function getGenres() {
  return async (dispatch) => {
    dispatch(getGenresIsLoading());

    try {
      const response = await genres.fetchGenres();
      dispatch(getGenresSuccess(response.data));
    } catch (error) {
      dispatch(getGenresFailure(error.message));
    }
  };
}

export const {
  getGenresIsLoading,
  getGenresSuccess,
  getGenresFailure,
} = slice.actions;

export const genresSelector = (state) => state.genres;

export default slice.reducer;
