import styled from 'styled-components';

import backgroundImg from 'assets/images/header-background.png';
import {media} from 'utils/mediaQueries';

const HeaderWrapper = styled.header`
  margin: 0 auto;
  background: linear-gradient(
      180deg,
      rgba(4, 17, 59, 0.8) 19.72%,
      #000a24 93.75%
    ),
    ${`url(${backgroundImg})`};
  background-size: cover;
  height: 144px;
  width: 100%;
  display: flex;

  ${media.tabletLandscape`
    padding: 0 16px;
  `}
  ${media.tablet`
    padding: 0 20px;
  `}

  ${media.phoneLandscape`
    height: auto;
    padding: 5px 20px;
    border-bottom: 1px solid ${(props) => props.theme.colors.mainBlue};
  `}
`;

const HeaderContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;

  .mobilemenu {
    display: none;
    position: absolute;
    top: 10px;
    right: 0;

    ${media.phoneLandscape`
      display: block;
  `}
  }
`;

export {HeaderWrapper, HeaderContainer};
