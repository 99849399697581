import styled from 'styled-components';

const RecordTableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.theme.colors.mainBlue};
  padding: 20px;
  background: linear-gradient(
      180deg,
      rgba(14, 154, 252, 0.3) 0%,
      rgba(14, 154, 252, 0) 100%
    ),
    #000a24;
`;
export {RecordTableContainer};
