import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const FiltersBlockContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.phoneLandscape`
    display: none;
  `}
`;

const Label = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.mainBlue};
  margin-bottom: 10px;
  margin-top: 15px;

  &:first-of-type {
    ${media.phoneLandscape`
    margin-top: 5px;
  `}
  }
`;

const TextInputWrapper = styled.div`
  width: 100%;
  position: relative;
  svg {
    fill: ${(props) => props.theme.colors.mainBlue};
  }
  .closeIcon {
    position: absolute;
    right: 3px;
    top: 6px;
  }
`;

const ResetFilterButton = styled.button`
  background: rgba(0, 0, 0, 0);
  width: 195px;
  height: 35px;
  border: 1px solid ${(props) => props.theme.colors.mainBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: ${(props) => props.theme.colors.mainBlue};
  font-size: ${(props) => props.theme.fontSizes.text};
  line-height: ${(props) => props.theme.lineHeights.text};
  cursor: pointer;
  margin-top: 40px;

  &:focus {
    outline: none;
  }

  &:hover {
    border: 2px solid ${(props) => props.theme.colors.mainBlue};
    box-shadow: ${(props) => props.theme.blockShadow};
  }

  ${media.tabletLandscape`
    background: rgba(14, 154, 252, 0.5);
    color: ${(props) => props.theme.colors.white};
  `}
`;

export {FiltersBlockContainer, Label, TextInputWrapper, ResetFilterButton};
