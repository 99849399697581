import React from 'react';
import {useSelector} from 'react-redux';
import {Box, Container} from '@material-ui/core';

import {LearnersWrapper, Title, TitleTab, LogoBg, ScrollWrap} from './styles';

import Testing from './Testing';
import ChoiseDirection from './Directions';
import Modal from 'components/AuthGames/Modal';

import frame from 'assets/images/modal.png';
import {CloseButton} from 'components/Account/styles';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {useShowBlock} from 'hooks/useShowBlock';
import {AnswerButton} from 'components/AuthGames/AuthPageContainer/Poll/styles';
import {authSelector} from 'redux/authReducer';

const Learners = ({hideTeory}) => {
  const {info} = useSelector(authSelector);
  const [tabSelected, setTabSelected] = React.useState(0);
  const [direction, setDirection] = React.useState(null);

  const getTab = (id) => {
    switch (id) {
      case 0:
        return (
          <ChoiseDirection
            direction={direction}
            setDirection={setDirection}
            setTabSelected={setTabSelected}
          />
        );
      case 1:
        return (
          <Testing
            setDirection={setDirection}
            setTabSelected={setTabSelected}
            direction={direction}
            hideTeory={hideTeory}
            showCongratulations={showCongratulations}
          />
        );
      default:
        break;
    }
  };
  const ref = React.createRef();

  React.useEffect(() => {
    console.log(ref);
    ref.current.scrollIntoView();
  }, [ref]);

  const {
    showBlock: showCongratulations,
    isBlockShown: isCongratulationsShown,
    hideBlock: hideCongratulations,
  } = useShowBlock();

  const isTeoryComplete =
    !!info?.is_school &&
    info?.school !== null &&
    !!info?.school?.filter((i) => i.status === 3).length;

  return (
    <Container fixed>
      {!isTeoryComplete && isCongratulationsShown && (
        <Modal bg={frame}>
          {' '}
          <Box
            style={{
              display: 'flex',
              alignContent: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '80%',
            }}
          >
            <h3
              style={{fontSize: '2.8em', lineHeight: '2', textAlign: 'center'}}
            >
              Поздравляем!
            </h3>
            <span
              style={{
                fontSize: '2em',
                lineHeight: '1',
                textAlign: 'center',
                marginBottom: '3em',
              }}
            >
              Вы прошли тестирование, пора приступить к практике
            </span>
            <AnswerButton
              onClick={hideCongratulations}
              style={{width: 'max-content'}}
            >
              Закрыть
            </AnswerButton>
          </Box>
        </Modal>
      )}

      <LearnersWrapper id="learners">
        <LogoBg left />
        <LogoBg right />

        <ScrollWrap>
          {' '}
          <Box
            style={{width: '100%'}}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Title ref={ref}>
              <TitleTab active={tabSelected === 0}>
                <span>Теория</span>
              </TitleTab>
              <TitleTab active={tabSelected === 1}>
                <span>Тестирование</span>
              </TitleTab>
            </Title>
            {isTeoryComplete && (
              <CloseButton onClick={hideTeory}>
                <HighlightOffIcon style={{color: '#777', cursor: 'pointer'}} />
              </CloseButton>
            )}
          </Box>
          {getTab(tabSelected)}
        </ScrollWrap>
      </LearnersWrapper>
    </Container>
  );
};

export default Learners;
