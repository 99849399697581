import styled from 'styled-components';
import {media} from 'utils/mediaQueries';

const TableContainer = styled.div`
  width: 100%;
  border: 2px solid ${(props) => props.theme.colors.mainBlue};

  background: linear-gradient(
      180deg,
      rgba(14, 154, 252, 0.3) 0%,
      rgba(14, 154, 252, 0) 100%
    ),
    #000a24;
  height: 100%;
  margin-top: 22px;
  padding: 25px;
`;

const TableHeading = styled.span`
  display: inline-block;
  font-size: 30px;
  line-height: 36px;
  color: ${(props) => props.theme.colors.mainBlue};
  margin-top: -9px;

  ${media.phoneLandscape`
    margin-left: 0;
    font-size: 20px;
    line-height: 24px;
  `}
`;

const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
  position: relative;

  ${media.phone`
    margin-left: 0;
  `}
`;

const TableHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.mainBlue};
  padding-bottom: 20px;
  box-shadow: 0px 4px 3px -3px rgba(14, 154, 252, 0.4);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TableItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
`;

const TableItemNumber = styled.span`
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.colors.mainBlue};
  flex: 10%;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-weight: 300;
`;
const TableItemPlayer = styled.span`
  flex: 50%;
  color: ${(props) => props.theme.colors.white};
  font-size: 16px;
  line-height: 19px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
`;
const TableItemPoints = styled.span`
  color: ${(props) => props.theme.colors.white};
  font-size: 16px;
  line-height: 19px;
  flex: 20%;
  font-family: Roboto, sans-serif;
  font-weight: 300;
`;
const TableItemDate = styled.span`
  color: ${(props) => props.theme.colors.white};
  font-size: 16px;
  line-height: 19px;
  flex: 20%;
  font-family: Roboto, sans-serif;
  font-weight: 300;
`;
const TableNumber = styled.span`
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.colors.mainBlue};
`;
const TableHeaderNumber = styled.span`
  flex: 10%;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.mainBlue};
  text-align: center;
  margin-right: 2%;
`;
const TableHeaderPlayer = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.mainBlue};
  flex: 50%;

  ${media.phone`
    flex: 40%;
  `}
`;
const TableHeaderPoints = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.mainBlue};
  flex: 20%;
  margin-left: -3%;
`;
const TableHeaderDate = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.mainBlue};
  flex: 20%;
`;

const UserIcon = styled.div`
  width: 30px;
  height: 30px;
  margin: 0 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const MobileFilterAction = styled.div`
  display: none;
  position: absolute;

  align-items: center;
  top: -5px;
  left: 40%;

  .arrowdownfilter {
    margin-top: 5px;
    margin-left: 9px;
  }

  ${media.phoneLandscape`
      display: flex;
  		margin-bottom: 10px;
  `}
  ${media.phone`
      right:0;
      left:unset;
  `}
`;

const MoblieFilterText = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.white};
`;

export {
  TableContainer,
  TableHeading,
  TableWrapper,
  TableHeader,
  TableNumber,
  TableHeaderNumber,
  TableHeaderPlayer,
  TableHeaderPoints,
  TableHeaderDate,
  TableItemNumber,
  TableItemPlayer,
  TableItemPoints,
  TableItemDate,
  TableItem,
  UserIcon,
  MobileFilterAction,
  MoblieFilterText,
};
