import {Box} from '@material-ui/core';
import React from 'react';

const Сroupier = ({setTabSelected, setDirection}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
    >
      <span className="direction-title">
        <span className="outline">Выбор направления</span> - <b>Крупье</b>
      </span>
      <p>
        В покере карты раздаются людьми в специально созданных для этого
        заведениях. За каждым столом, кроме игроков, присутствует крупье или
        дилер, в их обязанности входит: раздача карт, контроль игры. Если мы
        говорим об онлайн версии, то карты раздаются специальным покерным
        приложением. По сути крупье и дилер – синонимы, но у них есть небольшое
        отличие. Крупье просто раздает карты, а дилер имеет право играть в
        покер. Рассмотрим подробнее эти понятия.
      </p>
      <p>
        <b>Что такое дилер и крупье в покере</b>
      </p>
      <p>
        Крупье – это штатный сотрудник покерного клуба, чья первостепенная
        функция раздавать карты. Участия в игре он не принимает. Его основные
        задачи:
      </p>
      <p>
        - вежливое, точное и быстрое обслуживание покеристов
        <br />
        - поддерживать темп за столом <br />
        - следить за соблюдением правил
        <br />
        - управлять ставками и собирать рейк покер-рума
        <br />
      </p>
      <p>
        Дилерский функционал даже чуть шире: он не только может раздавать карты,
        но и играть сам. Не забывая соблюдать установленные правила игры и
        следить за порядком. Как правило, дилер – это один из покеристов,
        которые собрались большой компанией приятно скоротать вечерок. И в
        данном случае это не только термин, но и определенная игровая позиция. А
        в течение одного круга раздачи каждый игрок становится дилером.{' '}
      </p>
      <p>
        Как правило, дилер – это один из покеристов, которые собрались большой
        компанией приятно скоротать вечерок. И в данном случае это не только
        термин, но и определенная игровая позиция. А в течение одного круга
        раздачи каждый игрок становится дилером.{' '}
      </p>
      <p>
        <b>БАТТОН</b>
      </p>
      <p>
        Позиции дилера как таковой в виртуальном покерном клубе нет. Карты
        раздает программа, она априори не ошибается при раздаче, в отличие от
        живого дилера или крупье. Однако фишка дилера (баттон) все же
        перекочевала из офлайн-покера в онлайн.{' '}
      </p>
      <p>
        Баттон – это позиция виртуального дилера, которая находится перед
        позицией игрока на малом блайнде. Все по аналогии с "живым" покером –
        именно там находится дилер, который и раздает карты, и одновременно
        играет сам.
      </p>
      <p>
        Дилерская фишка является ориентиром для остальных покеристов и
        определяет позиции игроков. А тот, кто находится в позиции баттона,
        имеет определенное стратегическое преимущество в раздаче – поскольку
        дилер принимает решение последним. Соответственно, имея при этом
        информации больше, чем любой другой оппонент. И для воровства блайндов,
        позиция баттона идеальная.
      </p>
      <p>
        <b>Особенности работы крупье </b>
      </p>
      <p>
        В казино или покерном клубе, есть свой штатный специалист – крупье. В
        идеале он обязан пройти минимум шестимесячные курсы подготовки, изучив
        теорию и практику всех видов покера, в которые играют в казино или
        покерном клубе. Подтвердив свои знания и умения соответствующим
        сертификатом.
      </p>
      <p>
        Для крупье в заведении высокого уровня обязательно знание нескольких
        языков для эффективной коммуникации с любыми клиентами. Он должен
        обладать минимальными знаниями поведенческих моделей разных психотипов
        покеристов. И релевантным опытом – желательно поработать на крупных
        турнирах мировых покерных серий.
      </p>
      <p>
        <b>ЗАДАЧИ КРУПЬЕ</b>
      </p>
      <p>
        В полном распоряжении крупье находится фишка дилера, равно как и
        карточные колоды для игры. Непосредственные рабочие обязанности крупье –
        используя баттон, он раздает карты покеристам и контролирует дальнейший
        ход раздачи. Также в ведении крупье находится обязанность сбора
        блайндов, формирование и контроль банка, наблюдение за поведением
        покеристов во время игровой сессии и обеспечение соблюдения всех правил
        игры. От крупье может потребоваться коммуникация с покеристами до и
        после игровой сессии, а постфактум и предоставление соответствующих
        отчетов менеджеру клуба.Также дилеры и крупье следят за темпом игры,
        поддерживая и ускоряя при необходимости, и особенно отслеживая
        правильное взимание рейка, согласно установленных тарифов. Их услуги
        обязаны быть высококачественными, не зря же заведение берет за них
        отдельную плату.
      </p>

      <p>
        <b>КАКИМ ДОЛЖЕН БЫТЬ КРУПЬЕ?</b>
      </p>
      <p>
        Крупье в казино или покерном клубе просто обязан иметь высокую степень
        стрессоустойчивости, долгое время сохранять концентрацию внимания, быть
        усидчивым и терпеливым. Должен спокойно воспринимать звуковые и
        зрительные сигналы покеристов, оперативно реагируя на них и обрабатывая
        просьбы и требования игроков. Естественно, у него должна быть солидная
        теоретическая покерная база, развитое логическое мышление и способность
        быстро оперировать цифрами, проводя математические расчеты и сводя
        баланс банка в начале и конце рабочей смены. Немаловажна и мелкая
        моторика, чтобы эффективно работать с картами, фишками и денежными
        средствами победивших игроков и проигравших покеристов. И стопроцентное
        зрение, а также адекватная реакция на шум, колебания температур,
        освещение. И отсутствие аллергии на пыль или табачный дым.
      </p>
      <Box
        style={{width: '100%', marginTop: '2em'}}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <button
          className="test-btn"
          onClick={() => {
            setTabSelected(1);
          }}
        >
          Перейти к тестированию
        </button>
      </Box>
    </Box>
  );
};

export default Сroupier;
