import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import footerLeft from 'assets/images/footerleft.png';
import footerRight from 'assets/images/footerright.png';
import selectProvider from 'assets/images/providers/btn.png';
import selectProviderDisabled from 'assets/images/providers/btn_disabled.png';
import footerArrow from 'assets/images/footerArrow.png';

import frame from 'assets/images/modal.png';
import popup from 'assets/images/popup2.png';

import {
  MainFooterContainer,
  FooterImg,
  FooterLeftText,
  FooterRightText,
  MobileFooterText,
  Message,
} from './styles';

import Modal from 'components/AuthGames/Modal';
import Records from '../../Records';

import Poll from '../../Poll';
import { useForm } from 'hooks/useForm';
import StartPoll from '../../StartPoll';
import { authSelector } from 'redux/authReducer';
import {
  mainContentSelector,
  onChangeSlide,
  setSelectorProvider,
  setSliderCount,
} from 'redux/mainContentReducer';
import { pollsSelector, getNewPoll } from 'redux/pollsReducer';
import { objectLength } from 'utils/objectLength';
import { splitSlidesData } from 'utils/helpers';
import ChangeHotkey from '../../ChangeHotkey';
import { gamePackageSelector } from 'redux/gamePackageReducer';

const MainFooter = ({
  showRecords,
  isRecordsShown,
  hideRecords,
  showPoll,
  isPollShown,
  hidePoll,
  isSocialPoll,
  isRulesShown,
  isOfertaShown,
  isCollectShown,
  showCollect,
  gameMenu,
  setSelectedGames,
  selectedGames,
  isChangeHotkeyShown,
  hideChangeHotkey,
}) => {
  const dispatch = useDispatch();
  const { form, handleInputChange } = useForm({ isAccepted: false });

  const {
    authInfo: { info },
  } = useSelector(authSelector);
  const { items: packages } = useSelector(gamePackageSelector);

  const lang = info.lang.toLowerCase();
  const { sliderCount, selectedProvider } = useSelector(mainContentSelector);
  const sliderElementsSum = 8;
  const games =
    packages &&
    selectedProvider &&
    packages.find((item) => item.package.name === selectedProvider).games;
  const allGames = packages ? (selectedProvider ? games : packages) : [];
  const gamesList = allGames
    ? splitSlidesData(allGames, sliderElementsSum)
    : [];

  const [isPollStarted, setIsPollStarted] = React.useState(false);
  const {
    currentPoll: { data },
    currentQuestion,
  } = useSelector(pollsSelector);

  React.useEffect(() => {
    if (info && info.poll_sum > 0) {
      showPoll();
      setIsPollStarted(true);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (currentQuestion > -1 && data && data.questions) {
      showPoll();
      setIsPollStarted(true);
    }
    // eslint-disable-next-line
  }, [currentQuestion, data]);

  const hotkeys = info && info.hotkeys;
  const handleKeyPress = (e) => {
    const { code } = e;
    const { save_record = [], pass_poll = [], collect = [] } = hotkeys;
    if (!isRulesShown && !isOfertaShown && !isPollShown && !isRecordsShown) {
      //save record
      if (code === save_record[0]) {
        setIsPollStarted(false);
        showRecords();
      }
      //pass poll
      if (code === pass_poll[0]) {
        setIsPollStarted(false);
        showPoll();
      }
      //collect
      if (code === collect[0]) {
        info && info.balance > 0 && showCollect();
      }
    }
  };

  React.useEffect(() => {
    if (hotkeys) {
      document.addEventListener('keydown', handleKeyPress);

      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
    // eslint-disable-next-line
  }, [info, isRulesShown, isOfertaShown, isPollShown, isRecordsShown]);

  React.useEffect(() => {
    if (hotkeys) {
      document.addEventListener('keydown', handleKeyPress);

      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
    // eslint-disable-next-line
  }, [info, isRulesShown, isOfertaShown, isPollShown, isRecordsShown]);

  const highscore = info?.highscore
  React.useEffect(() => {
    highscore > 0 && showPoll()
    // eslint-disable-next-line
  }, [highscore]);

  const nextText = lang === 'tgk' ? 'Ба пеш' : 'Вперед';
  const prevText = lang === 'tgk' ? 'Бозгашт' : 'Назад';
  const chooseText = lang === 'tgk' ? 'ИСТЕҲСОЛКУНАНДАРО ИНТИХОБ КУНЕД' : 'ВЫБРАТЬ ПРОИЗВОДИТЕЛЯ';
  return (
    <>
      {info && (
        <MainFooterContainer>
          {isChangeHotkeyShown && (
            <Modal bg={frame}>
              <ChangeHotkey hideChangeHotkey={hideChangeHotkey} />
            </Modal>
          )}
          {!!isSocialPoll && !!isRecordsShown && (
            <Modal bg={frame}>
              <Records hideRecords={hideRecords} showPoll={showPoll} />
            </Modal>
          )}
          {!!isPollShown && (
            <Modal bg={frame}>
              {isPollStarted ? (
                <Poll hidePoll={hidePoll} />
              ) : (
                <StartPoll
                  startPoll={() => {
                    dispatch(getNewPoll());
                    setIsPollStarted(true);
                  }}
                  checked={form.isAccepted}
                  onChange={handleInputChange}
                  name="isAccepted"
                />
              )}
            </Modal>
          )}
          {!!isCollectShown && (
            <Modal bg={popup}>
              <div>
                <Message>CASH BUTTON PRESSED</Message>
                <br />
                <Message style={{ fontSize: '7.5vh' }}>
                  {info && info.balance} CREDITS
                </Message>
                <br />
                <Message>WAIT FOR ATTENDANT</Message>
              </div>
            </Modal>
          )}
          {window.innerWidth > 900 && (
            <>
              <FooterImg
                onClick={
                  isSocialPoll
                    ? () => {
                      info.balance > 0 && setIsPollStarted(false);
                      info.balance > 0 && showRecords();
                    }
                    : () => dispatch(onChangeSlide(-1, gamesList, sliderCount))
                }
              >
                <img src={footerLeft} alt="" />
                <FooterLeftText
                  disabled={isSocialPoll ? info.balance === '0.00' : false}
                >
                  {isSocialPoll ? (
                    ''
                  ) : (
                    <img
                      style={{
                        transform: 'rotateZ(180deg)',
                        width: '6vh',
                        margin: '0 3vh',
                      }}
                      src={footerArrow}
                      alt="arrowRight"
                    />
                  )}
                  {isSocialPoll ? 'Сохранить рекорд' : prevText}
                </FooterLeftText>
              </FooterImg>
              {objectLength(info && info.game_package_list) !== 1 && (
                <div className="select-provider" style={{ cursor: selectedProvider ? 'pointer' : 'default' }}
                  onClick={() => {
                    dispatch(setSelectorProvider(''));
                    localStorage.removeItem('selected_provider');
                    localStorage.removeItem('slider_page');
                    dispatch(setSliderCount(0));
                  }} >
                  <img src={selectedProvider ? selectProvider : selectProviderDisabled} alt="" style={{ width: '45vw', minWidth: '250px', maxHeight: '8.5vh' }} />
                  <span style={{ opacity: selectedProvider ? '1' : '.5' }}>
                    {chooseText}
                  </span>
                </div>
              )}
              <FooterImg onClick={!!isSocialPoll ? () => { setIsPollStarted(false); showPoll(); } : () => { selectedGames === 4 && setSelectedGames(selectedGames - 4); dispatch(onChangeSlide(1, gamesList, sliderCount)); }} >
                <img src={footerRight} alt="" />
                <FooterRightText>
                  {!!isSocialPoll ? 'Пройти опрос' : nextText}
                  {!!isSocialPoll ? '' : <img style={{ width: '6vh', margin: '0 3vh' }} src={footerArrow} alt="arrowRight" />}
                </FooterRightText>
              </FooterImg>
            </>
          )}
          {window.innerWidth < 900 && (
            <div className="wrapper">
              <MobileFooterText
                disabled={isSocialPoll ? info.balance === '0.00' : allGames.length <= 8}
                onClick={!!isSocialPoll ? () => { setIsPollStarted(false); showRecords(); } : () => dispatch(onChangeSlide(-1, gamesList, sliderCount))} >
                {!!isSocialPoll ? '' : <img src={footerArrow} alt="arrowRight" style={{ transform: 'rotateZ(180deg)', width: '26px', margin: '0 1vh' }} />}
                {!!isSocialPoll ? 'Сохранить рекорд' : prevText}
              </MobileFooterText>
              <MobileFooterText
                disabled={!!isSocialPoll ? false : allGames.length <= 8}
                onClick={!!isSocialPoll ? () => { setIsPollStarted(false); showPoll(); } : () => dispatch(onChangeSlide(1, gamesList, sliderCount))} >
                {!!isSocialPoll ? 'Пройти опрос' : nextText}
                {!!isSocialPoll ? '' : <img src={footerArrow} alt="arrowRight" style={{ width: '26px', margin: '0 1vh' }} />}
              </MobileFooterText>
            </div>
          )}
        </MainFooterContainer>
      )}
    </>
  );
};

export default MainFooter;
