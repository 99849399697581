import React from 'react';

import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';

import {theme as appTheme} from 'theme/theme';

import ShadowText from 'components/Common/ShadowText';
import LoginForm from 'components/Common/LoginForm';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 386,
    height: 500,
    backgroundColor: appTheme.colors.darkBlue,
    border: `2px solid ${appTheme.colors.mainBlue}`,
    boxShadow: appTheme.blockShadow,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    '&:focus': {
      outline: 'none',
    },

    '@media (max-width: 670px)': {
      height: 'unset',
    },
    '@media (max-width: 616px)': {
      height: 501,
      width: '90%',
    },
  },
  closeIcon: {
    alignSelf: 'flex-end',
    margin: '10px 10px 0 0',
    cursor: 'pointer',
  },
  backDrop: {
    background: 'rgba(0, 5, 19, 0.8)',
  },
}));

const LoginModal = ({handleClose, open}) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="login-modal"
      aria-describedby="login-modal"
      open={open}
      onClose={handleClose}
      className={classes.backDrop}
    >
      <div className={`${classes.paper} loginModal`}>
        <CloseIcon
          style={{color: appTheme.colors.mainBlue, fontSize: 40}}
          className={classes.closeIcon}
          onClick={handleClose}
        />
        <ShadowText text="Вход" />
        <LoginForm handleClose={handleClose}/>
      </div>
    </Modal>
  );
};

export default LoginModal;
