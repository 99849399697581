import styled from 'styled-components';

const RecordsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  color: #e1b058;
  margin-top: 3%;

  @media (max-width: 1024px) {
    border: 3px solid #e1b058;
    border-radius: 10px;
    padding: 5%;
    background: #25132e;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  border-bottom: 3px solid #f0e3bf;
`;

const TableHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 100%;
  border-bottom: 3px solid #f0e3bf;

  @media (max-width: 560px) {
    font-size: 24px;
  }
  @media (max-width: 430px) {
    font-size: 14px;
  }

  &:last-of-type {
    border: none;
  }
`;

const TableHeaderCell = styled.div`
  height: 50px;
  width: 50%;
  font-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-of-type {
    border-right: 3px solid #f0e3bf;
  }
  &:nth-of-type(2) {
    border-right: 3px solid #f0e3bf;
  }
`;

const FooterCell = styled.div`
  background: #e1b058;
  color: #2b1c34;
  height: 50px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    border-right: 3px solid #f0e3bf;
  }
  &:nth-of-type(2) {
    border-right: 3px solid #f0e3bf;
  }
`;

export {
  RecordsContainer,
  TableHeaderCell,
  TableHeader,
  TableContainer,
  FooterCell,
};
